import { BetaSchemaForm } from '@ant-design/pro-form';
import { Button, message, RowProps, Segmented, Space, Typography } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { ContactUsModal } from 'components';
import useListLocation from 'hooks/useListLocation';
import useModalVisibility from 'hooks/useModalVisibility';
import useAddEditActions from 'pages/Network/hook/useAddEditActions';
import useDeleteActions from 'pages/Network/hook/useDeleteActions';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useAccount from 'services/api/useAccount';
import {
  useListNetworkInvites,
  useQueryDigitalLink,
  useTradePartnerById,
} from 'services/api/useTradePartners';
import { errorHandler, getPhoneNumber, hasUnsavedChanges } from 'utils';
import { getAccountType } from 'utils/network';
import { DeleteLocations, InviteShareLocation } from '..';
import useLocationStore from '../../hook/useLocationStore';
import styles from '../index.module.less';
import { AddLocationColumns, DataItem } from './CompanyLocation.fields';
import useDataEntryMethodStore from './DataEntryMethod/useDataEntryStore';
import { LocationCols } from './LocationTable/typings';
import UpgradeModal from './UpgradeModal';

const rowProps: RowProps = { gutter: [32, 24] };

const EditCompanyLocationForm = () => {
  const [queryParams] = useSearchParams();
  const isEditAttribute = queryParams?.get('tab') === 'attribute' || false;
  const { setLocationData } = useLocationStore();
  const { companyId = '1' } = useParams();
  const dataEntryMethod = useDataEntryMethodStore((state) => state.dataEntryMethod);
  const contactUsModal = useModalVisibility(false);
  const deleteLocModal = useModalVisibility(false);

  const {
    formRef,
    setAttributes,
    closeModal,
    onNoMultiLoc,
    visible,
    onNoVessel,
    addEditLocationData,
    setLocationFieldValues,
    updateCompanyData,
    updateLocationData,
    setAddEditLocationData,
    // onRestoreConnection,
    t,
    setVesselLocationFieldValues,
    updateVesselLocation,
    createNewVesselLocation,
    setAddEditVesselLocationData,
    addEditVesselLocationData,
    updateTraceabilityData,
    addTraceabilityData,
    traceSwitch,
    setTraceSwitch,
    onTraceSwitchOff,
    createNewLocation,
    onRemoveConnection,
    upgradeTraceModal,
    allowInternalLocation,
    allowExternalLocation,
    deleteInviteConsent,
    digitalLinkInvalid,
    queryClient,
    ...restDrawerVisibility
  } = useAddEditActions();
  // const { data: traceTradePartner } = useTradePartners({ tradePartnerType: ['Self'] });
  const { data: selectedCompany } = useTradePartnerById(String(companyId));
  const inviteShareLocModal = useModalVisibility(false);
  const validateDigitalLink = useQueryDigitalLink();
  const userType = useMemo(
    () =>
      getAccountType(
        selectedCompany?.isOwnerAccount,
        selectedCompany?.hasWholechainAccount,
        selectedCompany?.hasWholechainAccount && selectedCompany?.type === 'NonWholechain',
      ),
    [selectedCompany],
  );
  const { data: accountData } = useAccount();
  // const accountAsync = useAccountAsync();
  const vesselEnabled = useMemo(() => accountData?.vesselEnabled, [accountData?.vesselEnabled]);
  const myCompanyName = useMemo(() => accountData?.name || '', [accountData?.name]);
  const { data: dataLocations } = useListLocation(
    companyId
      ? {
          partnerIds: [companyId],
          pageNumber: 1,
          pageSize: 5,
          isVessel: false,
        }
      : undefined,
  );
  const { data: vesselLoc } = useListLocation(
    companyId
      ? {
          partnerIds: [companyId],
          pageNumber: 1,
          pageSize: 5,
          isVessel: true,
        }
      : undefined,
  );

  const { data: archivedLoc } = useListLocation(
    companyId
      ? {
          partnerIds: [companyId],
          archived: true,
          pageNumber: 1,
          pageSize: 5,
        }
      : undefined,
  );

  const locationData = useMemo(() => dataLocations || [], [dataLocations]);
  const vesselLocations = useMemo(() => vesselLoc || [], [vesselLoc]);
  const archivedLocations = useMemo(() => archivedLoc || [], [archivedLoc]);
  const isLastLocation = useMemo(
    () =>
      (locationData?.length || 0) +
        (vesselLocations?.length || 0) +
        (archivedLocations?.length || 0) ===
      1,
    [archivedLocations?.length, locationData?.length, vesselLocations?.length],
  );
  const { archiveSingleLocation, cancelInvite, deleteCompanyById, loading, onDeleteLocation } =
    useDeleteActions();
  const tabs = [t('details'), t('default_template_attibutes')];
  const [tabType, setTabType] = useState<string | number>(isEditAttribute ? tabs[1] : tabs[0]);
  // const [isRemovedWC, setRemovedWC] = useState<boolean>(false);

  const { data: inviteData } = useListNetworkInvites({
    accountId: selectedCompany?.wholechainId || '',
    status: ['Pending'],
  });

  const acceptInviteData = useMemo(
    () => inviteData?.results?.filter((item) => item?.incoming === true),
    [inviteData?.results],
  );

  const cancelInviteData = useMemo(
    () => inviteData?.results?.filter((item) => item?.incoming === false),
    [inviteData?.results],
  );

  const connectionOptionButton = useMemo(() => {
    if (userType === 'NONWC' && selectedCompany?.wholechainId) {
      if (acceptInviteData?.length) {
        return 'accept_invite';
      }
      if (cancelInviteData?.length) {
        return 'cancel_invite';
      }

      return 'restore_connection';
    }
    return 'remove_connection';
  }, [acceptInviteData?.length, cancelInviteData?.length, selectedCompany?.wholechainId, userType]);

  const upgradeModal = useModalVisibility(false);

  const allowAddNewRecord = useMemo(
    () => (selectedCompany?.isOwnerAccount ? allowInternalLocation : allowExternalLocation),
    [allowExternalLocation, allowInternalLocation, selectedCompany?.isOwnerAccount],
  );

  useEffect(() => {
    if (locationData && selectedCompany) {
      const isMultipleVessels = formRef?.current?.getFieldValue('isMultipleVessels');
      const isMultipleLocations = formRef?.current?.getFieldValue('isMultipleLocations');
      setTraceSwitch(!!selectedCompany?.digitalLinkUrl);
      const data = {
        pgln: selectedCompany?.pgln,
        duns: selectedCompany?.duns,
        companyId: selectedCompany?.id,
        companyName: selectedCompany?.name || '',
        connectionType: selectedCompany?.tradeRelationship,
        digitalLink: selectedCompany?.digitalLinkUrl,
        urn: selectedCompany?.urn || '',
        apiKey: selectedCompany?.digitalLinkUrl,
        tradepartnerNoId: selectedCompany?.externalIdentifier,
        isMultipleLocations:
          isMultipleLocations || (locationData.length > 1 ? 'multi_loc' : undefined),
        isMultipleVessels:
          isMultipleVessels || (vesselLocations.length > 1 ? 'multi_vessel' : undefined),
        isVessel: vesselEnabled && vesselLocations.length ? 'Yes' : 'No',
      };
      setAttributes(data);
      // set single location in singular location layout
      if (locationData?.length === 1) {
        const loc = locationData[0];
        setAddEditLocationData({
          id: loc?.id || '',
          company: selectedCompany?.name || '',
          companyId: selectedCompany?.id,
        });
        const numberObj = getPhoneNumber(loc?.contact?.phone);
        const locFields = {
          id: loc?.id || '',
          gln: loc.gln,
          locationName: loc.name || '',
          address: loc.address ? loc.address : {},
          geoCoordinates: `${loc?.address?.geoCoordinates?.latitude},${loc?.address?.geoCoordinates?.longitude}`,
          phoneNumber: loc?.contact?.phone || '',
          number: numberObj?.tenDigits,
          phoneCode: numberObj?.iso,
          phonePrefix: numberObj?.code,
          email: loc?.contact?.email,
          vesselName: loc?.vessel?.vesselName,
          vesselLink: loc?.vessel?.vesselPublicRegistryLink,
          vesselAuth: loc?.vessel?.vesselSatelliteTrackingAuthority,
          imoNumber: loc?.vessel?.imoNumber,
          vesselState: loc?.vessel?.vesselFlagState,
          vesselReg: loc?.vessel?.vesselRegistration,
          locNumberId: loc?.externalIdentifier,
          extension: String(loc?.extension || ''),
          captainsName: loc?.captainsName,
          locDuns: loc?.duns,
          contact: loc?.contact?.name,
        };
        setAttributes({ ...locFields, multiLoc: [{ ...locFields }] });
      }

      // set single vessel location in singular location layout
      if (vesselLocations?.length === 1) {
        const loc = vesselLocations[0];
        setAddEditVesselLocationData({
          id: loc?.id || '',
          company: selectedCompany?.name || '',
          companyId: selectedCompany?.id,
        });
        const numberObj = getPhoneNumber(loc?.contact?.phone);
        const locFields = {
          vGln: loc.gln,
          vLocationName: loc.name || '',
          vAddress: loc.address ? loc.address : {},
          vGeoCoordinates: `${loc?.address?.geoCoordinates?.latitude},${loc?.address?.geoCoordinates?.longitude}`,
          vPhoneNumber: loc?.contact?.phone || '',
          vNumber: numberObj?.tenDigits,
          vPhoneCode: numberObj?.iso,
          vPhonePrefix: numberObj?.code,
          vEmail: loc?.contact?.email,
          vVesselName: loc?.vessel?.vesselName,
          vVesselLink: loc?.vessel?.vesselPublicRegistryLink,
          vVesselAuth: loc?.vessel?.vesselSatelliteTrackingAuthority,
          vImoNumber: loc?.vessel?.imoNumber,
          vVesselState: loc?.vessel?.vesselFlagState,
          vVesselReg: loc?.vessel?.vesselRegistration,
          vlocNumberId: loc?.externalIdentifier,
          vExtension: String(loc?.extension || ''),
          captainsName: loc?.captainsName,
          vLocDuns: loc?.duns,
          vContact: loc?.contact?.name,
        };

        const locMultiFields = {
          id: loc?.id || '',
          gln: loc.gln,
          locationName: loc.name || '',
          address: loc.address ? loc.address : {},
          geoCoordinates: `${loc?.address?.geoCoordinates?.latitude},${loc?.address?.geoCoordinates?.longitude}`,
          phoneNumber: loc?.contact?.phone || '',
          number: numberObj?.tenDigits,
          phoneCode: numberObj?.iso,
          phonePrefix: numberObj?.code,
          email: loc?.contact?.email,
          vesselName: loc?.vessel?.vesselName,
          vesselLink: loc?.vessel?.vesselPublicRegistryLink,
          vesselAuth: loc?.vessel?.vesselSatelliteTrackingAuthority,
          imoNumber: loc?.vessel?.imoNumber,
          vesselState: loc?.vessel?.vesselFlagState,
          vesselReg: loc?.vessel?.vesselRegistration,
          locNumberId: loc?.externalIdentifier,
          locDuns: loc?.duns,
          contact: loc?.contact?.name,
        };
        setAttributes({ ...locFields, multiVesselLoc: [{ ...locMultiFields }] });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formRef,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...locationData,
    selectedCompany,
    setAddEditLocationData,
    setAddEditVesselLocationData,
    setAttributes,
    setTraceSwitch,
    vesselEnabled,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...vesselLocations,
  ]);

  const onFinishFailed = useCallback(() => {
    message.error(t('validate_fail'));
  }, [t]);

  const onFinish = async (formData: DataItem) => {
    try {
      hasUnsavedChanges();
      if (traceSwitch) {
        if (!selectedCompany?.digitalLinkUrl) {
          const res = await validateDigitalLink.mutateAsync({
            params: { url: formData?.digitalLink, apiKey: formData?.apiKey },
          });
          if (res?.result === 'OwnApi' || res?.result === 'InvalidLink') {
            digitalLinkInvalid();
            return;
          }
          addTraceabilityData(
            selectedCompany?.id || '',
            formData.digitalLink || '',
            formData.apiKey || '',
          );
        } else {
          const traceabilityReq = {
            tradePartnerId: selectedCompany?.id || '',
            digitalLinkUrl: formData.digitalLink || '',
          };
          await updateTraceabilityData(selectedCompany?.id || '', traceabilityReq);
          // addTraceabilityData(
          //   selectedCompany?.id || '',
          //   formData.digitalLink || '',
          //   formData.apiKey || '',
          // );
        }
      }
      await updateCompanyData(formData, selectedCompany);

      if (
        formData?.vLocationName &&
        String(formData?.isVessel || '') !== 'No' &&
        String(formData?.isMultipleVessels || '') !== 'multi_vessel'
      ) {
        const isVesselEditMode = addEditVesselLocationData?.id !== undefined;
        if (isVesselEditMode) {
          const vesselRes = await updateVesselLocation(
            String(addEditVesselLocationData?.id),
            formData,
          );
          message.success(t('success.update_loc', { location: vesselRes.name }));
        } else if (allowAddNewRecord) {
          const vesselRes = await createNewVesselLocation(formData, selectedCompany);
          if (vesselRes?.id) {
            message.success(t('success.add_loc', { company: selectedCompany?.name }));
          }
        } else {
          upgradeModal?.show();
        }
      }

      if (formData?.locationName && String(formData?.isMultipleLocations || '') !== 'multi_loc') {
        const isEditMode = addEditLocationData?.id !== undefined;
        if (isEditMode) {
          const res = await updateLocationData(String(addEditLocationData?.id), formData, false);
          if (res) {
            const props = {
              id: res.id || '',
              name: res.name,
              company: res.tradePartnerName,
              locationAddress: `${res.address?.addressLine1}`,
              lat: Number(res.address?.geoCoordinates?.latitude),
              lng: Number(res.address?.geoCoordinates?.longitude),
              companyId: res.tradePartnerId,
              contact: res?.contact,
            };
            setLocationData({ ...props });
            closeModal();
            message.success(t('success.update_loc', { location: res.name }));
          }
        } else if (allowAddNewRecord) {
          const res = await createNewLocation(formData, selectedCompany, false);
          if (res?.id) {
            message.success(t('success.add_loc', { company: selectedCompany?.name }));
            closeModal();
          }
        } else {
          upgradeModal?.show();
        }
      } else {
        closeModal();
        message.success(
          t('success.update_comp', {
            company: formData.companyName ? formData.companyName : selectedCompany?.name,
          }),
        );
      }
    } catch (err) {
      if (errorHandler(err)) {
        message.error(errorHandler(err));
      }
    }
  };

  const onContactUs = () => {
    contactUsModal.show();
  };

  const archive = () => {
    if (dataLocations?.length !== 1) {
      deleteLocModal.show();
    } else {
      const locId = formRef?.current?.getFieldValue('id') || addEditVesselLocationData?.id || '';
      archiveSingleLocation(locId);
    }
  };

  const changeType = useCallback(
    (value: SegmentedValue) => {
      setTabType(value);
      queryClient.resetQueries('locationList');
    },
    [queryClient],
  );

  const Title: ReactElement = (
    <Space>
      <Typography.Title level={4}>{t('edit_modal_title')}</Typography.Title>
      {userType !== 'WC' && <Segmented options={tabs} value={tabType} onChange={changeType} />}
    </Space>
  );

  // const initialAPICall = useCallback(async () => {
  //   const isVessel = vesselLoc?.find(
  //     (item) => item?.tradePartnerId === companyId && item?.vessel !== null,
  //     [],
  //   );
  //   const accountVessel = await accountAsync.mutateAsync({});
  //   return Promise.resolve({
  //     isVessel: accountVessel?.vesselEnabled && isVessel?.id ? 'Yes' : 'No',
  //   });
  // }, [accountAsync, companyId, vesselLoc]);

  const onAcceptCancelInvite = useCallback(async () => {
    if (connectionOptionButton === 'cancel_invite') {
      const consent = await deleteInviteConsent(
        cancelInviteData?.[0]?.companyName || selectedCompany?.name || '',
      );
      if (consent) {
        cancelInvite(cancelInviteData?.[0]?.id || '', cancelInviteData?.[0]?.companyName || '');
      }
    } else {
      inviteShareLocModal?.show();
    }
  }, [
    cancelInvite,
    cancelInviteData,
    connectionOptionButton,
    deleteInviteConsent,
    inviteShareLocModal,
    selectedCompany?.name,
  ]);

  const onDeleteCompany = useCallback(async () => {
    const res = await deleteCompanyById(selectedCompany?.id || '', selectedCompany?.name || '');
    if (res) {
      closeModal();
    }
  }, [closeModal, deleteCompanyById, selectedCompany?.id, selectedCompany?.name]);

  const onDeleteLoc = useCallback(
    async (record?: LocationCols, companyName?: string) => {
      await onDeleteLocation(record, companyName, isLastLocation, closeModal);
    },
    [closeModal, isLastLocation, onDeleteLocation],
  );

  return (
    <>
      {inviteShareLocModal.visible && (
        <InviteShareLocation modal={inviteShareLocModal} inviteRequest={acceptInviteData?.[0]} />
      )}
      <ContactUsModal contactUsModal={contactUsModal} />
      <UpgradeModal modal={upgradeModal} tkey="your_loc." />
      <UpgradeModal modal={upgradeTraceModal} tkey="trace_switch." />
      {deleteLocModal.visible && <DeleteLocations modal={deleteLocModal} />}
      <BetaSchemaForm<DataItem>
        layoutType="DrawerForm"
        formRef={formRef}
        //   request={initialAPICall}
        columns={AddLocationColumns(
          dataEntryMethod,
          false,
          true,
          locationData,
          vesselLocations,
          formRef,
          onNoVessel,
          onNoMultiLoc,
          setLocationFieldValues,
          setVesselLocationFieldValues,
          myCompanyName,
          selectedCompany,
          undefined,
          undefined,
          onContactUs,
          archive,
          tabType,
          userType,
          vesselEnabled,
          traceSwitch,
          onTraceSwitchOff,
          onRemoveConnection,
          allowAddNewRecord,
          connectionOptionButton,
          onAcceptCancelInvite,
          onDeleteLoc,
        )}
        grid
        rowProps={rowProps}
        // @ts-ignore
        title={Title}
        visible={visible}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          height: '100%',
          placement: 'top',
          size: 'large',
          ...restDrawerVisibility,
          onClose: () => closeModal(),
        }}
        submitter={{
          searchConfig: {
            submitText: t('done'),
          },
          submitButtonProps: {
            shape: 'round',
          },
          render: (_, defaultDoms) => [
            userType === 'NONWC' ? (
              <Space>
                <Button
                  shape="round"
                  className={styles.deletebuttontext}
                  loading={loading}
                  onClick={onDeleteCompany}
                >
                  {t('archive_button')}
                </Button>
              </Space>
            ) : null,
            defaultDoms[1],
          ],
        }}
        submitTimeout={2000}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      />
    </>
  );
};

export default React.memo(EditCompanyLocationForm);
