import { CheckCircleOutlined, InfoCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ContactUsProps } from 'components/ContactUsModal/typings';
import ContactUsModal from 'components/Settings/SubscriptionBilling/UpgradePlanModal';
import { useModalVisibility, UseModalVisibilityReturnType } from 'hooks';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlockchainDetailsById } from 'services/api';
import { supportEmailLink } from 'utils';
import styles from '../index.module.less';
import { HashCardProps, ValidateHashProps } from '../typings';
import HashValidationModal from './HashValidationModal';

const ContactUs: FC<ContactUsProps> = ({ contactUsModal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details.contact_us_popup' });
  return (
    <ContactUsModal
      modal={contactUsModal}
      title={t('title')}
      description={
        <div>
          {t('description')}{' '}
          <a href={`mailto:${supportEmailLink}`} type="mail">
            {supportEmailLink}
          </a>{' '}
          {t('sub_description')}
        </div>
      }
      descPlaceholder={t('placeholder')}
    />
  );
};
const CardTitleValidateHash: FC<ValidateHashProps> = ({ verified, status, t }) => {
  const color = useMemo(() => {
    if (status === 'Pending') {
      return 'default';
    }
    switch (verified) {
      case true:
        return 'success';
      case false:
        return 'default';
      default:
        return 'default';
    }
  }, [status, verified]);
  const text = useMemo(() => {
    if (status === 'Pending') {
      return t?.('notverified');
    }
    switch (verified) {
      case true:
        return t?.('verified');
      case false:
        return t?.('notverified');
      default:
        return 'default';
    }
  }, [status, t, verified]);
  const icon = useMemo(() => {
    if (status === 'Pending') {
      return <MinusCircleOutlined />;
    }
    switch (verified) {
      case true:
        return <CheckCircleOutlined />;
      case false:
        return <MinusCircleOutlined />;
      default:
        return 'default';
    }
  }, [status, verified]);
  const tooltipMessage = useMemo(() => {
    switch (verified) {
      case true:
        return t?.('verified_tooltip');
      case false:
        return (
          <div className={styles.tooltiplink}>
            {`${t?.('unable_to_verify')}`}
            <Typography.Link href={supportEmailLink}>{` ${t?.('support')} `}</Typography.Link>
            {`${t?.('unable_to_verify_sub')}`}
          </div>
        );
      default:
        return 'default';
    }
  }, [t, verified]);

  return (
    <div className={styles.titleflex}>
      <Descriptions size="small" className={styles.cardtitle}>
        <Space size="small">
          <Typography.Text strong>{t?.('wholechain_hash')}</Typography.Text>
        </Space>
      </Descriptions>
      {status === 'Pending' ? (
        <Tag icon={icon} color={color}>
          {text}
        </Tag>
      ) : (
        <Tooltip title={tooltipMessage}>
          <Tag icon={icon} color={color}>
            {text}
          </Tag>
        </Tooltip>
      )}
    </div>
  );
};

const HashCard: FC<HashCardProps> = ({ activeEvent, t }) => {
  const { data, isLoading } = useBlockchainDetailsById(activeEvent?.id || '');
  const verified = data?.verified || false;
  const hashModal = useModalVisibility(false);
  const hashContactUsModal: UseModalVisibilityReturnType = useModalVisibility(false);
  const showModal = () => {
    hashModal.show();
  };
  const showContactUsModal = () => {
    hashContactUsModal.show();
  };
  const getButtonsSection = () => {
    if (data?.status === 'Pending') {
      return null;
    }
    if (data?.status !== 'Pending' && verified) {
      return (
        <Button type="link" onClick={showModal}>
          {t?.('validate_hash')}{' '}
        </Button>
      );
    }
    return <Typography.Link onClick={showContactUsModal}>{t?.('contact_us')}</Typography.Link>;
  };
  return isLoading ? (
    <Skeleton.Input size="small" active className={styles.skcontainer} />
  ) : (
    <>
      <Space className={styles.mrb10}>
        <Typography.Text strong>{t?.('hash')}</Typography.Text>
        <Tooltip
          title={
            <div className={styles.tooltiplink}>
              {t?.('wholechain_hash_tooltip')}&nbsp;
              <Typography.Link
                href={
                  data?.apiDocsUrl || 'https://support.wholechain.com/article/328-hash-validation'
                }
                target="_blank"
              >
                {t?.('wholechain_hash_link')}
              </Typography.Link>
            </div>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
      <Card title={<CardTitleValidateHash verified={verified} status={data?.status} t={t} />}>
        <Row gutter={[16, 16]}>
          <Col span={18}>
            {data?.status === 'Pending' ? (
              <Typography.Text type="secondary">{t?.('event_not_recorded')}</Typography.Text>
            ) : (
              <Typography.Text>{data?.hash || ''}</Typography.Text>
            )}
          </Col>
          <Col span={6}>
            <Row justify="end">
              <Col>{getButtonsSection()}</Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <HashValidationModal
        t={t}
        key={data?.eventId}
        modalProps={hashModal}
        blockchainDetails={data}
      />
      <ContactUs contactUsModal={hashContactUsModal} />
    </>
  );
};
export default React.memo(HashCard);
