import ProForm from '@ant-design/pro-form';
import { Input, message, Modal, Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendSupportRequest } from 'services/api';
import { errorHandler, supportEmailLink } from 'utils';
import styles from './index.module.less';
import { UpgradeDataItem, UpgradePlanModalProps } from './typings';

const ContactUsModal: FC<UpgradePlanModalProps> = ({ title, description, modal, inviteId }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.subscription_billing' });
  const modalTitle = title || t?.('upgrade_modal_title');
  const sendRequest = useSendSupportRequest();

  const [form] = ProForm.useForm<UpgradeDataItem>();
  const closeModal = () => {
    form?.resetFields();
    modal?.hide();
  };
  const onFormSubmit = async (formData: UpgradeDataItem) => {
    try {
      await sendRequest?.mutateAsync({
        message: `Expired Invite : ${inviteId} 
        ${formData?.message}`,
      });
      message.success(t('request_success'));
      closeModal();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <Modal
      {...modal}
      title={modalTitle}
      okButtonProps={{ type: 'primary', shape: 'round' }}
      cancelButtonProps={{ type: 'primary', shape: 'round', ghost: true }}
      onOk={() => form.submit()}
      onCancel={closeModal}
      okText={t('send')}
      cancelText={t('cancel')}
    >
      <Typography.Text className={styles.umodaldesc}>{description}</Typography.Text>
      <ProForm
        form={form}
        onFinish={onFormSubmit}
        submitter={{ render: () => null }}
        rowProps={{ gutter: [0, 0] }}
      >
        <ProForm.Item name="message" className="no-marginb-divider">
          <Input.TextArea rows={8} placeholder={t('placeholder')} />
        </ProForm.Item>
      </ProForm>
    </Modal>
  );
};

const ContactUs: FC<UpgradePlanModalProps> = ({ modal, tkey, inviteId }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network' });

  return (
    <ContactUsModal
      modal={modal}
      inviteId={inviteId}
      title={`${t(`${tkey || ''}upgrade_modal_title`)}` || ''}
      description={
        <div>
          {`${t(`${tkey || ''}upgrade_modal_description`)}`}&nbsp;
          <a href={`mailto:${supportEmailLink}`} type="mail">
            {t('support_anchor_email_title')}
          </a>
        </div>
      }
    />
  );
};

export default React.memo(ContactUs);
