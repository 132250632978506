import * as coreClient from "@azure/core-client";

export const CreateAccountRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateAccountRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      phone: {
        serializedName: "phone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      timezone: {
        serializedName: "timezone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      isGs1Member: {
        serializedName: "isGs1Member",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const AddressContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AddressContract",
    modelProperties: {
      city: {
        serializedName: "city",
        nullable: true,
        type: {
          name: "String",
        },
      },
      state: {
        serializedName: "state",
        nullable: true,
        type: {
          name: "String",
        },
      },
      country: {
        serializedName: "country",
        nullable: true,
        type: {
          name: "String",
        },
      },
      addressLine1: {
        serializedName: "addressLine1",
        nullable: true,
        type: {
          name: "String",
        },
      },
      addressLine2: {
        serializedName: "addressLine2",
        nullable: true,
        type: {
          name: "String",
        },
      },
      postalCode: {
        serializedName: "postalCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinatesContract",
        },
      },
      displayAddress: {
        serializedName: "displayAddress",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GeoCoordinatesContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GeoCoordinatesContract",
    modelProperties: {
      latitude: {
        serializedName: "latitude",
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const AccountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AccountResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      phone: {
        serializedName: "phone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      timezone: {
        serializedName: "timezone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      vesselEnabled: {
        serializedName: "vesselEnabled",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      resolveOrphansEnabled: {
        serializedName: "resolveOrphansEnabled",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      defaultLocationId: {
        serializedName: "defaultLocationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      defaultLocationName: {
        serializedName: "defaultLocationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateAccountRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateAccountRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      timezone: {
        serializedName: "timezone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselEnabled: {
        serializedName: "vesselEnabled",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      resolveOrphansEnabled: {
        serializedName: "resolveOrphansEnabled",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      defaultLocationId: {
        serializedName: "defaultLocationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const InviteUsersToAccountRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InviteUsersToAccountRequest",
    modelProperties: {
      emails: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "emails",
        required: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      role: {
        serializedName: "role",
        required: true,
        type: {
          name: "String",
        },
      },
      message: {
        serializedName: "message",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const InviteUsersToAccountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InviteUsersToAccountResponse",
    modelProperties: {
      invites: {
        serializedName: "invites",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InviteResponse",
            },
          },
        },
      },
    },
  },
};

export const InviteResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InviteResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      url: {
        serializedName: "url",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      role: {
        serializedName: "role",
        required: true,
        type: {
          name: "String",
        },
      },
      expirationDate: {
        serializedName: "expirationDate",
        required: true,
        type: {
          name: "Date",
        },
      },
      expired: {
        serializedName: "expired",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ListParticipantsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListParticipantsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ParticipantResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ParticipantResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ParticipantResponse",
    modelProperties: {
      userId: {
        serializedName: "userId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String",
        },
      },
      role: {
        serializedName: "role",
        type: {
          name: "String",
        },
      },
      invitation: {
        serializedName: "invitation",
        type: {
          name: "String",
        },
      },
      inviteUrl: {
        serializedName: "inviteUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      inviteId: {
        serializedName: "inviteId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      acceptedTermsAndConditions: {
        serializedName: "acceptedTermsAndConditions",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const FullSubscriptionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FullSubscriptionResponse",
    modelProperties: {
      subscription: {
        serializedName: "subscription",
        type: {
          name: "Composite",
          className: "StripeSubscriptionResponse",
        },
      },
      paymentInfo: {
        serializedName: "paymentInfo",
        type: {
          name: "Composite",
          className: "PaymentInfoResponse",
        },
      },
      subscriptionDetails: {
        serializedName: "subscriptionDetails",
        type: {
          name: "Composite",
          className: "SubscriptionResponse",
        },
      },
    },
  },
};

export const StripeSubscriptionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "StripeSubscriptionResponse",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
      nextPaymentAmount: {
        serializedName: "nextPaymentAmount",
        nullable: true,
        type: {
          name: "String",
        },
      },
      nextPaymentDate: {
        serializedName: "nextPaymentDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const PaymentInfoResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaymentInfoResponse",
    modelProperties: {
      cardLast4: {
        serializedName: "cardLast4",
        nullable: true,
        type: {
          name: "String",
        },
      },
      billingPeriodStart: {
        serializedName: "billingPeriodStart",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      billingPeriodEnd: {
        serializedName: "billingPeriodEnd",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      billingType: {
        serializedName: "billingType",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SubscriptionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SubscriptionResponse",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
      monthlyRate: {
        serializedName: "monthlyRate",
        type: {
          name: "Number",
        },
      },
      totalProducts: {
        serializedName: "totalProducts",
        type: {
          name: "Number",
        },
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String",
        },
      },
      annualShipments: {
        serializedName: "annualShipments",
        type: {
          name: "Number",
        },
      },
      receiveSupplierShipments: {
        serializedName: "receiveSupplierShipments",
        type: {
          name: "Boolean",
        },
      },
      partnerEvents: {
        serializedName: "partnerEvents",
        type: {
          name: "Boolean",
        },
      },
      totalCompanyLocation: {
        serializedName: "totalCompanyLocation",
        type: {
          name: "Number",
        },
      },
      totalOutsideLocation: {
        serializedName: "totalOutsideLocation",
        type: {
          name: "Number",
        },
      },
      apiAccess: {
        serializedName: "apiAccess",
        type: {
          name: "Boolean",
        },
      },
      customMSA: {
        serializedName: "customMSA",
        type: {
          name: "Boolean",
        },
      },
      integrations: {
        serializedName: "integrations",
        type: {
          name: "Boolean",
        },
      },
      sourceviewAddon: {
        serializedName: "sourceviewAddon",
        type: {
          name: "Boolean",
        },
      },
      monthlySharedDocuments: {
        serializedName: "monthlySharedDocuments",
        type: {
          name: "Number",
        },
      },
      eventLimitation: {
        serializedName: "eventLimitation",
        type: {
          name: "Number",
        },
      },
      totalUsers: {
        serializedName: "totalUsers",
        type: {
          name: "Number",
        },
      },
      totalLocationInstances: {
        serializedName: "totalLocationInstances",
        type: {
          name: "Number",
        },
      },
      templatesType: {
        serializedName: "templatesType",
        type: {
          name: "String",
        },
      },
      templates: {
        serializedName: "templates",
        type: {
          name: "Number",
        },
      },
      workflowsType: {
        serializedName: "workflowsType",
        type: {
          name: "String",
        },
      },
      workflows: {
        serializedName: "workflows",
        type: {
          name: "Number",
        },
      },
      documents: {
        serializedName: "documents",
        type: {
          name: "Number",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const ListUserAccountsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListUserAccountsResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AccountResponse",
            },
          },
        },
      },
    },
  },
};

export const IsValidInviteResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IsValidInviteResponse",
    modelProperties: {
      isValid: {
        serializedName: "isValid",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const AttributeContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeContract",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Composite",
          className: "AttributeFieldsContract",
        },
      },
      values: {
        serializedName: "values",
        type: {
          name: "Composite",
          className: "AttributeValuesContract",
        },
      },
      namespace: {
        serializedName: "namespace",
        type: {
          name: "Composite",
          className: "AttributeNamespaceContract",
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      allowDelete: {
        serializedName: "allowDelete",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const AttributeFieldsContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeFieldsContract",
    modelProperties: {
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      propertyName: {
        serializedName: "propertyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      fieldType: {
        serializedName: "fieldType",
        type: {
          name: "String",
        },
      },
      required: {
        serializedName: "required",
        type: {
          name: "Boolean",
        },
      },
      hidden: {
        serializedName: "hidden",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const AttributeValuesContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeValuesContract",
    modelProperties: {
      valueOptions: {
        serializedName: "valueOptions",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      defaultValues: {
        serializedName: "defaultValues",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttributeDefaultValueContract",
            },
          },
        },
      },
      defaultValue: {
        serializedName: "defaultValue",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AttributeDefaultValueContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeDefaultValueContract",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      locationName: {
        serializedName: "locationName",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AttributeNamespaceContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeNamespaceContract",
    modelProperties: {
      uri: {
        serializedName: "uri",
        nullable: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      prefix: {
        serializedName: "prefix",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateTemplateAttributeRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateTemplateAttributeRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      allowDelete: {
        serializedName: "allowDelete",
        type: {
          name: "Boolean",
        },
      },
      fieldProperties: {
        serializedName: "fieldProperties",
        type: {
          name: "Composite",
          className: "TemplateFieldContract",
        },
      },
    },
  },
};

export const TemplateFieldContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateFieldContract",
    modelProperties: {
      fields: {
        serializedName: "fields",
        type: {
          name: "Composite",
          className: "AttributeFieldsContract",
        },
      },
      values: {
        serializedName: "values",
        type: {
          name: "Composite",
          className: "AttributeValuesContract",
        },
      },
      namespace: {
        serializedName: "namespace",
        type: {
          name: "Composite",
          className: "AttributeNamespaceContract",
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListAttributesPaginatedResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListAttributesPaginatedResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AttributeResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const AttributeResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      fieldProperties: {
        serializedName: "fieldProperties",
        type: {
          name: "Composite",
          className: "TemplateFieldContract",
        },
      },
      allowDelete: {
        serializedName: "allowDelete",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const CreateAttributeRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateAttributeRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      allowDelete: {
        serializedName: "allowDelete",
        type: {
          name: "Boolean",
        },
      },
      fieldProperties: {
        serializedName: "fieldProperties",
        type: {
          name: "Composite",
          className: "TemplateFieldContract",
        },
      },
    },
  },
};

export const ListAttributesDefaultValuesPaginatedResponse: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "ListAttributesDefaultValuesPaginatedResponse",
      modelProperties: {
        totalPages: {
          serializedName: "totalPages",
          readOnly: true,
          type: {
            name: "Number",
          },
        },
        pageNumber: {
          serializedName: "pageNumber",
          type: {
            name: "Number",
          },
        },
        pageSize: {
          serializedName: "pageSize",
          type: {
            name: "Number",
          },
        },
        totalItems: {
          serializedName: "totalItems",
          type: {
            name: "Number",
          },
        },
        results: {
          serializedName: "results",
          nullable: true,
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "AttributeDefaultValueResponse",
              },
            },
          },
        },
        hasNextPage: {
          serializedName: "hasNextPage",
          readOnly: true,
          type: {
            name: "Boolean",
          },
        },
        hasPreviousPage: {
          serializedName: "hasPreviousPage",
          readOnly: true,
          type: {
            name: "Boolean",
          },
        },
      },
    },
  };

export const AttributeDefaultValueResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AttributeDefaultValueResponse",
    modelProperties: {
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      locationVersion: {
        serializedName: "locationVersion",
        type: {
          name: "Number",
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationResponse",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
      attributeId: {
        serializedName: "attributeId",
        type: {
          name: "Uuid",
        },
      },
      attribute: {
        serializedName: "attribute",
        type: {
          name: "Composite",
          className: "AttributeContract",
        },
      },
    },
  },
};

export const LocationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      networkStatus: {
        serializedName: "networkStatus",
        type: {
          name: "String",
        },
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tradePartnerUrn: {
        serializedName: "tradePartnerUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      duns: {
        serializedName: "duns",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      extension: {
        serializedName: "extension",
        nullable: true,
        type: {
          name: "String",
        },
      },
      captainsName: {
        serializedName: "captainsName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      hasActiveInventory: {
        serializedName: "hasActiveInventory",
        type: {
          name: "Boolean",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "LocationContactInformationContract",
        },
      },
      vessel: {
        serializedName: "vessel",
        type: {
          name: "Composite",
          className: "VesselInformationContract",
        },
      },
      wholechainId: {
        serializedName: "wholechainId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SharedWithContract",
            },
          },
        },
      },
      createdTime: {
        serializedName: "createdTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const LocationContactInformationContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationContactInformationContract",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const VesselInformationContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "VesselInformationContract",
    modelProperties: {
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselFlagState: {
        serializedName: "vesselFlagState",
        nullable: true,
        type: {
          name: "String",
        },
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselSatelliteTrackingAuthority: {
        serializedName: "vesselSatelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselPublicRegistryLink: {
        serializedName: "vesselPublicRegistryLink",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselRegistration: {
        serializedName: "vesselRegistration",
        nullable: true,
        type: {
          name: "String",
        },
      },
      unloadingPort: {
        serializedName: "unloadingPort",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SharedWithContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SharedWithContract",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListBizStepResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListBizStepResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        required: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "BizStepResponse",
            },
          },
        },
      },
    },
  },
};

export const BizStepResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BizStepResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedContainerInventoryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedContainerInventoryResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerProductInstanceResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ContainerProductInstanceResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContainerProductInstanceResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid",
        },
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      aliasProductName: {
        serializedName: "aliasProductName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tlcSource: {
        serializedName: "tlcSource",
        type: {
          name: "Composite",
          className: "NamedAddressContract",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        type: {
          name: "Number",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        type: {
          name: "String",
        },
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String",
        },
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Uuid",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      simpleUnitOfMeasurement: {
        serializedName: "simpleUnitOfMeasurement",
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
      productGtin: {
        serializedName: "productGtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productUrn: {
        serializedName: "productUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      totalInventoryAtLocation: {
        serializedName: "totalInventoryAtLocation",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const NamedAddressContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NamedAddressContract",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      city: {
        serializedName: "city",
        nullable: true,
        type: {
          name: "String",
        },
      },
      state: {
        serializedName: "state",
        nullable: true,
        type: {
          name: "String",
        },
      },
      country: {
        serializedName: "country",
        nullable: true,
        type: {
          name: "String",
        },
      },
      line1: {
        serializedName: "line1",
        nullable: true,
        type: {
          name: "String",
        },
      },
      line2: {
        serializedName: "line2",
        nullable: true,
        type: {
          name: "String",
        },
      },
      postalCode: {
        serializedName: "postalCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinatesContract",
        },
      },
    },
  },
};

export const ListDispositionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListDispositionResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        required: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DispositionResponse",
            },
          },
        },
      },
    },
  },
};

export const DispositionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DispositionResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DocumentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DocumentResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      title: {
        serializedName: "title",
        nullable: true,
        type: {
          name: "String",
        },
      },
      fileName: {
        serializedName: "fileName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      url: {
        serializedName: "url",
        nullable: true,
        type: {
          name: "String",
        },
      },
      ownerAccount: {
        serializedName: "ownerAccount",
        type: {
          name: "Uuid",
        },
      },
      ownerAccountName: {
        serializedName: "ownerAccountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      privacy: {
        serializedName: "privacy",
        type: {
          name: "String",
        },
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "String",
        },
      },
      documentType: {
        serializedName: "documentType",
        type: {
          name: "String",
        },
      },
      documentTypeName: {
        serializedName: "documentTypeName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      recentActivity: {
        serializedName: "recentActivity",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      expirationDate: {
        serializedName: "expirationDate",
        nullable: true,
        type: {
          name: "Date",
        },
      },
      expirationAlertDate: {
        serializedName: "expirationAlertDate",
        nullable: true,
        type: {
          name: "Date",
        },
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean",
        },
      },
      locations: {
        serializedName: "locations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentAssociation",
            },
          },
        },
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentAssociation",
            },
          },
        },
      },
      tradePartners: {
        serializedName: "tradePartners",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentAssociation",
            },
          },
        },
      },
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentAssociation",
            },
          },
        },
      },
      certificationStandard: {
        serializedName: "certificationStandard",
        nullable: true,
        type: {
          name: "String",
        },
      },
      certificationAgency: {
        serializedName: "certificationAgency",
        nullable: true,
        type: {
          name: "String",
        },
      },
      certificationId: {
        serializedName: "certificationId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      purchaseOrderNumber: {
        serializedName: "purchaseOrderNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentSharedWith",
            },
          },
        },
      },
      shipFrom: {
        serializedName: "shipFrom",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shipTo: {
        serializedName: "shipTo",
        nullable: true,
        type: {
          name: "String",
        },
      },
      sid: {
        serializedName: "sid",
        nullable: true,
        type: {
          name: "String",
        },
      },
      cid: {
        serializedName: "cid",
        nullable: true,
        type: {
          name: "String",
        },
      },
      fob: {
        serializedName: "fob",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shipDate: {
        serializedName: "shipDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      carrier: {
        serializedName: "carrier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      items: {
        serializedName: "items",
        nullable: true,
        type: {
          name: "String",
        },
      },
      quantitiesOfShippedItems: {
        serializedName: "quantitiesOfShippedItems",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DocumentAssociation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DocumentAssociation",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DocumentSharedWith: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DocumentSharedWith",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      role: {
        serializedName: "role",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateDocumentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateDocumentRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      privacy: {
        serializedName: "privacy",
        type: {
          name: "String",
        },
      },
      archived: {
        serializedName: "archived",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      locationsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "locationsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      productsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "productsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      tradePartnersIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "tradePartnersIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      eventIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "eventIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const PaginatedDocumentsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedDocumentsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DocumentResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ShareDocumentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareDocumentRequest",
    modelProperties: {
      documentIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      accountIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "accountIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ShareDocumentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareDocumentResponse",
    modelProperties: {
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShareDocumentResult",
            },
          },
        },
      },
    },
  },
};

export const ShareDocumentResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareDocumentResult",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid",
        },
      },
      documentId: {
        serializedName: "documentId",
        type: {
          name: "Uuid",
        },
      },
      documentTitle: {
        serializedName: "documentTitle",
        nullable: true,
        type: {
          name: "String",
        },
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UnshareDocumentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UnshareDocumentRequest",
    modelProperties: {
      documentId: {
        serializedName: "documentId",
        type: {
          name: "Uuid",
        },
      },
      accountIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "accountIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const SupportEmailRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SupportEmailRequest",
    modelProperties: {
      message: {
        serializedName: "message",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateEpcisDataRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateEpcisDataRequest",
    modelProperties: {
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "BaseEventConnectRequest",
            },
          },
        },
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateProductConnectRequest",
            },
          },
        },
      },
      locations: {
        serializedName: "locations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateLocationConnectRequest",
            },
          },
        },
      },
      tradePartners: {
        serializedName: "tradePartners",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateTradePartnerConnectRequest",
            },
          },
        },
      },
    },
  },
};

export const BaseEventConnectRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BaseEventConnectRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStep: {
        serializedName: "bizStep",
        nullable: true,
        type: {
          name: "String",
        },
      },
      disposition: {
        serializedName: "disposition",
        nullable: true,
        type: {
          name: "String",
        },
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime",
        },
      },
      loggedTime: {
        serializedName: "loggedTime",
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productOwnerUrn: {
        serializedName: "productOwnerUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      informationProviderUrn: {
        serializedName: "informationProviderUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      errorDeclaration: {
        serializedName: "errorDeclaration",
        type: {
          name: "Composite",
          className: "ErrorDeclarationContract",
        },
      },
    },
  },
};

export const EventMasterDataContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventMasterDataContract",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      namespace: {
        serializedName: "namespace",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
      propertyLocation: {
        serializedName: "propertyLocation",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const EventCertificationContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventCertificationContract",
    modelProperties: {
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      standard: {
        serializedName: "standard",
        nullable: true,
        type: {
          name: "String",
        },
      },
      agency: {
        serializedName: "agency",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
      identification: {
        serializedName: "identification",
        nullable: true,
        type: {
          name: "String",
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ErrorDeclarationContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ErrorDeclarationContract",
    modelProperties: {
      reason: {
        serializedName: "reason",
        nullable: true,
        type: {
          name: "String",
        },
      },
      declarationTime: {
        serializedName: "declarationTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      correctiveEventIDs: {
        serializedName: "correctiveEventIDs",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
    },
  },
};

export const CreateProductConnectRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateProductConnectRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      simpleUnitOfMeasurement: {
        serializedName: "simpleUnitOfMeasurement",
        type: {
          name: "String",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        type: {
          name: "Number",
        },
      },
      sharingPolicy: {
        serializedName: "sharingPolicy",
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        type: {
          name: "String",
        },
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productMasterData: {
        serializedName: "productMasterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductMasterDataContract",
            },
          },
        },
      },
      plu: {
        serializedName: "plu",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProductMasterDataContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductMasterDataContract",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      namespace: {
        serializedName: "namespace",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
      order: {
        serializedName: "order",
        type: {
          name: "Number",
        },
      },
      elementId: {
        serializedName: "elementId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const CreateLocationConnectRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateLocationConnectRequest",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      duns: {
        serializedName: "duns",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      extension: {
        serializedName: "extension",
        nullable: true,
        type: {
          name: "String",
        },
      },
      captainsName: {
        serializedName: "captainsName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "LocationContactInformationContract",
        },
      },
      vessel: {
        serializedName: "vessel",
        type: {
          name: "Composite",
          className: "VesselInformationContract",
        },
      },
      urn: {
        serializedName: "urn",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      tradePartnerUrn: {
        serializedName: "tradePartnerUrn",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const CreateTradePartnerConnectRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateTradePartnerConnectRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      pgln: {
        serializedName: "pgln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      duns: {
        serializedName: "duns",
        nullable: true,
        type: {
          name: "String",
        },
      },
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const EventTemplatesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventTemplatesResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      templateName: {
        serializedName: "templateName",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      allowVesselCatch: {
        serializedName: "allowVesselCatch",
        type: {
          name: "Boolean",
        },
      },
      allowCertificationDocuments: {
        serializedName: "allowCertificationDocuments",
        type: {
          name: "Boolean",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      role: {
        serializedName: "role",
        type: {
          name: "String",
        },
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SharedEventTemplateResponse",
            },
          },
        },
      },
      templateAttributes: {
        serializedName: "templateAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateAttributeResponse",
            },
          },
        },
      },
      templateDocuments: {
        serializedName: "templateDocuments",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateDocumentResponse",
            },
          },
        },
      },
      templateCertifications: {
        serializedName: "templateCertifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateCertificationResponse",
            },
          },
        },
      },
    },
  },
};

export const SharedEventTemplateResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SharedEventTemplateResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      eventTemplateId: {
        serializedName: "eventTemplateId",
        type: {
          name: "Uuid",
        },
      },
      role: {
        serializedName: "role",
        type: {
          name: "String",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TemplateAttributeResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateAttributeResponse",
    modelProperties: {
      templateId: {
        serializedName: "templateId",
        type: {
          name: "Uuid",
        },
      },
      template: {
        serializedName: "template",
        type: {
          name: "Composite",
          className: "EventTemplatesResponse",
        },
      },
      attributeId: {
        serializedName: "attributeId",
        type: {
          name: "Uuid",
        },
      },
      attribute: {
        serializedName: "attribute",
        type: {
          name: "Composite",
          className: "AttributeResponse",
        },
      },
      defaultValue: {
        serializedName: "defaultValue",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TemplateDocumentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateDocumentResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      templateId: {
        serializedName: "templateId",
        type: {
          name: "Uuid",
        },
      },
      template: {
        serializedName: "template",
        type: {
          name: "Composite",
          className: "EventTemplatesResponse",
        },
      },
      documentType: {
        serializedName: "documentType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentName: {
        serializedName: "documentName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      required: {
        serializedName: "required",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const TemplateCertificationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateCertificationResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      eventTemplateId: {
        serializedName: "eventTemplateId",
        type: {
          name: "Uuid",
        },
      },
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      standard: {
        serializedName: "standard",
        nullable: true,
        type: {
          name: "String",
        },
      },
      agency: {
        serializedName: "agency",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
      identification: {
        serializedName: "identification",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateEventTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateEventTemplateRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      allowVesselCatch: {
        serializedName: "allowVesselCatch",
        type: {
          name: "Boolean",
        },
      },
      allowCertificationDocuments: {
        serializedName: "allowCertificationDocuments",
        type: {
          name: "Boolean",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      templateFields: {
        serializedName: "templateFields",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UpdateTemplateAttributeRequest",
            },
          },
        },
      },
      templateDocuments: {
        serializedName: "templateDocuments",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateDocumentRequest",
            },
          },
        },
      },
      templateCertifications: {
        serializedName: "templateCertifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateCertificationRequest",
            },
          },
        },
      },
    },
  },
};

export const TemplateDocumentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateDocumentRequest",
    modelProperties: {
      documentType: {
        serializedName: "documentType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentName: {
        serializedName: "documentName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      required: {
        serializedName: "required",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const TemplateCertificationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TemplateCertificationRequest",
    modelProperties: {
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      standard: {
        serializedName: "standard",
        nullable: true,
        type: {
          name: "String",
        },
      },
      agency: {
        serializedName: "agency",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
      identification: {
        serializedName: "identification",
        nullable: true,
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const ProblemDetails: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProblemDetails",
    additionalProperties: { type: { name: "Object" } },
    modelProperties: {
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      title: {
        serializedName: "title",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      detail: {
        serializedName: "detail",
        nullable: true,
        type: {
          name: "String",
        },
      },
      instance: {
        serializedName: "instance",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListEventTemplatesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListEventTemplatesResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventTemplatesResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const CreateEventTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateEventTemplateRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      allowVesselCatch: {
        serializedName: "allowVesselCatch",
        type: {
          name: "Boolean",
        },
      },
      allowCertificationDocuments: {
        serializedName: "allowCertificationDocuments",
        type: {
          name: "Boolean",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      templateFields: {
        serializedName: "templateFields",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateAttributeRequest",
            },
          },
        },
      },
      templateDocuments: {
        serializedName: "templateDocuments",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateDocumentRequest",
            },
          },
        },
      },
      templateCertifications: {
        serializedName: "templateCertifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TemplateCertificationRequest",
            },
          },
        },
      },
    },
  },
};

export const ShareEventTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareEventTemplateRequest",
    modelProperties: {
      wholechainIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "wholechainIds",
        required: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      role: {
        serializedName: "role",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const EventResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationResponse",
        },
      },
      originLocation: {
        serializedName: "originLocation",
        type: {
          name: "Composite",
          className: "LocationResponse",
        },
      },
      destinationLocation: {
        serializedName: "destinationLocation",
        type: {
          name: "Composite",
          className: "LocationResponse",
        },
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      tradePartner: {
        serializedName: "tradePartner",
        type: {
          name: "Composite",
          className: "TradePartnerResponse",
        },
      },
      originTradePartner: {
        serializedName: "originTradePartner",
        type: {
          name: "Composite",
          className: "TradePartnerResponse",
        },
      },
      destinationTradePartner: {
        serializedName: "destinationTradePartner",
        type: {
          name: "Composite",
          className: "TradePartnerResponse",
        },
      },
      destinationId: {
        serializedName: "destinationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      destinationTpId: {
        serializedName: "destinationTpId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originId: {
        serializedName: "originId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originTpId: {
        serializedName: "originTpId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime",
        },
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "Composite",
          className: "BizStepResponse",
        },
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "Composite",
          className: "DispositionResponse",
        },
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String",
        },
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        type: {
          name: "String",
        },
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      timezone: {
        serializedName: "timezone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductInstanceContract",
            },
          },
        },
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerResponse",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainResponse",
        },
      },
      transformationId: {
        serializedName: "transformationId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TradePartnerResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TradePartnerResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      pgln: {
        serializedName: "pgln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      duns: {
        serializedName: "duns",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tradeRelationship: {
        serializedName: "tradeRelationship",
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      hasWholechainAccount: {
        serializedName: "hasWholechainAccount",
        type: {
          name: "Boolean",
        },
      },
      isOwnerAccount: {
        serializedName: "isOwnerAccount",
        type: {
          name: "Boolean",
        },
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      wholechainId: {
        serializedName: "wholechainId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const ProductInstanceContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductInstanceContract",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid",
        },
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Uuid",
        },
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      consumed: {
        serializedName: "consumed",
        type: {
          name: "Boolean",
        },
      },
      superseded: {
        serializedName: "superseded",
        type: {
          name: "Boolean",
        },
      },
      shipped: {
        serializedName: "shipped",
        type: {
          name: "Boolean",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      transformationType: {
        serializedName: "transformationType",
        type: {
          name: "String",
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
      simpleUnitOfMeasurement: {
        serializedName: "simpleUnitOfMeasurement",
        type: {
          name: "String",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        type: {
          name: "Number",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        type: {
          name: "String",
        },
      },
      tlcSource: {
        serializedName: "tlcSource",
        type: {
          name: "Composite",
          className: "NamedAddressContract",
        },
      },
    },
  },
};

export const ContainerResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContainerResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      identifierType: {
        serializedName: "identifierType",
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Uuid",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerProductInstanceResponse",
            },
          },
        },
      },
    },
  },
};

export const BlockchainResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BlockchainResponse",
    modelProperties: {
      eventData: {
        serializedName: "eventData",
        nullable: true,
        type: {
          name: "String",
        },
      },
      hashedEventData: {
        serializedName: "hashedEventData",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      transactionAddress: {
        serializedName: "transactionAddress",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedEventDetailsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedEventDetailsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventDetailResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const EventDetailResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventDetailResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Uuid",
        },
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid",
        },
      },
      isContainer: {
        serializedName: "isContainer",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String",
        },
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      quantity: {
        serializedName: "quantity",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      companyName: {
        serializedName: "companyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      eventLoggedDate: {
        serializedName: "eventLoggedDate",
        type: {
          name: "DateTime",
        },
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String",
        },
      },
      identifierType: {
        serializedName: "identifierType",
        type: {
          name: "String",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "String",
        },
      },
      businessStep: {
        serializedName: "businessStep",
        nullable: true,
        type: {
          name: "String",
        },
      },
      disposition: {
        serializedName: "disposition",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      transformType: {
        serializedName: "transformType",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GetEventHistoryDiagramResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetEventHistoryDiagramResponse",
    modelProperties: {
      nodes: {
        serializedName: "nodes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeStub",
            },
          },
        },
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapDiagramEdge",
            },
          },
        },
      },
    },
  },
};

export const EventNodeStub: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventNodeStub",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventType: {
        serializedName: "eventType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime",
        },
      },
      eventTimezoneOffset: {
        serializedName: "eventTimezoneOffset",
        nullable: true,
        type: {
          name: "String",
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationModel",
        },
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance",
            },
          },
        },
      },
      container: {
        serializedName: "container",
        type: {
          name: "Composite",
          className: "EventNodeContainer",
        },
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeContainer",
            },
          },
        },
      },
      originLocation: {
        serializedName: "originLocation",
        type: {
          name: "Composite",
          className: "LocationModel",
        },
      },
      destinationLocation: {
        serializedName: "destinationLocation",
        type: {
          name: "Composite",
          className: "LocationModel",
        },
      },
      inputProducts: {
        serializedName: "inputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance",
            },
          },
        },
      },
      outputProducts: {
        serializedName: "outputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance",
            },
          },
        },
      },
    },
  },
};

export const LocationModel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationModel",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      deleted: {
        serializedName: "deleted",
        type: {
          name: "Boolean",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      sharedWith: {
        serializedName: "sharedWith",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationSharedWIth",
            },
          },
        },
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      ownerWholechainAccountId: {
        serializedName: "ownerWholechainAccountId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      urNs: {
        serializedName: "urNs",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationIdentifier",
            },
          },
        },
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address",
        },
      },
      customAttributes: {
        serializedName: "customAttributes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomMasterData",
            },
          },
        },
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "Contact",
        },
      },
      vesselName: {
        serializedName: "vesselName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselID: {
        serializedName: "vesselID",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselRegistration: {
        serializedName: "vesselRegistration",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselPublicRegistryLink: {
        serializedName: "vesselPublicRegistryLink",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselSatelliteTrackingAuthority: {
        serializedName: "vesselSatelliteTrackingAuthority",
        nullable: true,
        type: {
          name: "String",
        },
      },
      imoNumber: {
        serializedName: "imoNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      vesselFlagState: {
        serializedName: "vesselFlagState",
        nullable: true,
        type: {
          name: "String",
        },
      },
      unloadingPort: {
        serializedName: "unloadingPort",
        nullable: true,
        type: {
          name: "String",
        },
      },
      isShared: {
        serializedName: "isShared",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const LocationSharedWIth: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationSharedWIth",
    modelProperties: {
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid",
        },
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const LocationIdentifier: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationIdentifier",
    modelProperties: {
      identifierType: {
        serializedName: "identifierType",
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      companyIdentifier: {
        serializedName: "companyIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const GeoCoordinates: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GeoCoordinates",
    modelProperties: {
      latitude: {
        serializedName: "latitude",
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const Address: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Address",
    modelProperties: {
      city: {
        serializedName: "city",
        nullable: true,
        type: {
          name: "String",
        },
      },
      state: {
        serializedName: "state",
        nullable: true,
        type: {
          name: "String",
        },
      },
      country: {
        serializedName: "country",
        nullable: true,
        type: {
          name: "String",
        },
      },
      line1: {
        serializedName: "line1",
        nullable: true,
        type: {
          name: "String",
        },
      },
      line2: {
        serializedName: "line2",
        nullable: true,
        type: {
          name: "String",
        },
      },
      postalCode: {
        serializedName: "postalCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinates",
        },
      },
    },
  },
};

export const CustomMasterData: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CustomMasterData",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      namespace: {
        serializedName: "namespace",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const Contact: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Contact",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const EventNodeInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventNodeInstance",
    modelProperties: {
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        nullable: true,
        type: {
          name: "String",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      simpleUnitOfMeasurement: {
        serializedName: "simpleUnitOfMeasurement",
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      previousEventId: {
        serializedName: "previousEventId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventType: {
        serializedName: "eventType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      speciesName: {
        serializedName: "speciesName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      speciesCode: {
        serializedName: "speciesCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      conditionCode: {
        serializedName: "conditionCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      consumed: {
        serializedName: "consumed",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      inContainer: {
        serializedName: "inContainer",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const EventNodeContainer: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventNodeContainer",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      identifier: {
        serializedName: "identifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeInstance",
            },
          },
        },
      },
      eventId: {
        serializedName: "eventId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventType: {
        serializedName: "eventType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      quantity: {
        serializedName: "quantity",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const MapDiagramEdge: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MapDiagramEdge",
    modelProperties: {
      source: {
        serializedName: "source",
        nullable: true,
        type: {
          name: "String",
        },
      },
      target: {
        serializedName: "target",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const MapModel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MapModel",
    modelProperties: {
      nodes: {
        serializedName: "nodes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapNode",
            },
          },
        },
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapDiagramEdge",
            },
          },
        },
      },
    },
  },
};

export const MapNode: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MapNode",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String",
        },
      },
      latitude: {
        serializedName: "latitude",
        type: {
          name: "Number",
        },
      },
      longitude: {
        serializedName: "longitude",
        type: {
          name: "Number",
        },
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tradePartnerName: {
        serializedName: "tradePartnerName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "Address",
        },
      },
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeStub",
            },
          },
        },
      },
      isReceivePlaceholder: {
        serializedName: "isReceivePlaceholder",
        type: {
          name: "Boolean",
        },
      },
      acceptedByReceiver: {
        serializedName: "acceptedByReceiver",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ErrorOr1: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ErrorOr1",
    modelProperties: {
      isError: {
        serializedName: "isError",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      errors: {
        serializedName: "errors",
        readOnly: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ErrorModel",
            },
          },
        },
      },
      errorsOrEmptyList: {
        serializedName: "errorsOrEmptyList",
        readOnly: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ErrorModel",
            },
          },
        },
      },
      value: {
        serializedName: "value",
        readOnly: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventConnectResponse",
            },
          },
        },
      },
      firstError: {
        serializedName: "firstError",
        type: {
          name: "Composite",
          className: "ErrorModel",
        },
      },
    },
  },
};

export const ErrorModel: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ErrorModel",
    modelProperties: {
      code: {
        serializedName: "code",
        readOnly: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        readOnly: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      numericType: {
        serializedName: "numericType",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      metadata: {
        serializedName: "metadata",
        readOnly: true,
        nullable: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "any" } },
        },
      },
    },
  },
};

export const EventConnectResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventConnectResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "LocationResponse",
        },
      },
      originLocation: {
        serializedName: "originLocation",
        type: {
          name: "Composite",
          className: "LocationResponse",
        },
      },
      destinationLocation: {
        serializedName: "destinationLocation",
        type: {
          name: "Composite",
          className: "LocationResponse",
        },
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      tradePartner: {
        serializedName: "tradePartner",
        type: {
          name: "Composite",
          className: "TradePartnerResponse",
        },
      },
      originTradePartner: {
        serializedName: "originTradePartner",
        type: {
          name: "Composite",
          className: "TradePartnerResponse",
        },
      },
      destinationTradePartner: {
        serializedName: "destinationTradePartner",
        type: {
          name: "Composite",
          className: "TradePartnerResponse",
        },
      },
      destinationId: {
        serializedName: "destinationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      destinationTpId: {
        serializedName: "destinationTpId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originId: {
        serializedName: "originId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originTpId: {
        serializedName: "originTpId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime",
        },
      },
      bizStep: {
        serializedName: "bizStep",
        type: {
          name: "Composite",
          className: "BizStepResponse",
        },
      },
      disposition: {
        serializedName: "disposition",
        type: {
          name: "Composite",
          className: "DispositionResponse",
        },
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String",
        },
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        type: {
          name: "String",
        },
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      timezone: {
        serializedName: "timezone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      templateName: {
        serializedName: "templateName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductInstanceConnectContractResponse",
            },
          },
        },
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerResponse",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "Composite",
          className: "BlockchainResponse",
        },
      },
      transformationId: {
        serializedName: "transformationId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProductInstanceConnectContractResponse: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "ProductInstanceConnectContractResponse",
      modelProperties: {
        id: {
          serializedName: "id",
          type: {
            name: "Uuid",
          },
        },
        productId: {
          serializedName: "productId",
          type: {
            name: "Uuid",
          },
        },
        eventId: {
          serializedName: "eventId",
          type: {
            name: "Uuid",
          },
        },
        containerId: {
          serializedName: "containerId",
          nullable: true,
          type: {
            name: "Uuid",
          },
        },
        quantity: {
          serializedName: "quantity",
          type: {
            name: "Number",
          },
        },
        consumed: {
          serializedName: "consumed",
          type: {
            name: "Boolean",
          },
        },
        superseded: {
          serializedName: "superseded",
          type: {
            name: "Boolean",
          },
        },
        shipped: {
          serializedName: "shipped",
          type: {
            name: "Boolean",
          },
        },
        urn: {
          serializedName: "urn",
          nullable: true,
          type: {
            name: "String",
          },
        },
        lotSerial: {
          serializedName: "lotSerial",
          nullable: true,
          type: {
            name: "String",
          },
        },
        productName: {
          serializedName: "productName",
          nullable: true,
          type: {
            name: "String",
          },
        },
        transformationType: {
          serializedName: "transformationType",
          type: {
            name: "String",
          },
        },
        templateId: {
          serializedName: "templateId",
          nullable: true,
          type: {
            name: "Uuid",
          },
        },
        templateName: {
          serializedName: "templateName",
          nullable: true,
          type: {
            name: "String",
          },
        },
        productIdentifierType: {
          serializedName: "productIdentifierType",
          type: {
            name: "String",
          },
        },
        simpleUnitOfMeasurement: {
          serializedName: "simpleUnitOfMeasurement",
          type: {
            name: "String",
          },
        },
        unitQuantity: {
          serializedName: "unitQuantity",
          type: {
            name: "Number",
          },
        },
        unitDescriptor: {
          serializedName: "unitDescriptor",
          type: {
            name: "String",
          },
        },
        tlcSource: {
          serializedName: "tlcSource",
          type: {
            name: "Composite",
            className: "NamedAddressContract",
          },
        },
        productMasterData: {
          serializedName: "productMasterData",
          nullable: true,
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "ProductMasterDataContract",
              },
            },
          },
        },
        containerUrn: {
          serializedName: "containerUrn",
          nullable: true,
          type: {
            name: "String",
          },
        },
        productUrn: {
          serializedName: "productUrn",
          nullable: true,
          type: {
            name: "String",
          },
        },
      },
    },
  };

export const GetEventHistoryCountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetEventHistoryCountResponse",
    modelProperties: {
      count: {
        serializedName: "count",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const MultiCommissionRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MultiCommissionRequest",
    modelProperties: {
      commissions: {
        serializedName: "commissions",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CommissionRequest",
            },
          },
        },
      },
    },
  },
};

export const CommissionRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CommissionRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid",
        },
      },
      productInstance: {
        serializedName: "productInstance",
        type: {
          name: "Composite",
          className: "NewProductInstanceContract",
        },
      },
    },
  },
};

export const NewProductInstanceContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NewProductInstanceContract",
    modelProperties: {
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tlcSource: {
        serializedName: "tlcSource",
        type: {
          name: "Composite",
          className: "NamedAddressContract",
        },
      },
    },
  },
};

export const DecommissionRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DecommissionRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExistingProductInstanceContract",
            },
          },
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ExistingProductInstanceContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExistingProductInstanceContract",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      tlcSource: {
        serializedName: "tlcSource",
        type: {
          name: "Composite",
          className: "NamedAddressContract",
        },
      },
    },
  },
};

export const MultiObserveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MultiObserveRequest",
    modelProperties: {
      observations: {
        serializedName: "observations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ObserveRequest",
            },
          },
        },
      },
    },
  },
};

export const ObserveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ObserveRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExistingProductInstanceContract",
            },
          },
        },
      },
      containerIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "containerIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const AggregateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AggregateRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      identifierType: {
        serializedName: "identifierType",
        type: {
          name: "String",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExistingProductInstanceContract",
            },
          },
        },
      },
    },
  },
};

export const DisaggregateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DisaggregateRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      containerId: {
        serializedName: "containerId",
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const TransformRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TransformRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      inputProducts: {
        serializedName: "inputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExistingProductInstanceContract",
            },
          },
        },
      },
      outputProducts: {
        serializedName: "outputProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OutputProductRequest",
            },
          },
        },
      },
    },
  },
};

export const OutputProductRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OutputProductRequest",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const MultiShipRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MultiShipRequest",
    modelProperties: {
      ships: {
        serializedName: "ships",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShipRequest",
            },
          },
        },
      },
    },
  },
};

export const ShipRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      originLocationId: {
        serializedName: "originLocationId",
        type: {
          name: "Uuid",
        },
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        type: {
          name: "Uuid",
        },
      },
      containerIds: {
        serializedName: "containerIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      containerProductInstances: {
        serializedName: "containerProductInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExistingContainerProductInstanceContract",
            },
          },
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExistingProductInstanceContract",
            },
          },
        },
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      automaticReceive: {
        serializedName: "automaticReceive",
        type: {
          name: "Boolean",
        },
      },
      receiveEventTime: {
        serializedName: "receiveEventTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const ExistingContainerProductInstanceContract: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "ExistingContainerProductInstanceContract",
      modelProperties: {
        productInstanceId: {
          serializedName: "productInstanceId",
          required: true,
          type: {
            name: "Uuid",
          },
        },
        containerId: {
          serializedName: "containerId",
          required: true,
          type: {
            name: "Uuid",
          },
        },
        tlcSource: {
          serializedName: "tlcSource",
          type: {
            name: "Composite",
            className: "NamedAddressContract",
          },
        },
      },
    },
  };

export const MultiReceiveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MultiReceiveRequest",
    modelProperties: {
      receives: {
        serializedName: "receives",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReceiveRequest",
            },
          },
        },
      },
    },
  },
};

export const ReceiveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReceiveRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalShipmentId: {
        serializedName: "externalShipmentId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originLocationId: {
        serializedName: "originLocationId",
        type: {
          name: "Uuid",
        },
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        type: {
          name: "Uuid",
        },
      },
      containerIdentifiers: {
        serializedName: "containerIdentifiers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReceiveExistingProductInstance",
            },
          },
        },
      },
      automaticDisaggregationDate: {
        serializedName: "automaticDisaggregationDate",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const ReceiveExistingProductInstance: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReceiveExistingProductInstance",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      tlcSource: {
        serializedName: "tlcSource",
        type: {
          name: "Composite",
          className: "NamedAddressContract",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      aliasProductId: {
        serializedName: "aliasProductId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originalInstanceUrn: {
        serializedName: "originalInstanceUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateIntegrationDataRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateIntegrationDataRequest",
    modelProperties: {
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateProductConnectRequest",
            },
          },
        },
      },
      locations: {
        serializedName: "locations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateLocationConnectRequest",
            },
          },
        },
      },
      tradePartners: {
        serializedName: "tradePartners",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateTradePartnerConnectRequest",
            },
          },
        },
      },
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateIntegrationEvent",
            },
          },
        },
      },
    },
  },
};

export const CreateIntegrationEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateIntegrationEvent",
    modelProperties: {
      externalEventId: {
        serializedName: "externalEventId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      invoiceNumber: {
        serializedName: "invoiceNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStep: {
        serializedName: "bizStep",
        nullable: true,
        type: {
          name: "String",
        },
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      disposition: {
        serializedName: "disposition",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      recordTime: {
        serializedName: "recordTime",
        type: {
          name: "DateTime",
        },
      },
      tradePartner: {
        serializedName: "tradePartner",
        type: {
          name: "Composite",
          className: "CreateTradePartnerConnectRequest",
        },
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCustomProperty",
            },
          },
        },
      },
      vesselCatchInformation: {
        serializedName: "vesselCatchInformation",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "VesselInformationContract",
            },
          },
        },
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CertificationInfo",
            },
          },
        },
      },
    },
  },
};

export const EventCustomProperty: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventCustomProperty",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      namespace: {
        serializedName: "namespace",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
      propertyLocation: {
        serializedName: "propertyLocation",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CertificationInfo: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CertificationInfo",
    modelProperties: {
      certificationType: {
        serializedName: "certificationType",
        nullable: true,
        type: {
          name: "String",
        },
      },
      certificationStandard: {
        serializedName: "certificationStandard",
        nullable: true,
        type: {
          name: "String",
        },
      },
      certificationAgency: {
        serializedName: "certificationAgency",
        nullable: true,
        type: {
          name: "String",
        },
      },
      certificationValue: {
        serializedName: "certificationValue",
        nullable: true,
        type: {
          name: "String",
        },
      },
      certificationIdentification: {
        serializedName: "certificationIdentification",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateIntegrationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateIntegrationResponse",
    modelProperties: {
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductResult",
            },
          },
        },
      },
      locations: {
        serializedName: "locations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationResult",
            },
          },
        },
      },
      tradePartners: {
        serializedName: "tradePartners",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TradePartnerResult",
            },
          },
        },
      },
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventResult",
            },
          },
        },
      },
    },
  },
};

export const ProductResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductResult",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const LocationResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationResult",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      geoCoordinates: {
        serializedName: "geoCoordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinatesContract",
        },
      },
    },
  },
};

export const TradePartnerResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TradePartnerResult",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      pgln: {
        serializedName: "pgln",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const EventResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventResult",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductInstanceResult",
            },
          },
        },
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerResult",
            },
          },
        },
      },
    },
  },
};

export const ProductInstanceResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductInstanceResult",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ContainerResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ContainerResult",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      sscc: {
        serializedName: "sscc",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProcessWholechainEventsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProcessWholechainEventsRequest",
    modelProperties: {
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ConnectEvent",
            },
          },
        },
      },
    },
  },
};

export const ConnectEvent: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ConnectEvent",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "String",
        },
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      invoiceNumber: {
        serializedName: "invoiceNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStep: {
        serializedName: "bizStep",
        nullable: true,
        type: {
          name: "String",
        },
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      disposition: {
        serializedName: "disposition",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      customProperties: {
        serializedName: "customProperties",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomProperty",
            },
          },
        },
      },
      certificationList: {
        serializedName: "certificationList",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ConnectCertificationInfo",
            },
          },
        },
      },
    },
  },
};

export const CustomProperty: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CustomProperty",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      namespace: {
        serializedName: "namespace",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ConnectCertificationInfo: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ConnectCertificationInfo",
    modelProperties: {
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      standard: {
        serializedName: "standard",
        nullable: true,
        type: {
          name: "String",
        },
      },
      agency: {
        serializedName: "agency",
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "String",
        },
      },
      identification: {
        serializedName: "identification",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProcessEventsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProcessEventsResponse",
    modelProperties: {
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String",
        },
      },
      result: {
        serializedName: "result",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProcessEventResult",
            },
          },
        },
      },
      errors: {
        serializedName: "errors",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProcessEventError",
            },
          },
        },
      },
      validations: {
        serializedName: "validations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ValidationFailure",
            },
          },
        },
      },
    },
  },
};

export const ProcessEventResult: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProcessEventResult",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      type: {
        serializedName: "type",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductInstanceResult",
            },
          },
        },
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerResult",
            },
          },
        },
      },
    },
  },
};

export const ProcessEventError: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProcessEventError",
    modelProperties: {
      statusCode: {
        serializedName: "statusCode",
        type: {
          name: "String",
        },
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ValidationFailure: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ValidationFailure",
    modelProperties: {
      propertyName: {
        serializedName: "propertyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      errorMessage: {
        serializedName: "errorMessage",
        nullable: true,
        type: {
          name: "String",
        },
      },
      attemptedValue: {
        serializedName: "attemptedValue",
        nullable: true,
        type: {
          name: "any",
        },
      },
      customState: {
        serializedName: "customState",
        nullable: true,
        type: {
          name: "any",
        },
      },
      severity: {
        serializedName: "severity",
        type: {
          name: "String",
        },
      },
      errorCode: {
        serializedName: "errorCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      formattedMessagePlaceholderValues: {
        serializedName: "formattedMessagePlaceholderValues",
        nullable: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "any" } },
        },
      },
    },
  },
};

export const MultiDirectReceiveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "MultiDirectReceiveRequest",
    modelProperties: {
      directReceives: {
        serializedName: "directReceives",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DirectReceiveRequest",
            },
          },
        },
      },
    },
  },
};

export const DirectReceiveRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DirectReceiveRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStepId: {
        serializedName: "bizStepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      dispositionId: {
        serializedName: "dispositionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      documentsIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "documentsIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      readpoint: {
        serializedName: "readpoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NewProductInstanceContract",
            },
          },
        },
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      containerProductInstances: {
        serializedName: "containerProductInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NewProductInstanceWithProductContract",
            },
          },
        },
      },
    },
  },
};

export const NewProductInstanceWithProductContract: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "NewProductInstanceWithProductContract",
      modelProperties: {
        productId: {
          serializedName: "productId",
          type: {
            name: "Uuid",
          },
        },
        quantity: {
          serializedName: "quantity",
          type: {
            name: "Number",
          },
        },
        lotSerial: {
          serializedName: "lotSerial",
          nullable: true,
          type: {
            name: "String",
          },
        },
      },
    },
  };

export const EventBlockchainResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventBlockchainResponse",
    modelProperties: {
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Uuid",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      verified: {
        serializedName: "verified",
        nullable: true,
        type: {
          name: "Boolean",
        },
      },
      event: {
        serializedName: "event",
        nullable: true,
        type: {
          name: "String",
        },
      },
      preHashEvent: {
        serializedName: "preHashEvent",
        nullable: true,
        type: {
          name: "String",
        },
      },
      hash: {
        serializedName: "hash",
        nullable: true,
        type: {
          name: "String",
        },
      },
      transactionAddress: {
        serializedName: "transactionAddress",
        nullable: true,
        type: {
          name: "String",
        },
      },
      blockExplorerUrl: {
        serializedName: "blockExplorerUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gS1HashGeneratorUrl: {
        serializedName: "gS1HashGeneratorUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      apiDocsUrl: {
        serializedName: "apiDocsUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SetExternalRegistryRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SetExternalRegistryRequest",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      outboundApiKey: {
        serializedName: "outboundApiKey",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const SetExternalRegistryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SetExternalRegistryResponse",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateExternalRegistryRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateExternalRegistryRequest",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      outboundApiKey: {
        serializedName: "outboundApiKey",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UpdateExternalRegistryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateExternalRegistryResponse",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      outboundApiKey: {
        serializedName: "outboundApiKey",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GetExternalRegistryDetailsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetExternalRegistryDetailsResponse",
    modelProperties: {
      digitalLinkUrl: {
        serializedName: "digitalLinkUrl",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      apiKey: {
        serializedName: "apiKey",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      sourceTradePartyName: {
        serializedName: "sourceTradePartyName",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      sourceTradePartyEmail: {
        serializedName: "sourceTradePartyEmail",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GetInboundApiKeyResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetInboundApiKeyResponse",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      inboundApiKey: {
        serializedName: "inboundApiKey",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TestDigitalLinkResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TestDigitalLinkResponse",
    modelProperties: {
      result: {
        serializedName: "result",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateLanguageRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateLanguageRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      code: {
        serializedName: "code",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      emailTemplateIds: {
        serializedName: "emailTemplateIds",
        type: {
          name: "Composite",
          className: "EmailTemplateRequest",
        },
      },
    },
  },
};

export const EmailTemplateRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailTemplateRequest",
    modelProperties: {
      sharedTemplate: {
        serializedName: "sharedTemplate",
        nullable: true,
        type: {
          name: "String",
        },
      },
      sharedDocument: {
        serializedName: "sharedDocument",
        nullable: true,
        type: {
          name: "String",
        },
      },
      rejectedNetworkInvite: {
        serializedName: "rejectedNetworkInvite",
        nullable: true,
        type: {
          name: "String",
        },
      },
      acceptedNetworkInvite: {
        serializedName: "acceptedNetworkInvite",
        nullable: true,
        type: {
          name: "String",
        },
      },
      newNetworkInvite: {
        serializedName: "newNetworkInvite",
        nullable: true,
        type: {
          name: "String",
        },
      },
      rejectedShipment: {
        serializedName: "rejectedShipment",
        nullable: true,
        type: {
          name: "String",
        },
      },
      receivedShipment: {
        serializedName: "receivedShipment",
        nullable: true,
        type: {
          name: "String",
        },
      },
      inboundShipment: {
        serializedName: "inboundShipment",
        nullable: true,
        type: {
          name: "String",
        },
      },
      userInvite: {
        serializedName: "userInvite",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accountInvite: {
        serializedName: "accountInvite",
        nullable: true,
        type: {
          name: "String",
        },
      },
      expiringDocument: {
        serializedName: "expiringDocument",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const LanguageResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LanguageResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      code: {
        serializedName: "code",
        nullable: true,
        type: {
          name: "String",
        },
      },
      emailTemplates: {
        serializedName: "emailTemplates",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailTemplateResponse",
            },
          },
        },
      },
    },
  },
};

export const EmailTemplateResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailTemplateResponse",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      templateId: {
        serializedName: "templateId",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateLanguageAttributeRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateLanguageAttributeRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      languageValues: {
        serializedName: "languageValues",
        required: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LanguageAttributeValue",
            },
          },
        },
      },
    },
  },
};

export const LanguageAttributeValue: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LanguageAttributeValue",
    modelProperties: {
      languageCode: {
        serializedName: "languageCode",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedLanguageAttribute: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedLanguageAttribute",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LanguageAttribute",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const LanguageAttribute: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LanguageAttribute",
    modelProperties: {
      id: {
        serializedName: "id",
        readOnly: true,
        type: {
          name: "Uuid",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        readOnly: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        readOnly: true,
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        readOnly: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        readOnly: true,
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      deleted: {
        serializedName: "deleted",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      attributeName: {
        serializedName: "attributeName",
        readOnly: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        readOnly: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      languageCode: {
        serializedName: "languageCode",
        readOnly: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      application: {
        serializedName: "application",
        type: {
          name: "String",
        },
      },
      language: {
        serializedName: "language",
        type: {
          name: "Composite",
          className: "Language",
        },
      },
    },
  },
};

export const Language: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Language",
    modelProperties: {
      id: {
        serializedName: "id",
        readOnly: true,
        type: {
          name: "Uuid",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        readOnly: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        readOnly: true,
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        readOnly: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        readOnly: true,
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      deleted: {
        serializedName: "deleted",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      name: {
        serializedName: "name",
        readOnly: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      code: {
        serializedName: "code",
        readOnly: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      emailTemplates: {
        serializedName: "emailTemplates",
        readOnly: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmailTemplate",
            },
          },
        },
      },
      languageAttributes: {
        serializedName: "languageAttributes",
        readOnly: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LanguageAttribute",
            },
          },
        },
      },
    },
  },
};

export const EmailTemplate: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailTemplate",
    modelProperties: {
      id: {
        serializedName: "id",
        readOnly: true,
        type: {
          name: "Uuid",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        readOnly: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      createdBy: {
        serializedName: "createdBy",
        readOnly: true,
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        readOnly: true,
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      modifiedBy: {
        serializedName: "modifiedBy",
        readOnly: true,
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      deleted: {
        serializedName: "deleted",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      templateId: {
        serializedName: "templateId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      languageCode: {
        serializedName: "languageCode",
        nullable: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      language: {
        serializedName: "language",
        type: {
          name: "Composite",
          className: "Language",
        },
      },
    },
  },
};

export const CreateLanguageAttributeBulkRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateLanguageAttributeBulkRequest",
    modelProperties: {
      data: {
        serializedName: "data",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateLanguageAttributeRequest",
            },
          },
        },
      },
    },
  },
};

export const UpdateLanguageAttributeBulkRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateLanguageAttributeBulkRequest",
    modelProperties: {
      data: {
        serializedName: "data",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UpdateLanguageAttributeRequest",
            },
          },
        },
      },
    },
  },
};

export const UpdateLanguageAttributeRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateLanguageAttributeRequest",
    modelProperties: {
      languageAttributeName: {
        serializedName: "languageAttributeName",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      languageValues: {
        serializedName: "languageValues",
        required: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LanguageAttributeValue",
            },
          },
        },
      },
    },
  },
};

export const LanguageAttributeResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LanguageAttributeResponse",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      languageValues: {
        serializedName: "languageValues",
        nullable: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "String" } },
        },
      },
    },
  },
};

export const LanguageAttributeResponseDictionary: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LanguageAttributeResponseDictionary",
    modelProperties: {
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "String" } },
        },
      },
    },
  },
};

export const ListLocationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListLocationsResponse",
    modelProperties: {
      data: {
        serializedName: "data",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LocationResponse",
            },
          },
        },
      },
    },
  },
};

export const CreateLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateLocationRequest",
    modelProperties: {
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      duns: {
        serializedName: "duns",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      extension: {
        serializedName: "extension",
        nullable: true,
        type: {
          name: "String",
        },
      },
      captainsName: {
        serializedName: "captainsName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "LocationContactInformationContract",
        },
      },
      vessel: {
        serializedName: "vessel",
        type: {
          name: "Composite",
          className: "VesselInformationContract",
        },
      },
    },
  },
};

export const UpdateLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateLocationRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gln: {
        serializedName: "gln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      duns: {
        serializedName: "duns",
        nullable: true,
        type: {
          name: "String",
        },
      },
      extension: {
        serializedName: "extension",
        nullable: true,
        type: {
          name: "String",
        },
      },
      captainsName: {
        serializedName: "captainsName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "LocationContactInformationContract",
        },
      },
      vessel: {
        serializedName: "vessel",
        type: {
          name: "Composite",
          className: "VesselInformationContract",
        },
      },
    },
  },
};

export const HasActiveInventoryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "HasActiveInventoryResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ToggleArchiveLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ToggleArchiveLocationRequest",
    modelProperties: {
      ids: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "ids",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ToggleArchiveLocationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ToggleArchiveLocationResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      archived: {
        serializedName: "archived",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ShareLocationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShareLocationRequest",
    modelProperties: {
      locationIds: {
        serializedName: "locationIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      wholechanIds: {
        serializedName: "wholechanIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const EventStatistics: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EventStatistics",
    modelProperties: {
      shipmentsReceived: {
        serializedName: "shipmentsReceived",
        type: {
          name: "Number",
        },
      },
      totalEvents: {
        serializedName: "totalEvents",
        type: {
          name: "Number",
        },
      },
      shipmentsSent: {
        serializedName: "shipmentsSent",
        type: {
          name: "Number",
        },
      },
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String",
        },
      },
      ownerWholechainId: {
        serializedName: "ownerWholechainId",
        type: {
          name: "Uuid",
        },
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "LocationContactInformation",
        },
      },
    },
  },
};

export const LocationContactInformation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "LocationContactInformation",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      phone: {
        serializedName: "phone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProductResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      sharingPolicy: {
        serializedName: "sharingPolicy",
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      simpleUnitOfMeasurement: {
        serializedName: "simpleUnitOfMeasurement",
        type: {
          name: "String",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        type: {
          name: "Number",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        type: {
          name: "String",
        },
      },
      blockchain: {
        serializedName: "blockchain",
        type: {
          name: "String",
        },
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      plu: {
        serializedName: "plu",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      currentInventory: {
        serializedName: "currentInventory",
        type: {
          name: "Number",
        },
      },
      currentInventoryTotal: {
        serializedName: "currentInventoryTotal",
        type: {
          name: "Number",
        },
      },
      shippedInventory: {
        serializedName: "shippedInventory",
        type: {
          name: "Number",
        },
      },
      productMasterData: {
        serializedName: "productMasterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductMasterDataContract",
            },
          },
        },
      },
      subProducts: {
        serializedName: "subProducts",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SubProductResponse",
            },
          },
        },
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      pendingShipmentItems: {
        serializedName: "pendingShipmentItems",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const SubProductResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SubProductResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      accountName: {
        serializedName: "accountName",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProductInstanceResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductInstanceResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      shipped: {
        serializedName: "shipped",
        type: {
          name: "Boolean",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      product: {
        serializedName: "product",
        type: {
          name: "Composite",
          className: "ProductResponse",
        },
      },
      aliasProduct: {
        serializedName: "aliasProduct",
        type: {
          name: "Composite",
          className: "ProductResponse",
        },
      },
    },
  },
};

export const PaginatedExpandedNetworksResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedExpandedNetworksResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExpandedNetworkResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ExpandedNetworkResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExpandedNetworkResponse",
    modelProperties: {
      tradePartner: {
        serializedName: "tradePartner",
        type: {
          name: "Composite",
          className: "NetworkTradePartner",
        },
      },
      location: {
        serializedName: "location",
        type: {
          name: "Composite",
          className: "NetworkLocation",
        },
      },
      invite: {
        serializedName: "invite",
        type: {
          name: "Composite",
          className: "NetworkInvite",
        },
      },
      networkStatus: {
        serializedName: "networkStatus",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NetworkTradePartner: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NetworkTradePartner",
    modelProperties: {
      id: {
        serializedName: "id",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const NetworkLocation: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NetworkLocation",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      coordinates: {
        serializedName: "coordinates",
        type: {
          name: "Composite",
          className: "GeoCoordinatesContract",
        },
      },
      isShared: {
        serializedName: "isShared",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const NetworkInvite: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NetworkInvite",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedNetworkInvitesResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedNetworkInvitesResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NetworkInviteResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const NetworkInviteResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NetworkInviteResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      wholechainId: {
        serializedName: "wholechainId",
        type: {
          name: "Uuid",
        },
      },
      companyName: {
        serializedName: "companyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      incoming: {
        serializedName: "incoming",
        type: {
          name: "Boolean",
        },
      },
      isResent: {
        serializedName: "isResent",
        type: {
          name: "Boolean",
        },
      },
      tradeRelationship: {
        serializedName: "tradeRelationship",
        type: {
          name: "String",
        },
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const CreateNetworkInviteRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateNetworkInviteRequest",
    modelProperties: {
      tradeRelationship: {
        serializedName: "tradeRelationship",
        type: {
          name: "String",
        },
      },
      wholechainId: {
        serializedName: "wholechainId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String",
        },
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "locationIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const UpdateNetworkInviteRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateNetworkInviteRequest",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      locationIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "locationIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const PaginatedNotificationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedNotificationsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "NotificationResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      totalUnreadAmount: {
        serializedName: "totalUnreadAmount",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const NotificationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "NotificationResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      message: {
        serializedName: "message",
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
      messageBackup: {
        serializedName: "messageBackup",
        nullable: true,
        type: {
          name: "String",
        },
      },
      descriptionBackup: {
        serializedName: "descriptionBackup",
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "String",
        },
      },
      kind: {
        serializedName: "kind",
        type: {
          name: "String",
        },
      },
      senderId: {
        serializedName: "senderId",
        type: {
          name: "Uuid",
        },
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      messageParams: {
        serializedName: "messageParams",
        nullable: true,
        type: {
          name: "String",
        },
      },
      descriptionParams: {
        serializedName: "descriptionParams",
        nullable: true,
        type: {
          name: "String",
        },
      },
      sender: {
        serializedName: "sender",
        type: {
          name: "Composite",
          className: "SenderContract",
        },
      },
      read: {
        serializedName: "read",
        type: {
          name: "Boolean",
        },
      },
      action: {
        serializedName: "action",
        type: {
          name: "Composite",
          className: "ActionContract",
        },
      },
    },
  },
};

export const SenderContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SenderContract",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      givenName: {
        serializedName: "givenName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      surname: {
        serializedName: "surname",
        nullable: true,
        type: {
          name: "String",
        },
      },
      profileImageUrl: {
        serializedName: "profileImageUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ActionContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ActionContract",
    modelProperties: {
      text: {
        serializedName: "text",
        nullable: true,
        type: {
          name: "String",
        },
      },
      resourceStatus: {
        serializedName: "resourceStatus",
        nullable: true,
        type: {
          name: "String",
        },
      },
      resourceId: {
        serializedName: "resourceId",
        type: {
          name: "Uuid",
        },
      },
      resourceUrl: {
        serializedName: "resourceUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChangeToReadNotificationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeToReadNotificationRequest",
    modelProperties: {
      notificationIds: {
        serializedName: "notificationIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const PaginatedOrphansResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedOrphansResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrphanResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const OrphanResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OrphanResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Uuid",
        },
      },
      instanceId: {
        serializedName: "instanceId",
        type: {
          name: "Uuid",
        },
      },
      isContainer: {
        serializedName: "isContainer",
        type: {
          name: "Boolean",
        },
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      company: {
        serializedName: "company",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String",
        },
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      parentProductId: {
        serializedName: "parentProductId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      quantity: {
        serializedName: "quantity",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shippedTo: {
        serializedName: "shippedTo",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      shippedFrom: {
        serializedName: "shippedFrom",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        type: {
          name: "String",
        },
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime",
        },
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedOrphanSuggestionsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedOrphanSuggestionsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OrphanSuggestionsResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const OrphanSuggestionsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OrphanSuggestionsResponse",
    modelProperties: {
      lastEventId: {
        serializedName: "lastEventId",
        type: {
          name: "Uuid",
        },
      },
      productInstanceId: {
        serializedName: "productInstanceId",
        type: {
          name: "Uuid",
        },
      },
      parentProductId: {
        serializedName: "parentProductId",
        type: {
          name: "Uuid",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      quantity: {
        serializedName: "quantity",
        nullable: true,
        type: {
          name: "Number",
        },
      },
      productType: {
        serializedName: "productType",
        type: {
          name: "String",
        },
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String",
        },
      },
      companyName: {
        serializedName: "companyName",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationName: {
        serializedName: "locationName",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      preferred: {
        serializedName: "preferred",
        type: {
          name: "Boolean",
        },
      },
      needsTransform: {
        serializedName: "needsTransform",
        type: {
          name: "Boolean",
        },
      },
      isInboundShipment: {
        serializedName: "isInboundShipment",
        type: {
          name: "Boolean",
        },
      },
      tradePartnerId: {
        serializedName: "tradePartnerId",
        type: {
          name: "Uuid",
        },
      },
      locationId: {
        serializedName: "locationId",
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const PaginatedOutboundIntegrationDispatchLogResponse: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "PaginatedOutboundIntegrationDispatchLogResponse",
      modelProperties: {
        totalPages: {
          serializedName: "totalPages",
          readOnly: true,
          type: {
            name: "Number",
          },
        },
        pageNumber: {
          serializedName: "pageNumber",
          type: {
            name: "Number",
          },
        },
        pageSize: {
          serializedName: "pageSize",
          type: {
            name: "Number",
          },
        },
        totalItems: {
          serializedName: "totalItems",
          type: {
            name: "Number",
          },
        },
        results: {
          serializedName: "results",
          nullable: true,
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "OutboundIntegrationDispatchLogResponse",
              },
            },
          },
        },
        hasNextPage: {
          serializedName: "hasNextPage",
          readOnly: true,
          type: {
            name: "Boolean",
          },
        },
        hasPreviousPage: {
          serializedName: "hasPreviousPage",
          readOnly: true,
          type: {
            name: "Boolean",
          },
        },
      },
    },
  };

export const OutboundIntegrationDispatchLogResponse: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "OutboundIntegrationDispatchLogResponse",
      modelProperties: {
        id: {
          serializedName: "id",
          type: {
            name: "Uuid",
          },
        },
        outboundIntegrationId: {
          serializedName: "outboundIntegrationId",
          type: {
            name: "Uuid",
          },
        },
        eventId: {
          serializedName: "eventId",
          type: {
            name: "Uuid",
          },
        },
        accountId: {
          serializedName: "accountId",
          type: {
            name: "Uuid",
          },
        },
        createdAt: {
          serializedName: "createdAt",
          type: {
            name: "DateTime",
          },
        },
        completedAt: {
          serializedName: "completedAt",
          nullable: true,
          type: {
            name: "DateTime",
          },
        },
        isComplete: {
          serializedName: "isComplete",
          type: {
            name: "Boolean",
          },
        },
        isError: {
          serializedName: "isError",
          type: {
            name: "Boolean",
          },
        },
        dispatchErrors: {
          serializedName: "dispatchErrors",
          nullable: true,
          type: {
            name: "String",
          },
        },
        dispatchFileName: {
          serializedName: "dispatchFileName",
          nullable: true,
          type: {
            name: "String",
          },
        },
      },
    },
  };

export const IntegrationExecutionCheckResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IntegrationExecutionCheckResponse",
    modelProperties: {
      willExecute: {
        serializedName: "willExecute",
        type: {
          name: "Boolean",
        },
      },
      executionDetails: {
        serializedName: "executionDetails",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
      outboundIntegrationMessageBody: {
        serializedName: "outboundIntegrationMessageBody",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedOutboundIntegrationsResponse: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "PaginatedOutboundIntegrationsResponse",
      modelProperties: {
        totalPages: {
          serializedName: "totalPages",
          readOnly: true,
          type: {
            name: "Number",
          },
        },
        pageNumber: {
          serializedName: "pageNumber",
          type: {
            name: "Number",
          },
        },
        pageSize: {
          serializedName: "pageSize",
          type: {
            name: "Number",
          },
        },
        totalItems: {
          serializedName: "totalItems",
          type: {
            name: "Number",
          },
        },
        results: {
          serializedName: "results",
          nullable: true,
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "OutboundIntegrationResponse",
              },
            },
          },
        },
        hasNextPage: {
          serializedName: "hasNextPage",
          readOnly: true,
          type: {
            name: "Boolean",
          },
        },
        hasPreviousPage: {
          serializedName: "hasPreviousPage",
          readOnly: true,
          type: {
            name: "Boolean",
          },
        },
      },
    },
  };

export const OutboundIntegrationResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OutboundIntegrationResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      externalSystem: {
        serializedName: "externalSystem",
        type: {
          name: "String",
        },
      },
      transportMethod: {
        serializedName: "transportMethod",
        type: {
          name: "String",
        },
      },
      transportFormat: {
        serializedName: "transportFormat",
        type: {
          name: "String",
        },
      },
      billedToEntity: {
        serializedName: "billedToEntity",
        type: {
          name: "String",
        },
      },
      customSystemConfigurationKeyVaultPath: {
        serializedName: "customSystemConfigurationKeyVaultPath",
        nullable: true,
        type: {
          name: "String",
        },
      },
      useCustomSystemConfiguration: {
        serializedName: "useCustomSystemConfiguration",
        type: {
          name: "Boolean",
        },
      },
      triggerCriteria: {
        serializedName: "triggerCriteria",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TriggerCriterionResponse",
            },
          },
        },
      },
      dataElements: {
        serializedName: "dataElements",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "DataMapElementResponse",
            },
          },
        },
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const TriggerCriterionResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TriggerCriterionResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      triggerSpecificationId: {
        serializedName: "triggerSpecificationId",
        type: {
          name: "Uuid",
        },
      },
      values: {
        serializedName: "values",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TriggerCriterionValueResponse",
            },
          },
        },
      },
    },
  },
};

export const TriggerCriterionValueResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TriggerCriterionValueResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      entityId: {
        serializedName: "entityId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      isEnum: {
        serializedName: "isEnum",
        type: {
          name: "Boolean",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DataMapElementResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DataMapElementResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      externalSystem: {
        serializedName: "externalSystem",
        type: {
          name: "String",
        },
      },
      externalProperty: {
        serializedName: "externalProperty",
        type: {
          name: "Uuid",
        },
      },
      internalProperty: {
        serializedName: "internalProperty",
        type: {
          name: "Uuid",
        },
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number",
        },
      },
      internalMasterDataElementId: {
        serializedName: "internalMasterDataElementId",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateOutboundIntegrationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateOutboundIntegrationRequest",
    modelProperties: {
      externalSystem: {
        serializedName: "externalSystem",
        type: {
          name: "String",
        },
      },
      transportMethod: {
        serializedName: "transportMethod",
        type: {
          name: "String",
        },
      },
      transportFormat: {
        serializedName: "transportFormat",
        type: {
          name: "String",
        },
      },
      billedToEntity: {
        serializedName: "billedToEntity",
        type: {
          name: "String",
        },
      },
      customSystemConfigurationKeyVaultPath: {
        serializedName: "customSystemConfigurationKeyVaultPath",
        nullable: true,
        type: {
          name: "String",
        },
      },
      useCustomSystemConfiguration: {
        serializedName: "useCustomSystemConfiguration",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const UpdateOutboundIntegrationRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateOutboundIntegrationRequest",
    modelProperties: {
      externalSystem: {
        serializedName: "externalSystem",
        type: {
          name: "String",
        },
      },
      transportMethod: {
        serializedName: "transportMethod",
        type: {
          name: "String",
        },
      },
      transportFormat: {
        serializedName: "transportFormat",
        type: {
          name: "String",
        },
      },
      billedToEntity: {
        serializedName: "billedToEntity",
        type: {
          name: "String",
        },
      },
      customSystemConfigurationKeyVaultPath: {
        serializedName: "customSystemConfigurationKeyVaultPath",
        nullable: true,
        type: {
          name: "String",
        },
      },
      useCustomSystemConfiguration: {
        serializedName: "useCustomSystemConfiguration",
        type: {
          name: "Boolean",
        },
      },
      isEnabled: {
        serializedName: "isEnabled",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const OutboundIntegrationDefault: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OutboundIntegrationDefault",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalSystem: {
        serializedName: "externalSystem",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateConfiguredOutboundIntegrationRequest: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "CreateConfiguredOutboundIntegrationRequest",
      modelProperties: {
        externalSystem: {
          serializedName: "externalSystem",
          type: {
            name: "String",
          },
        },
      },
    },
  };

export const TriggerConfigurationsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TriggerConfigurationsResponse",
    modelProperties: {
      values: {
        serializedName: "values",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OutboundIntegrationTrigger",
            },
          },
        },
      },
    },
  },
};

export const OutboundIntegrationTrigger: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OutboundIntegrationTrigger",
    modelProperties: {
      triggerSpecificationId: {
        serializedName: "triggerSpecificationId",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      triggerIdentity: {
        serializedName: "triggerIdentity",
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const TriggerConfigurationOptionsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TriggerConfigurationOptionsResponse",
    modelProperties: {
      options: {
        serializedName: "options",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TriggerCriterionValueResponse",
            },
          },
        },
      },
    },
  },
};

export const ExternalMappableDataElement: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExternalMappableDataElement",
    modelProperties: {
      mappableDataElementId: {
        serializedName: "mappableDataElementId",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalSystem: {
        serializedName: "externalSystem",
        type: {
          name: "String",
        },
      },
      testHelperFieldValue: {
        serializedName: "testHelperFieldValue",
        type: {
          name: "String",
        },
      },
      mapFromDataElements: {
        serializedName: "mapFromDataElements",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InternalMappableDataElement",
            },
          },
        },
      },
      isRestrictedToMapFromDataElements: {
        serializedName: "isRestrictedToMapFromDataElements",
        type: {
          name: "Boolean",
        },
      },
      isRequiredByIntegration: {
        serializedName: "isRequiredByIntegration",
        type: {
          name: "Boolean",
        },
      },
      allowUserCustomization: {
        serializedName: "allowUserCustomization",
        type: {
          name: "Boolean",
        },
      },
      isAutoMapped: {
        serializedName: "isAutoMapped",
        type: {
          name: "Boolean",
        },
      },
      isVisibleToUser: {
        serializedName: "isVisibleToUser",
        type: {
          name: "Boolean",
        },
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number",
        },
      },
      restrictMappingToDataElements: {
        serializedName: "restrictMappingToDataElements",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String",
            },
          },
        },
      },
    },
  },
};

export const InternalMappableDataElement: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InternalMappableDataElement",
    modelProperties: {
      mappableDataElementId: {
        serializedName: "mappableDataElementId",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
      testHelperFieldValue: {
        serializedName: "testHelperFieldValue",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateTriggerCriterionRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateTriggerCriterionRequest",
    modelProperties: {
      triggerSpecificationId: {
        serializedName: "triggerSpecificationId",
        type: {
          name: "Uuid",
        },
      },
      values: {
        serializedName: "values",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TriggerCriterionValueRequest",
            },
          },
        },
      },
      integrationId: {
        serializedName: "integrationId",
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const TriggerCriterionValueRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TriggerCriterionValueRequest",
    modelProperties: {
      entityId: {
        serializedName: "entityId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      isEnum: {
        serializedName: "isEnum",
        type: {
          name: "Boolean",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      description: {
        serializedName: "description",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateTriggerCriterionLookupValueRequest: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "CreateTriggerCriterionLookupValueRequest",
      modelProperties: {
        triggerValueId: {
          serializedName: "triggerValueId",
          nullable: true,
          type: {
            name: "String",
          },
        },
      },
    },
  };

export const CreateDataMapElementRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateDataMapElementRequest",
    modelProperties: {
      externalProperty: {
        serializedName: "externalProperty",
        type: {
          name: "Uuid",
        },
      },
      internalProperty: {
        serializedName: "internalProperty",
        type: {
          name: "Uuid",
        },
      },
      position: {
        serializedName: "position",
        type: {
          name: "Number",
        },
      },
      internalMasterDataElementId: {
        serializedName: "internalMasterDataElementId",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListWholechainPluginConfigurationResponse: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "ListWholechainPluginConfigurationResponse",
      modelProperties: {
        pluginAccountConfiguration: {
          serializedName: "pluginAccountConfiguration",
          nullable: true,
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "WholechainPluginAccountConfigurationResponse",
              },
            },
          },
        },
      },
    },
  };

export const WholechainPluginAccountConfigurationResponse: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "WholechainPluginAccountConfigurationResponse",
      modelProperties: {
        pluginId: {
          serializedName: "pluginId",
          type: {
            name: "Uuid",
          },
        },
        accountPluginActivationId: {
          serializedName: "accountPluginActivationId",
          nullable: true,
          type: {
            name: "Uuid",
          },
        },
        name: {
          serializedName: "name",
          nullable: true,
          type: {
            name: "String",
          },
        },
        imageUrl: {
          serializedName: "imageUrl",
          nullable: true,
          type: {
            name: "String",
          },
        },
        externalSystem: {
          serializedName: "externalSystem",
          type: {
            name: "String",
          },
        },
        categories: {
          serializedName: "categories",
          nullable: true,
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "String",
              },
            },
          },
        },
        isCurrentlyActiveOnAccount: {
          serializedName: "isCurrentlyActiveOnAccount",
          type: {
            name: "Boolean",
          },
        },
        outboundIntegrationIds: {
          serializedName: "outboundIntegrationIds",
          nullable: true,
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Uuid",
              },
            },
          },
        },
      },
    },
  };

export const SaveIntegrationFtpSecretsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "SaveIntegrationFtpSecretsRequest",
    modelProperties: {
      server: {
        serializedName: "server",
        nullable: true,
        type: {
          name: "String",
        },
      },
      username: {
        serializedName: "username",
        nullable: true,
        type: {
          name: "String",
        },
      },
      password: {
        serializedName: "password",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const CreateProductRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateProductRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      simpleUnitOfMeasurement: {
        serializedName: "simpleUnitOfMeasurement",
        type: {
          name: "String",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        type: {
          name: "Number",
        },
      },
      sharingPolicy: {
        serializedName: "sharingPolicy",
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        type: {
          name: "String",
        },
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productMasterData: {
        serializedName: "productMasterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductMasterDataContract",
            },
          },
        },
      },
      plu: {
        serializedName: "plu",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedProductsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedProductsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      grossCount: {
        serializedName: "grossCount",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const UpdateProductRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateProductRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      simpleUnitOfMeasurement: {
        serializedName: "simpleUnitOfMeasurement",
        type: {
          name: "String",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        type: {
          name: "Number",
        },
      },
      sharingPolicy: {
        serializedName: "sharingPolicy",
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        type: {
          name: "String",
        },
      },
      productMasterData: {
        serializedName: "productMasterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductMasterDataContract",
            },
          },
        },
      },
      subProductIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "subProductIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      plu: {
        serializedName: "plu",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedInventoryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedInventoryResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InventoryResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      inventory: {
        serializedName: "inventory",
        type: {
          name: "Number",
        },
      },
      history: {
        serializedName: "history",
        type: {
          name: "Number",
        },
      },
      filteredQuantity: {
        serializedName: "filteredQuantity",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const InventoryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InventoryResponse",
    modelProperties: {
      container: {
        serializedName: "container",
        type: {
          name: "Composite",
          className: "InventoryContainerResponse",
        },
      },
      productInstance: {
        serializedName: "productInstance",
        type: {
          name: "Composite",
          className: "InventoryInstanceResponse",
        },
      },
      event: {
        serializedName: "event",
        type: {
          name: "Composite",
          className: "InventoryEventResponse",
        },
      },
    },
  },
};

export const InventoryContainerResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InventoryContainerResponse",
    modelProperties: {
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      containerItems: {
        serializedName: "containerItems",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InventoryInstanceResponse",
            },
          },
        },
      },
    },
  },
};

export const InventoryInstanceResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InventoryInstanceResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      shipped: {
        serializedName: "shipped",
        type: {
          name: "Boolean",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      product: {
        serializedName: "product",
        type: {
          name: "Composite",
          className: "ProductResponse",
        },
      },
      totalInventoryAtSameLocation: {
        serializedName: "totalInventoryAtSameLocation",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const InventoryEventResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InventoryEventResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String",
        },
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String",
        },
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationName: {
        serializedName: "destinationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        type: {
          name: "String",
        },
      },
      destinationTradePartnerId: {
        serializedName: "destinationTradePartnerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      destinationAddress: {
        serializedName: "destinationAddress",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
    },
  },
};

export const CreateSubProductsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateSubProductsRequest",
    modelProperties: {
      productId: {
        serializedName: "productId",
        type: {
          name: "Uuid",
        },
      },
      subProductIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "subProductIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const ChangeArchivedProductRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeArchivedProductRequest",
    modelProperties: {
      archived: {
        serializedName: "archived",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ChangeArchivedProductResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeArchivedProductResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      archived: {
        serializedName: "archived",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const PaginatedGlobalSearchProductsResponse: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "PaginatedGlobalSearchProductsResponse",
      modelProperties: {
        totalPages: {
          serializedName: "totalPages",
          readOnly: true,
          type: {
            name: "Number",
          },
        },
        pageNumber: {
          serializedName: "pageNumber",
          type: {
            name: "Number",
          },
        },
        pageSize: {
          serializedName: "pageSize",
          type: {
            name: "Number",
          },
        },
        totalItems: {
          serializedName: "totalItems",
          type: {
            name: "Number",
          },
        },
        results: {
          serializedName: "results",
          nullable: true,
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Composite",
                className: "GlobalSearchProductResponse",
              },
            },
          },
        },
        hasNextPage: {
          serializedName: "hasNextPage",
          readOnly: true,
          type: {
            name: "Boolean",
          },
        },
        hasPreviousPage: {
          serializedName: "hasPreviousPage",
          readOnly: true,
          type: {
            name: "Boolean",
          },
        },
      },
    },
  };

export const GlobalSearchProductResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GlobalSearchProductResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      gtin: {
        serializedName: "gtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      totalQuantity: {
        serializedName: "totalQuantity",
        type: {
          name: "Number",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        type: {
          name: "Number",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        type: {
          name: "String",
        },
      },
      simpleUnitOfMeasurement: {
        serializedName: "simpleUnitOfMeasurement",
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PaginatedShipmentsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedShipmentsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShipmentResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ShipmentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipmentResponse",
    modelProperties: {
      container: {
        serializedName: "container",
        type: {
          name: "Composite",
          className: "ContainerResponse",
        },
      },
      productInstance: {
        serializedName: "productInstance",
        type: {
          name: "Composite",
          className: "ProductInstanceResponse",
        },
      },
      event: {
        serializedName: "event",
        type: {
          name: "Composite",
          className: "ShipmentEventResponse",
        },
      },
      direction: {
        serializedName: "direction",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ShipmentEventResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipmentEventResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      eventType: {
        serializedName: "eventType",
        type: {
          name: "String",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String",
        },
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locationId: {
        serializedName: "locationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      locationName: {
        serializedName: "locationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        type: {
          name: "String",
        },
      },
      destinationTradePartnerId: {
        serializedName: "destinationTradePartnerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      destinationId: {
        serializedName: "destinationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      destinationUrn: {
        serializedName: "destinationUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationName: {
        serializedName: "destinationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      originId: {
        serializedName: "originId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originUrn: {
        serializedName: "originUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      originName: {
        serializedName: "originName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      senderName: {
        serializedName: "senderName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      senderUrn: {
        serializedName: "senderUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      receiverName: {
        serializedName: "receiverName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      receiverUrn: {
        serializedName: "receiverUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      receiverGtin: {
        serializedName: "receiverGtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
    },
  },
};

export const GetShipmentsSummaryResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetShipmentsSummaryResponse",
    modelProperties: {
      shipments: {
        serializedName: "shipments",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShipmentSummaryItemContract",
            },
          },
        },
      },
      totalOutboundShipments: {
        serializedName: "totalOutboundShipments",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      totalInboundShipments: {
        serializedName: "totalInboundShipments",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      totalShipments: {
        serializedName: "totalShipments",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const ShipmentSummaryItemContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipmentSummaryItemContract",
    modelProperties: {
      outboundShipments: {
        serializedName: "outboundShipments",
        type: {
          name: "Number",
        },
      },
      inboundShipments: {
        serializedName: "inboundShipments",
        type: {
          name: "Number",
        },
      },
      date: {
        serializedName: "date",
        type: {
          name: "Date",
        },
      },
    },
  },
};

export const CreateExternalShipmentRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateExternalShipmentRequest",
    modelProperties: {
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "BaseEventConnectRequest",
            },
          },
        },
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateProductConnectRequest",
            },
          },
        },
      },
      locations: {
        serializedName: "locations",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateLocationConnectRequest",
            },
          },
        },
      },
      tradePartners: {
        serializedName: "tradePartners",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CreateTradePartnerConnectRequest",
            },
          },
        },
      },
      shipment: {
        serializedName: "shipment",
        type: {
          name: "Composite",
          className: "ShipConnectRequest",
        },
      },
      request: {
        serializedName: "request",
        nullable: true,
        type: {
          name: "String",
        },
      },
      error: {
        serializedName: "error",
        type: {
          name: "Boolean",
        },
      },
      captureId: {
        serializedName: "captureId",
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const ShipConnectRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipConnectRequest",
    modelProperties: {
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      bizStep: {
        serializedName: "bizStep",
        nullable: true,
        type: {
          name: "String",
        },
      },
      disposition: {
        serializedName: "disposition",
        nullable: true,
        type: {
          name: "String",
        },
      },
      readPoint: {
        serializedName: "readPoint",
        nullable: true,
        type: {
          name: "String",
        },
      },
      eventTime: {
        serializedName: "eventTime",
        type: {
          name: "DateTime",
        },
      },
      loggedTime: {
        serializedName: "loggedTime",
        type: {
          name: "DateTime",
        },
      },
      eventTimeZone: {
        serializedName: "eventTimeZone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      masterData: {
        serializedName: "masterData",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventMasterDataContract",
            },
          },
        },
      },
      certifications: {
        serializedName: "certifications",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventCertificationContract",
            },
          },
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productOwnerUrn: {
        serializedName: "productOwnerUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      informationProviderUrn: {
        serializedName: "informationProviderUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      errorDeclaration: {
        serializedName: "errorDeclaration",
        type: {
          name: "Composite",
          className: "ErrorDeclarationContract",
        },
      },
      originLocationUrn: {
        serializedName: "originLocationUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationLocationUrn: {
        serializedName: "destinationLocationUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      originTradePartnerUrn: {
        serializedName: "originTradePartnerUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationTradePartnerUrn: {
        serializedName: "destinationTradePartnerUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ShipConnectContainer",
            },
          },
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductInstanceConnectContract",
            },
          },
        },
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ShipConnectContainer: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipConnectContainer",
    modelProperties: {
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      identifier: {
        serializedName: "identifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ProductInstanceConnectContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ProductInstanceConnectContract",
    modelProperties: {
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productUrn: {
        serializedName: "productUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListExternalShipmentsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListExternalShipmentsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OtherSystemShipmentResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const OtherSystemShipmentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OtherSystemShipmentResponse",
    modelProperties: {
      shipmentId: {
        serializedName: "shipmentId",
        type: {
          name: "Uuid",
        },
      },
      shipmentEventId: {
        serializedName: "shipmentEventId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      originCompanyName: {
        serializedName: "originCompanyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      originLocationName: {
        serializedName: "originLocationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationCompanyUrn: {
        serializedName: "destinationCompanyUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationLocationUrn: {
        serializedName: "destinationLocationUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      purchaseOrderNumber: {
        serializedName: "purchaseOrderNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      numberOfProducts: {
        serializedName: "numberOfProducts",
        type: {
          name: "Number",
        },
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      timezone: {
        serializedName: "timezone",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        type: {
          name: "String",
        },
      },
      instances: {
        serializedName: "instances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalProductInstanceResponse",
            },
          },
        },
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalShipmentContainerResponse",
            },
          },
        },
      },
      hasNewProducts: {
        serializedName: "hasNewProducts",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ExternalProductInstanceResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExternalProductInstanceResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      externalShipmentId: {
        serializedName: "externalShipmentId",
        type: {
          name: "Uuid",
        },
      },
      shipmentEventId: {
        serializedName: "shipmentEventId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productId: {
        serializedName: "productId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      productName: {
        serializedName: "productName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productGtin: {
        serializedName: "productGtin",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productUrn: {
        serializedName: "productUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      productIdentifierType: {
        serializedName: "productIdentifierType",
        type: {
          name: "String",
        },
      },
      unitOfMeasure: {
        serializedName: "unitOfMeasure",
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      unitQuantity: {
        serializedName: "unitQuantity",
        type: {
          name: "Number",
        },
      },
      unitDescriptor: {
        serializedName: "unitDescriptor",
        type: {
          name: "String",
        },
      },
      instanceId: {
        serializedName: "instanceId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      lotSerial: {
        serializedName: "lotSerial",
        nullable: true,
        type: {
          name: "String",
        },
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number",
        },
      },
      originId: {
        serializedName: "originId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originUrn: {
        serializedName: "originUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      originAddress: {
        serializedName: "originAddress",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      shippedFromLocationName: {
        serializedName: "shippedFromLocationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      senderUrn: {
        serializedName: "senderUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shippedFromCompanyName: {
        serializedName: "shippedFromCompanyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationId: {
        serializedName: "destinationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      destinationUrn: {
        serializedName: "destinationUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationName: {
        serializedName: "destinationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationAddress: {
        serializedName: "destinationAddress",
        type: {
          name: "Composite",
          className: "AddressContract",
        },
      },
      receiverUrn: {
        serializedName: "receiverUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      shipmentType: {
        serializedName: "shipmentType",
        type: {
          name: "String",
        },
      },
      direction: {
        serializedName: "direction",
        type: {
          name: "String",
        },
      },
      purchaseOrder: {
        serializedName: "purchaseOrder",
        nullable: true,
        type: {
          name: "String",
        },
      },
      containerId: {
        serializedName: "containerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      ticketId: {
        serializedName: "ticketId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      isExternalShipment: {
        serializedName: "isExternalShipment",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ExternalShipmentContainerResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExternalShipmentContainerResponse",
    modelProperties: {
      containerId: {
        serializedName: "containerId",
        type: {
          name: "Uuid",
        },
      },
      urn: {
        serializedName: "urn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalShipmentId: {
        serializedName: "externalShipmentId",
        type: {
          name: "Uuid",
        },
      },
      shipmentEventId: {
        serializedName: "shipmentEventId",
        nullable: true,
        type: {
          name: "String",
        },
      },
      containerIdentifier: {
        serializedName: "containerIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      instances: {
        serializedName: "instances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ExternalProductInstanceResponse",
            },
          },
        },
      },
    },
  },
};

export const ExternalShipmentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExternalShipmentResponse",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      events: {
        serializedName: "events",
        nullable: true,
        type: {
          name: "String",
        },
      },
      products: {
        serializedName: "products",
        nullable: true,
        type: {
          name: "String",
        },
      },
      locations: {
        serializedName: "locations",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tradePartners: {
        serializedName: "tradePartners",
        nullable: true,
        type: {
          name: "String",
        },
      },
      shipment: {
        serializedName: "shipment",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      shipmentUrn: {
        serializedName: "shipmentUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      request: {
        serializedName: "request",
        nullable: true,
        type: {
          name: "String",
        },
      },
      captureId: {
        serializedName: "captureId",
        type: {
          name: "Uuid",
        },
      },
      createdTime: {
        serializedName: "createdTime",
        type: {
          name: "DateTime",
        },
      },
      lastModifiedTime: {
        serializedName: "lastModifiedTime",
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const PaginatedInboundShipmentsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedInboundShipmentsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "InboundShipmentResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const InboundShipmentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "InboundShipmentResponse",
    modelProperties: {
      shipmentId: {
        serializedName: "shipmentId",
        type: {
          name: "Uuid",
        },
      },
      originCompanyName: {
        serializedName: "originCompanyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      originLocationName: {
        serializedName: "originLocationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationCompanyName: {
        serializedName: "destinationCompanyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationCompanyUrn: {
        serializedName: "destinationCompanyUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationLocationName: {
        serializedName: "destinationLocationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationLocationUrn: {
        serializedName: "destinationLocationUrn",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      destinationTradePartnerId: {
        serializedName: "destinationTradePartnerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      originLocationId: {
        serializedName: "originLocationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      purchaseOrderNumber: {
        serializedName: "purchaseOrderNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      numberOfProducts: {
        serializedName: "numberOfProducts",
        type: {
          name: "Number",
        },
      },
      hasContainer: {
        serializedName: "hasContainer",
        type: {
          name: "Boolean",
        },
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListOtherSystemShipmentsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListOtherSystemShipmentsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OtherSystemShipmentResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ShipmentDetailsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipmentDetailsResponse",
    modelProperties: {
      shipmentId: {
        serializedName: "shipmentId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      hasNewProducts: {
        serializedName: "hasNewProducts",
        type: {
          name: "Boolean",
        },
      },
      productInstances: {
        serializedName: "productInstances",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ProductInstanceResponse",
            },
          },
        },
      },
      containers: {
        serializedName: "containers",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContainerResponse",
            },
          },
        },
      },
    },
  },
};

export const ShipmentCountResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ShipmentCountResponse",
    modelProperties: {
      internal: {
        serializedName: "internal",
        type: {
          name: "Number",
        },
      },
      wholechain: {
        serializedName: "wholechain",
        type: {
          name: "Number",
        },
      },
      otherSystems: {
        serializedName: "otherSystems",
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const PaginatedOutboundShipmentsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PaginatedOutboundShipmentsResponse",
    modelProperties: {
      totalPages: {
        serializedName: "totalPages",
        readOnly: true,
        type: {
          name: "Number",
        },
      },
      pageNumber: {
        serializedName: "pageNumber",
        type: {
          name: "Number",
        },
      },
      pageSize: {
        serializedName: "pageSize",
        type: {
          name: "Number",
        },
      },
      totalItems: {
        serializedName: "totalItems",
        type: {
          name: "Number",
        },
      },
      results: {
        serializedName: "results",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "OutboundShipmentResponse",
            },
          },
        },
      },
      hasNextPage: {
        serializedName: "hasNextPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
      hasPreviousPage: {
        serializedName: "hasPreviousPage",
        readOnly: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const OutboundShipmentResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OutboundShipmentResponse",
    modelProperties: {
      shipmentId: {
        serializedName: "shipmentId",
        type: {
          name: "Uuid",
        },
      },
      destinationCompanyName: {
        serializedName: "destinationCompanyName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationLocationName: {
        serializedName: "destinationLocationName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      destinationLocationId: {
        serializedName: "destinationLocationId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      destinationTradePartnerId: {
        serializedName: "destinationTradePartnerId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
      loggedDate: {
        serializedName: "loggedDate",
        type: {
          name: "DateTime",
        },
      },
      eventDate: {
        serializedName: "eventDate",
        type: {
          name: "DateTime",
        },
      },
      purchaseOrderNumber: {
        serializedName: "purchaseOrderNumber",
        nullable: true,
        type: {
          name: "String",
        },
      },
      numberOfProducts: {
        serializedName: "numberOfProducts",
        type: {
          name: "Number",
        },
      },
      hasContainer: {
        serializedName: "hasContainer",
        type: {
          name: "Boolean",
        },
      },
      shipmentStatus: {
        serializedName: "shipmentStatus",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const GetShipmentDiagramResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "GetShipmentDiagramResponse",
    modelProperties: {
      nodes: {
        serializedName: "nodes",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EventNodeStub",
            },
          },
        },
      },
      edges: {
        serializedName: "edges",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "MapDiagramEdge",
            },
          },
        },
      },
    },
  },
};

export const CreateTradePartnerRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "CreateTradePartnerRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      pgln: {
        serializedName: "pgln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      duns: {
        serializedName: "duns",
        nullable: true,
        type: {
          name: "String",
        },
      },
      connectionType: {
        serializedName: "connectionType",
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ListTradePartnersResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ListTradePartnersResponse",
    modelProperties: {
      data: {
        serializedName: "data",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TradePartnerResponse",
            },
          },
        },
      },
    },
  },
};

export const UpdateTradePartnerRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UpdateTradePartnerRequest",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      pgln: {
        serializedName: "pgln",
        nullable: true,
        type: {
          name: "String",
        },
      },
      duns: {
        serializedName: "duns",
        nullable: true,
        type: {
          name: "String",
        },
      },
      externalIdentifier: {
        serializedName: "externalIdentifier",
        nullable: true,
        type: {
          name: "String",
        },
      },
      tradeRelationship: {
        serializedName: "tradeRelationship",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChangeArchivedTradePartnerRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeArchivedTradePartnerRequest",
    modelProperties: {
      archived: {
        serializedName: "archived",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ChangeArchivedTradePartnerResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeArchivedTradePartnerResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      archived: {
        serializedName: "archived",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ToggleArchiveVesselsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ToggleArchiveVesselsRequest",
    modelProperties: {
      tradePartnerIds: {
        constraints: {
          UniqueItems: true,
        },
        serializedName: "tradePartnerIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ToggleArchiveVesselsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ToggleArchiveVesselsResponse",
    modelProperties: {
      vesselIds: {
        serializedName: "vesselIds",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const UserResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      email: {
        serializedName: "email",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      givenName: {
        serializedName: "givenName",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      surname: {
        serializedName: "surname",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
      profilePictureUrl: {
        serializedName: "profilePictureUrl",
        nullable: true,
        type: {
          name: "String",
        },
      },
      settings: {
        serializedName: "settings",
        type: {
          name: "Composite",
          className: "UserSettingsContract",
        },
      },
      acceptedTermsAndConditions: {
        serializedName: "acceptedTermsAndConditions",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      pinnedAccountId: {
        serializedName: "pinnedAccountId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      currentAccountId: {
        serializedName: "currentAccountId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      userAccounts: {
        serializedName: "userAccounts",
        required: true,
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "UserAccountsResponse",
            },
          },
        },
      },
    },
  },
};

export const UserSettingsContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserSettingsContract",
    modelProperties: {
      emailNotifications: {
        serializedName: "emailNotifications",
        type: {
          name: "Composite",
          className: "EmailNotificationsSettingsContract",
        },
      },
      language: {
        serializedName: "language",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const EmailNotificationsSettingsContract: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "EmailNotificationsSettingsContract",
    modelProperties: {
      notifyInbound: {
        serializedName: "notifyInbound",
        type: {
          name: "Boolean",
        },
      },
      notifyOutbound: {
        serializedName: "notifyOutbound",
        type: {
          name: "Boolean",
        },
      },
      notifyNetworkInvites: {
        serializedName: "notifyNetworkInvites",
        type: {
          name: "Boolean",
        },
      },
      notifySharing: {
        serializedName: "notifySharing",
        type: {
          name: "Boolean",
        },
      },
      notifyDocumentExpiration: {
        serializedName: "notifyDocumentExpiration",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const UserAccountsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserAccountsResponse",
    modelProperties: {
      userId: {
        serializedName: "userId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      accountName: {
        serializedName: "accountName",
        required: true,
        nullable: true,
        type: {
          name: "String",
        },
      },
      role: {
        serializedName: "role",
        required: true,
        type: {
          name: "String",
        },
      },
      isCurrentAccount: {
        serializedName: "isCurrentAccount",
        type: {
          name: "Boolean",
        },
      },
      isPinnedAccount: {
        serializedName: "isPinnedAccount",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ChangeAccountRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeAccountRequest",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const ChangeAcceptedTermsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeAcceptedTermsRequest",
    modelProperties: {
      acceptedTerms: {
        serializedName: "acceptedTerms",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ChangePasswordRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangePasswordRequest",
    modelProperties: {
      password: {
        serializedName: "password",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChangeEmailRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeEmailRequest",
    modelProperties: {
      email: {
        serializedName: "email",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChangePinnedAccountRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangePinnedAccountRequest",
    modelProperties: {
      accountId: {
        serializedName: "accountId",
        type: {
          name: "Uuid",
        },
      },
      pinned: {
        serializedName: "pinned",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const ChangePersonalInfoRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangePersonalInfoRequest",
    modelProperties: {
      givenName: {
        serializedName: "givenName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      surname: {
        serializedName: "surname",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChangePersonalInfoResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangePersonalInfoResponse",
    modelProperties: {
      givenName: {
        serializedName: "givenName",
        nullable: true,
        type: {
          name: "String",
        },
      },
      surname: {
        serializedName: "surname",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChangeSettingsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeSettingsRequest",
    modelProperties: {
      settings: {
        serializedName: "settings",
        type: {
          name: "Composite",
          className: "ChangeUserSettingsRequest",
        },
      },
    },
  },
};

export const ChangeUserSettingsRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeUserSettingsRequest",
    modelProperties: {
      emailNotifications: {
        serializedName: "emailNotifications",
        type: {
          name: "Composite",
          className: "ChangeEmailNotificationsSettingsRequest",
        },
      },
      language: {
        serializedName: "language",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ChangeEmailNotificationsSettingsRequest: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className: "ChangeEmailNotificationsSettingsRequest",
      modelProperties: {
        notifyInbound: {
          serializedName: "notifyInbound",
          nullable: true,
          type: {
            name: "Boolean",
          },
        },
        notifyOutbound: {
          serializedName: "notifyOutbound",
          nullable: true,
          type: {
            name: "Boolean",
          },
        },
        notifyNetworkInvites: {
          serializedName: "notifyNetworkInvites",
          nullable: true,
          type: {
            name: "Boolean",
          },
        },
        notifySharing: {
          serializedName: "notifySharing",
          nullable: true,
          type: {
            name: "Boolean",
          },
        },
        notifyDocumentExpiration: {
          serializedName: "notifyDocumentExpiration",
          nullable: true,
          type: {
            name: "Boolean",
          },
        },
      },
    },
  };

export const ChangeSettingsResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeSettingsResponse",
    modelProperties: {
      settings: {
        serializedName: "settings",
        type: {
          name: "Composite",
          className: "UserSettingsContract",
        },
      },
    },
  },
};

export const ChangeRoleRequest: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ChangeRoleRequest",
    modelProperties: {
      role: {
        serializedName: "role",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const UploadProfilePictureResponse: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UploadProfilePictureResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      url: {
        serializedName: "url",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const PathsMoo8DeApiDocumentsPostRequestbodyContentMultipartFormDataSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "PathsMoo8DeApiDocumentsPostRequestbodyContentMultipartFormDataSchema",
      modelProperties: {
        title: {
          serializedName: "Title",
          type: {
            name: "String",
          },
        },
        file: {
          serializedName: "File",
          type: {
            name: "Stream",
          },
        },
        privacy: {
          serializedName: "Privacy",
          type: {
            name: "String",
          },
        },
        blockchain: {
          serializedName: "Blockchain",
          type: {
            name: "String",
          },
        },
        documentType: {
          serializedName: "DocumentType",
          type: {
            name: "String",
          },
        },
        documentTypeName: {
          serializedName: "DocumentTypeName",
          type: {
            name: "String",
          },
        },
        expirationDate: {
          serializedName: "ExpirationDate",
          type: {
            name: "Date",
          },
        },
        expirationAlertDate: {
          serializedName: "ExpirationAlertDate",
          type: {
            name: "Date",
          },
        },
        locationsIds: {
          constraints: {
            UniqueItems: true,
          },
          serializedName: "LocationsIds",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Uuid",
              },
            },
          },
        },
        productsIds: {
          constraints: {
            UniqueItems: true,
          },
          serializedName: "ProductsIds",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Uuid",
              },
            },
          },
        },
        tradePartnersIds: {
          constraints: {
            UniqueItems: true,
          },
          serializedName: "TradePartnersIds",
          type: {
            name: "Sequence",
            element: {
              type: {
                name: "Uuid",
              },
            },
          },
        },
        purchaseOrderNumber: {
          serializedName: "PurchaseOrderNumber",
          type: {
            name: "String",
          },
        },
        certificationStandard: {
          serializedName: "CertificationStandard",
          type: {
            name: "String",
          },
        },
        certificationAgency: {
          serializedName: "CertificationAgency",
          type: {
            name: "String",
          },
        },
        certificationId: {
          serializedName: "CertificationId",
          type: {
            name: "String",
          },
        },
        shipFrom: {
          serializedName: "ShipFrom",
          type: {
            name: "String",
          },
        },
        shipTo: {
          serializedName: "ShipTo",
          type: {
            name: "String",
          },
        },
        sid: {
          serializedName: "SID",
          type: {
            name: "String",
          },
        },
        cid: {
          serializedName: "CID",
          type: {
            name: "String",
          },
        },
        fob: {
          serializedName: "FOB",
          type: {
            name: "String",
          },
        },
        shipDate: {
          serializedName: "ShipDate",
          type: {
            name: "DateTime",
          },
        },
        carrier: {
          serializedName: "Carrier",
          type: {
            name: "String",
          },
        },
        items: {
          serializedName: "Items",
          type: {
            name: "String",
          },
        },
        quantitiesOfShippedItems: {
          serializedName: "QuantitiesOfShippedItems",
          type: {
            name: "String",
          },
        },
      },
    },
  };

export const Paths9G085CApiProductsUploadPostRequestbodyContentMultipartFormDataSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "Paths9G085CApiProductsUploadPostRequestbodyContentMultipartFormDataSchema",
      modelProperties: {
        file: {
          serializedName: "file",
          type: {
            name: "Stream",
          },
        },
      },
    },
  };

export const Paths177HedqApiUsersUseridSiteadminPatchRequestbodyContentMultipartFormDataSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "Paths177HedqApiUsersUseridSiteadminPatchRequestbodyContentMultipartFormDataSchema",
      modelProperties: {
        isSiteAdmin: {
          serializedName: "isSiteAdmin",
          type: {
            name: "Boolean",
          },
        },
      },
    },
  };

export const Paths15H0Yy5ApiUsersMeProfilePicturePostRequestbodyContentApplicationOctetStreamSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "Paths15H0Yy5ApiUsersMeProfilePicturePostRequestbodyContentApplicationOctetStreamSchema",
      modelProperties: {
        picture: {
          serializedName: "Picture",
          type: {
            name: "Stream",
          },
        },
      },
    },
  };

export const Paths1Wslb08ApiUsersMeProfilePicturePostRequestbodyContentMultipartFormDataSchema: coreClient.CompositeMapper =
  {
    type: {
      name: "Composite",
      className:
        "Paths1Wslb08ApiUsersMeProfilePicturePostRequestbodyContentMultipartFormDataSchema",
      modelProperties: {
        picture: {
          serializedName: "Picture",
          type: {
            name: "Stream",
          },
        },
      },
    },
  };
