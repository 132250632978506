import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import classNames from 'classnames';
import { ActionButtons, GTable } from 'components';
import TraceabilityLotCodeSourceSelect from 'components/GSelect/TraceabilityLotCodeSourceSelect';
import React, { FC, useRef } from 'react';
import { ContainerProductInstanceQuantity, getProductsT, getUom } from 'utils';
import styles from './index.module.less';
import { ContainerTableColumnProps, ContainerTableProps } from './typings';
/**
 * Columns
 * Defines the columns configuration for displaying container product instance quantity data.
 * @param columnProps Additional properties for configuring each column.
 * @param identifier Identifier for distinguishing between different types of columns.
 * @returns An array of ProColumns<ContainerProductInstanceQuantity> objects representing the columns configuration.
 */
const Columns = ({
  columnProps,
  identifier,
  setNewCompanyLocationList,
  newCompanyLocationList,
  onEditRow,
  t,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editableFormRef,
}: ContainerTableColumnProps): ProColumns<ContainerProductInstanceQuantity>[] => [
  {
    dataIndex: 'id',
    hideInTable: true,
    ...columnProps?.id,
  },
  {
    title: t?.('tbl_col_primary_id_title'),
    dataIndex: 'instanceId',
    hideInTable: true,
    ...columnProps?.instanceId,
  },
  {
    title: t?.('traceability_lotcode'),
    dataIndex: 'lotSerial',
    render: (text, record) => (
      <span>
        {getProductsT(identifier)}: {record?.lotSerial || ''} &nbsp;
        <Typography.Text type="secondary">
          {
            // @ts-ignore
            record?.productName || ''
          }
        </Typography.Text>
      </span>
    ),
    ...columnProps?.lotSerial,
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    /* might need in future based on the requirement */
    // hideInTable: identifier === 'Serial',
    sorter: true,
    render: (text, record) => `${record?.quantity || 0} ${getUom({ product: record }) || 'LBS'}`,
    ...columnProps?.quantity,
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    sorter: true,
    // hideInTable: true,
    render: (text, record) =>
      `${record?.totalInventoryAtLocation || 0} ${getUom({ product: record }) || 'LBS'}`,
    ...columnProps?.currentInventory,
    fieldProps: (form, { entity }) => ({
      value: `${entity?.totalInventoryAtLocation || 0}`,
      ...columnProps?.currentInventory?.fieldProps,
    }),
  },
  {
    title: t?.('last_vent'),
    dataIndex: 'lastEvent',
    ellipsis: true,
    ...columnProps?.lastEvent,
  },
  {
    title: t?.('location'),
    dataIndex: 'locationName',
    ellipsis: true,
    ...columnProps?.locationName,
  },
  {
    title: t?.('traceability_lotcode'),
    dataIndex: 'tlcSource',
    tooltip: {
      title: (
        <span className={styles.tooltiplink}>
          {t?.('traceability_info_tooltip')}{' '}
          <Typography.Link
            href="https://www.fda.gov/food/food-safety-modernization-act-fsma/traceability-lot-code"
            target="_blank"
          >
            {t?.('traceability_info_tooltip_link')}
          </Typography.Link>
          .
        </span>
      ),
      icon: <InfoCircleOutlined />,
    },
    valueType: 'select',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('tlcs_required'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('tlcs_placeholder'),
    },
    renderFormItem: (_, { recordKey, record }, form) => (
      <TraceabilityLotCodeSourceSelect
        size="small"
        isAddProduct
        allowLotSerial
        onChange={(value, option: any) => {
          const { setFieldsValue } = form;
          if (option && option?.itemProps) {
            const item: any = option?.itemProps;
            setFieldsValue({
              [String(recordKey)]: {
                tlcSourceDetails: { ...item?.address, name: item?.name },
                tlcSource: item?.name,
              },
            });
          }
        }}
        onClear={() => {
          const { setFieldsValue } = form;
          setFieldsValue({
            [String(recordKey)]: { tlcSource: undefined, tlcSourceDetails: undefined },
          });
        }}
        allowClear
        eventId={record?.eventId || ''}
        updateListHander={(updatedList: DefaultOptionType[]) => {
          setNewCompanyLocationList(updatedList);
        }}
        newCompanyLocationList={newCompanyLocationList}
      />
    ),
    render: (text, record: any) => record?.tlcSource || '',
    ...columnProps?.tlcSource,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: (_text, record) => (
      <ActionButtons record={record} onEdit={onEditRow} showDelete={false} />
    ),
    ...columnProps?.actions,
  },
];

/**
 * ContainerTable
 * Represents a table component for displaying container product instance quantity data.
 * @param products The array of container product instance quantity data to be displayed.
 * @param showHeader Boolean indicating whether to display the table header.
 * @param className Additional CSS classes for styling the table.
 * @param columnProps Additional properties for configuring each column.
 * @param identifier Identifier for distinguishing between different types of columns.
 */
const ContainerTable: FC<ContainerTableProps> = ({
  products,
  showHeader,
  className,
  columnProps,
  identifier,
  setNewCompanyLocationList,
  newCompanyLocationList,
  parentProducts,
  parentRowId,
  parentForm,
  t,
}) => {
  const containerSactionRef = useRef<ActionType>();
  const containerEditableFormRef = useRef<EditableFormInstance<any>>();
  const onEditRow = (actionPayload?: ContainerProductInstanceQuantity) => {
    containerSactionRef.current?.startEditable(actionPayload?.instanceId || 0);
  };
  const onSaveProduct = async (rowKey: RecordKey, data: ContainerProductInstanceQuantity) => {
    containerSactionRef.current?.cancelEditable(data?.instanceId || 0);
    const existingProduct = products?.find((p) => p.instanceId === data?.instanceId);
    if (existingProduct) {
      existingProduct.tlcSource = data?.tlcSource;
      existingProduct.tlcSourceDetails = data?.tlcSourceDetails;
    }
    // update product
    const updatedContainerItems = products?.map((p) =>
      p.instanceId === existingProduct?.instanceId ? existingProduct : p,
    );

    const updatedParentRows = parentProducts?.map(
      (p) =>
        p?.id === parentRowId
          ? {
              ...p,
              containerItems: updatedContainerItems,
            }
          : p,
      [],
    );
    parentForm?.setFieldsValue({
      eventProducts: updatedParentRows,
    });
  };
  return (
    <GTable<ContainerProductInstanceQuantity>
      key="containerSactionRef"
      actionRef={containerSactionRef}
      editableFormRef={containerEditableFormRef}
      rowKey="instanceId"
      className={classNames('expanded-row-tbl', className)}
      columns={Columns({
        columnProps,
        identifier,
        setNewCompanyLocationList,
        newCompanyLocationList,
        onEditRow,
        // @ts-ignore
        editableFormRef: { containerEditableFormRef },
        t,
      })}
      options={{
        setting: false,
        reload: false,
      }}
      value={products}
      showHeader={showHeader}
      editable={{
        onSave: (rowKey, data) => onSaveProduct(rowKey, data),
        onCancel: async (_rowKey, data) => {
          containerSactionRef.current?.cancelEditable(data?.instanceId || 0);
        },
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
    />
  );
};
export default React.memo(ContainerTable);
