import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { Modal, message } from 'antd';
import { useDrawerVisibility } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useProductById, useUpdateProductById } from 'services/api';
import { UpdateProductRequest } from 'services/api/client/src';
import { errorHandler, getEventsT, hasUnsavedChanges } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { AddProductColumns, DataItem } from '../../Actions/AddProduct/Columns';

const ProductSettingsForm = () => {
  const queryClient = useQueryClient();
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const editableFormRef = useRef<EditableFormInstance<DataItem>>();
  const { productId = '1' } = useParams();
  const { data } = useProductById(productId);
  const [fetched, setFetched] = useState(false);
  const [allowChangeDetails, setAllowChangeDetails] = useState(false);
  const updateProduct = useUpdateProductById(queryClient);
  const { t } = useTranslation('pages', { keyPrefix: 'products.settings' });
  const hasDefinedUnits = !!data?.unitDescriptor || false;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCustomAttributes = (productMasterData: any[]) =>
    productMasterData?.map((el) => {
      let namespaceValue = el?.namespace || '';
      switch (namespaceValue) {
        case 'custom':
          namespaceValue = t?.('custom');
          break;
        default:
          break;
      }
      return {
        id: el.id || uuidv4(),
        attribute: el?.name || '',
        value: el?.value || '',
        elementId: String(el?.elementId || ''),
        namespace: namespaceValue,
      };
    }) || [];

  const productData: DataItem = useMemo(
    () => ({
      name: data?.name || '',
      wholechainProductId: data?.id || '',
      currentInventoryTotal: data?.currentInventoryTotal || 0,
      currentInventory: data?.currentInventory || 0,
      epcUrn: data?.urn || '',
      gtin: data?.gtin || '',
      traceabilityType: data?.productIdentifierType || '',
      identifier: data?.urn || '',
      blockchain: data?.blockchain || '',
      unitOfMeasure: !hasDefinedUnits ? data?.simpleUnitOfMeasurement : undefined,
      uomQuantity: hasDefinedUnits ? data?.simpleUnitOfMeasurement : undefined,
      definedUnits: hasDefinedUnits || false,
      dataSharingPolicy: data?.sharingPolicy || '',
      unitDescriptor: data?.unitDescriptor || undefined,
      unitQuantity: Number(data?.unitQuantity || 0),
      customDataAttributes: (data?.productMasterData?.length || 0) > 0,
      customAttributes: getCustomAttributes(data?.productMasterData || []),
      subProducts: data?.subProducts || [],
      plu: data?.plu,
      itemid: data?.externalIdentifier,
    }),
    [data, hasDefinedUnits, getCustomAttributes],
  );
  const onFinish = async (formData: DataItem) => {
    try {
      hasUnsavedChanges();

      /* The unitOfMeasure now depends if defined units are enabled or not  */
      // let uomChanges = false;
      let uomValue: string | undefined;
      let unitDescriptor: string | undefined;
      let unitQuantity: number | undefined;

      const isLot = formData?.traceabilityType === 'Lot';
      const definedUnits = isLot ? formData?.definedUnits : false;

      if (definedUnits) {
        if (!formData.uomQuantity) {
          throw new Error(t('uom_req'));
        }
        uomValue = formData.uomQuantity;
        unitDescriptor = formData.unitDescriptor;
        unitQuantity = Number(formData?.unitQuantity || 0);
      } else {
        uomValue = formData.unitOfMeasure;
        unitDescriptor = undefined;
        unitQuantity = undefined;
      }

      const reqData: UpdateProductRequest = {
        id: productId,
        name: formData?.name,
        gtin: formData?.gtin?.length === 12 ? `00${formData?.gtin || ''}` : formData?.gtin || '',
        productIdentifierType: formData.traceabilityType,
        simpleUnitOfMeasurement: uomValue,
        plu: formData.plu,
        externalIdentifier: formData.itemid,
        // definedUnits,
        unitDescriptor,
        unitQuantity,
        productMasterData:
          formData?.customAttributes?.map((el) => ({
            id: String(el.id)?.includes('newField') ? undefined : el.id,
            elementId: String(el.elementId),
            name: el.attribute,
            value: el.value,
            namespace: el.namespace,
          })) || [],
        sharingPolicy: formData.dataSharingPolicy,
        subProductIds: formData?.subProducts?.map((el) => el?.id || '') || [],
      };
      await updateProduct.mutateAsync({ productId, product: reqData });

      setTimeout(() => {
        message.success(t('submit_success', { product: formData.name }));
      }, 1000);
      closeDrawer();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  /* on close modal */
  const onClose = () => {
    const hasFilled = editableFormRef?.current?.isFieldsTouched();

    if (hasFilled) {
      Modal.warning({
        title: t?.('unsaved_changes_title'),
        content: t?.('unsaved_changes_desc'),
        okText: t?.('unsaved_changes_submit'),
        cancelText: t?.('unsaved_changes_cancel'),
        onOk: closeDrawer,
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      });
    } else {
      closeDrawer();
    }
  };
  const onSubmit = async () => {
    try {
      await editableFormRef?.current?.validateFields();
      editableFormRef?.current?.submit();
    } catch (error) {
      message.error(getEventsT('events_field_req'));
    }
  };

  // setting data
  useEffect(() => {
    if (data && !fetched) {
      editableFormRef.current?.setFieldsValue(productData);
      setFetched(true);
    }

    if (productData.subProducts?.length === 0 && productData.currentInventoryTotal === 0)
      setAllowChangeDetails(true);
    else setAllowChangeDetails(false);
  }, [productData, data, fetched]);
  // effect to set changes in sub-products
  useEffect(() => {
    if (data && fetched) {
      editableFormRef.current?.setFieldsValue({
        subProducts: data?.subProducts || [],
      });
    }
  }, [data, fetched]);

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="DrawerForm"
      columns={AddProductColumns({
        isProductSettingsDrawer: true,
        allowChangeDetails,
      })}
      grid
      title={productData?.name || ''}
      visible={visible}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        ...restDrawerVisibility,
        onClose,
      }}
      submitter={{
        searchConfig: {
          submitText: t('submit_btn_text'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: (_, defaultDoms) => defaultDoms[1],
        onSubmit,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default React.memo(ProductSettingsForm);
