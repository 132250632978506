import { Space, Typography } from 'antd';
import useListLocation from 'hooks/useListLocation';
import { GetLocationsOptionalParams } from 'hooks/useListLocation/typings';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useMemo, useState } from 'react';
import { LocationResponse } from 'services/api/client/src';
import GSelect from './GSelect';
import { FetchDataFn, LocationSelectProps } from './typings';
/* note: filtering to done on frontend */
interface Address {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}

const flattenAddress = (address?: Address) => {
  if (!address) {
    return '';
  }
  const shortAddress = {
    line1: address.line1,
    city: address.city,
    state: address.state,
    country: address.country,
  };
  const values = Object.values(shortAddress);
  return values.filter((value) => value && String(value).trim()).join(', ');
};

const LocationSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  partnerId,
  initParams,
  disabledItem,
  filterItem,
  spaceClassName,
  assignUrnAsValue,
  ...props
}: LocationSelectProps<ValueType, OptionType>) => {
  const [params] = useState<GetLocationsOptionalParams>({ ...initParams });
  const { data, isLoading } = useListLocation({
    ...params,
    // archived: false,
    ...(partnerId && { partnerIds: [partnerId] }),
  });
  const locations = useMemo(() => data || [], [data]);
  const filterOption = useCallback(
    (option?: DefaultOptionType) => {
      if (!filterItem) {
        return true;
      }
      return filterItem?.(option?.itemProps as OptionType['itemProps']);
    },
    [filterItem],
  );
  const structureOptions = useCallback(
    (option: LocationResponse) => ({
      label: (
        <Space className={spaceClassName}>
          <Typography.Text strong>{option.name}</Typography.Text>
          <Typography.Text>
            {option.tradePartnerName ? `${option.tradePartnerName}-` : ''}
            {flattenAddress(option?.address)?.trim() ||
              `${option?.address?.geoCoordinates?.latitude},${option?.address?.geoCoordinates?.longitude}`}
          </Typography.Text>
        </Space>
      ),
      value: String(assignUrnAsValue ? option?.urn : option.id),
      name: `${option?.name} ${flattenAddress(option?.address)} ${option.tradePartnerName}`,
      itemProps: option,
      disabled: disabledItem?.(option) || false,
    }),
    [assignUrnAsValue, disabledItem, spaceClassName],
  );

  // we create here the list with the shape the select is expecting for

  const options: Array<DefaultOptionType> = useMemo(
    () => locations?.map(structureOptions)?.filter(filterOption) || [],
    [locations, structureOptions, filterOption],
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(() => {}, []);

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Select Location"
      {...props}
      loading={isLoading}
      options={options as OptionType[]}
      fetchData={fetchData}
      allowFilterOption
      optionFilterProp="name"
    />
  );
};

export default LocationSelect;
