/* eslint-disable no-useless-catch */
import { FormInstance, Modal, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { MessageType } from 'antd/lib/message';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { useModalVisibility } from 'hooks';
import moment from 'moment';
import { useEventsStore } from 'pages/Events/hooks';
import { ProductInventoryItem } from 'pages/Products';
import { DataItem as BatchShipDataItem } from 'pages/Shipments/Forms/typings';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getProductInstances,
  useAccount,
  useAttributes,
  useCreateShipEvent,
  useMultiShipEvent,
  useProductById,
  useProductInstanceQuantities,
  useRemoveEvent,
  useUpdateTradePartnerById,
} from 'services/api';
import { EventTemplatesResponse, MultiShipRequest } from 'services/api/client/src';
import {
  camelize,
  downloadJsonToCSV,
  errorHandler,
  filterTCertAttribute,
  getDisabledDate,
  getDisabledTime,
  getEventsT,
  getProductsT,
  getTParam,
  getUom,
  mapCertDocuments,
  mapInvItem,
  supportEmailLink,
} from 'utils';
import { isObject } from 'utils/locations';
import { v4 as uuidv4 } from 'uuid';
import { DataItem } from '../CommissionForm.fields';
import styles from '../index.module.less';
import {
  OnErrorRemoveEvent,
  OnErrorShipEvent,
  TimeValidationFn,
  useEventActionsProps,
} from './typings';

const useEventActions = (defaultProps?: useEventActionsProps, disableAPICall?: boolean) => {
  const queryClient = useQueryClient();
  const eventErrorModal = useModalVisibility();
  const { data: account } = useAccount();
  const { data: attributes } = useAttributes();
  const createShipEvent = useCreateShipEvent(queryClient);
  const createMultiShipEvent = useMultiShipEvent(queryClient);
  const updateTradePartner = useUpdateTradePartnerById(queryClient);
  const removeEvent = useRemoveEvent(queryClient);
  const [queryParams] = useSearchParams();

  const defaultProductId = useMemo(() => defaultProps?.productId, [defaultProps]);
  const { productId } = useParams();

  const { data: instances } = useProductInstanceQuantities(
    productId || defaultProductId || '',
    {
      pageNumber: 1,
      pageSize: 20,
      sortBy: '-loggedDate',
      isHistory: false,
    },
    !disableAPICall,
  );
  const { data: product } = useProductById(
    productId || defaultProductId || '',
    {},
    !disableAPICall,
  );
  const isSerial = useMemo(() => product?.productIdentifierType === 'Serial', [product]);
  const identifier = useMemo(() => product?.productIdentifierType || 'Lot', [product]);
  const [sameDayMessage, setSameDayMessage] = useState<MessageType>();
  const [msgKey, setMsgKey] = useState<string>();
  const { template, setTemplate, dataEntryMethod } = useEventsStore();

  const defaultContainerIdArray = useMemo(
    () => defaultProps?.containerIdArray || [],
    [defaultProps],
  );
  const defaultProductIdArray = useMemo(() => defaultProps?.productIdArray || [], [defaultProps]);
  const defaultEventDate = useMemo(() => defaultProps?.eventDate || '', [defaultProps]);
  const defaultLocationId = useMemo(() => defaultProps?.locationId, [defaultProps]);
  const defaultLocationName = useMemo(() => defaultProps?.locationName || '', [defaultProps]);
  const defaultTradePartnerId = useMemo(() => defaultProps?.tradePartnerId || '', [defaultProps]);

  const containerIdArray = queryParams?.get('containers')?.split(',') || defaultContainerIdArray;
  const productIdArray = queryParams?.get('products')?.split(',') || defaultProductIdArray;
  const eventDate = queryParams?.get('eventDate') || defaultEventDate;
  const locationId = queryParams?.get('locationId') || defaultLocationId;
  const tradePartnerId = queryParams?.get('tradePartnerId') || defaultTradePartnerId;
  const locationName = queryParams?.get('locationName') || defaultLocationName;

  const lotId = productIdArray?.[0] || '';

  const [lastEventDate, setLastEventDate] = useState<string>(eventDate);

  useEffect(() => {
    if (eventDate) {
      setLastEventDate(eventDate);
    }
  }, [eventDate]);

  const disabledDate = useMemo(() => getDisabledDate(lastEventDate), [lastEventDate]);
  const disabledTime = useMemo(() => getDisabledTime(lastEventDate), [lastEventDate]);
  const timeValidation = useCallback<TimeValidationFn>(
    (date, prevEventDate) => {
      const rules: Array<Rule> = [
        {
          /* value should be greater than lastEventDate time */
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            }

            const prevEventDateStr = prevEventDate || lastEventDate;
            const lastEventDateM = moment(new Date(prevEventDateStr));
            const eventTime = !(typeof value === 'string' || value instanceof String)
              ? moment(value, 'HH:mm:ss')
              : String(value);

            const eDate = !(typeof date === 'string' || date instanceof String)
              ? moment(date).format('YYYY-MM-DD')
              : String(date);
            const eventDateTime = moment(
              `${moment(new Date(eDate)).format('YYYY-MM-DD')} ${moment(eventTime).format(
                'HH:mm:ss',
              )}`,
              'YYYY-MM-DD HH:mm:ss',
            );

            const lastEventTime = !(typeof prevEventDateStr === 'string')
              ? moment(new Date(prevEventDateStr), 'HH:mm:ss')
              : prevEventDateStr;

            switch (true) {
              case moment(eDate).isSame(lastEventDateM, 'day'): {
                const isSameofBeforeTime = moment(eventDateTime).isSameOrBefore(
                  lastEventTime,
                  'second',
                );

                if (isSameofBeforeTime) {
                  return Promise.reject(
                    new Error(getTParam('error.events.eventtime_greater_last_event')),
                  );
                }
                break;
              }

              default:
                break;
            }
            return Promise.resolve();
          },
        },
      ];
      return rules;
    },
    [lastEventDate],
  );

  const defaultTimezone = useMemo(
    () => account?.timezone || '(UTC-05:00) Eastern Time (US & Canada)',
    [account],
  );
  const defaultAttribute = useMemo(
    () => ({
      id: template?.id || '',
      name: 'Custom Data Template',
      value: template?.templateName || 'None',
    }),
    [template],
  );

  const defaultUom = useMemo(() => getUom({ product }), [product]);

  const triggerSameDayMessage = useCallback(
    (isMultipleDates?: boolean) => {
      message.destroy();
      const key = uuidv4();
      const messageFn = message.info(
        {
          content: !isMultipleDates
            ? getProductsT('same_day_tooltip')
            : getProductsT('same_day_multiple_tooltip'),
          key,
          duration: 0,
          className: styles.antmessage,
        },
        0,
      );
      setSameDayMessage(messageFn);
      setMsgKey(key);
    },
    [setMsgKey, setSameDayMessage],
  );
  const clearSameDayMessage = useCallback(
    (destroyAll?: boolean) => {
      message.destroy(!destroyAll ? msgKey : undefined);
      if (sameDayMessage) {
        sameDayMessage();
        setSameDayMessage(undefined);
      }
    },
    [sameDayMessage, msgKey],
  );
  /* note: eventTime is optional and in Locale time ( with browsers timezone offset) */
  const showFutureDayPopup = useCallback(() => {
    const t = getProductsT;
    Modal.info({
      title: t?.('future_date_title'),
      content: t?.('future_date_message'),
      okText: t?.('done'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);
  const onChangeDate = useCallback(
    (
      form: FormInstance<any>,
      value: string,
      isMultipleDates?: boolean,
      rowKey?: string,
      eventTime?: string,
      timeKey = 'time',
    ) => {
      switch (true) {
        case !!value: {
          const edate = eventTime || lastEventDate;
          const isSameDay = moment(value).isSame(moment(new Date(String(edate))), 'day');

          if (isSameDay) {
            const clearValue =
              isMultipleDates && rowKey
                ? { [String(rowKey)]: { [timeKey]: undefined } }
                : { time: undefined };
            form?.setFieldsValue(clearValue);
            triggerSameDayMessage(isMultipleDates);
          } else {
            const setValue =
              isMultipleDates && rowKey
                ? { [String(rowKey)]: { [timeKey]: '12:00:00' } }
                : { time: '12:00:00' };
            form.setFieldsValue(setValue);
            clearSameDayMessage();
          }

          if (moment(new Date()).isBefore(moment(value), 'day')) {
            showFutureDayPopup();
          }
          break;
        }

        default:
          clearSameDayMessage();
          break;
      }
    },
    [triggerSameDayMessage, clearSameDayMessage, showFutureDayPopup, lastEventDate],
  );

  const onChangeLocation = useCallback(
    (
      form: FormInstance<any>,
      location?: string,
      optionTemplate?: EventTemplatesResponse,
      eventType?: string,
    ) => {
      switch (true) {
        case attributes &&
          (optionTemplate || template) &&
          (attributes?.results?.length || 0) > 0 &&
          ((optionTemplate || template)?.templateAttributes?.length || 0) > 0:
          (optionTemplate || template)?.templateAttributes?.forEach((field, fieldIdx) => {
            const fieldProperties = attributes?.results?.map((el) => ({
              id: el?.id,
              ...el?.fieldProperties,
            }));
            const propertyName = field?.attribute?.fieldProperties?.fields?.propertyName;
            const dValue = field?.defaultValue;
            const attributeField = fieldProperties?.find(
              (attr) => attr?.id === field?.attribute?.id,
            );

            if (attributeField) {
              const defaultValues = attributeField?.values?.defaultValues;
              const templateDefaultValue = dValue;
              const customProperties = form?.getFieldValue('customProperties') || {};
              const existingCsvData = form?.getFieldValue('csvData') || [];
              const newCustomProperties = isObject(customProperties)
                ? { ...customProperties }
                : [...customProperties];

              const defaultValue = defaultValues?.find((el) => el?.locationId === location);
              const valueToSet = templateDefaultValue || defaultValue?.value || undefined;

              if (valueToSet !== undefined) {
                if (isObject(customProperties)) {
                  newCustomProperties[propertyName || ''] = valueToSet;
                } else {
                  newCustomProperties[fieldIdx] = valueToSet;
                }

                if (
                  dataEntryMethod === 'manual' ||
                  eventType === 'transform' ||
                  eventType === 'ship' ||
                  eventType === 'decommission'
                ) {
                  form?.setFieldsValue({ customProperties: newCustomProperties });
                } else {
                  const updatedCsvData = existingCsvData?.map((el: DataItem) => ({
                    ...el,
                    [propertyName || '']: valueToSet,
                  }));
                  form?.setFieldsValue({ csvData: updatedCsvData });
                }
              } else {
                if (isObject(customProperties)) {
                  newCustomProperties[propertyName || ''] = undefined;
                } else {
                  newCustomProperties[fieldIdx] = undefined;
                }

                if (
                  dataEntryMethod === 'manual' ||
                  eventType === 'transform' ||
                  eventType === 'ship' ||
                  eventType === 'decommission'
                ) {
                  form?.setFieldsValue({ customProperties: newCustomProperties });
                } else {
                  const updatedCsvData = existingCsvData?.map((el: DataItem) => ({
                    ...el,
                    [propertyName || '']: undefined,
                  }));
                  form?.setFieldsValue({ csvData: updatedCsvData });
                }
              }
            }
          });
          break;
        default:
          // Handle other cases here
          break;
      }
    },
    [attributes, template, dataEntryMethod],
  );

  const onChangeTemplate = useCallback(
    (
      form: FormInstance<any>,
      option: any,
      value: string,
      location?: string,
      eventType?: string,
    ) => {
      const item: EventTemplatesResponse = option?.itemProps;
      const tFields = item?.templateAttributes || [];
      const bizStep = tFields?.find(
        (field) => field?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep',
      );
      const disposition = tFields?.find(
        (field) => field?.attribute?.fieldProperties?.fields?.propertyName === 'disposition',
      );
      const templateAttributes = tFields?.filter(filterTCertAttribute);
      const templateItem: EventTemplatesResponse = {
        ...item,
        templateAttributes,
      };

      setTemplate(templateItem);

      /* update default values for certification fields */
      const certdefaultValuesExist = (item?.templateCertifications?.length || 0) > 0;
      /* map default value of each property name to respective fields in certificationList */
      const certificationList =
        certdefaultValuesExist && item?.allowCertificationDocuments
          ? item?.templateCertifications?.map(mapCertDocuments)
          : [];

      /* update default values for vessel catch fields and other template fields */
      const customProperties = templateAttributes?.reduce((acc, curr) => {
        acc[(curr?.attribute?.fieldProperties?.fields?.propertyName || '') as keyof DataItem] =
          curr?.defaultValue || undefined;
        return acc;
      }, {} as DataItem);

      form?.setFieldsValue({
        customData: value,
        ...(bizStep?.attribute?.fieldProperties?.values?.valueOptions?.[0] && {
          bizStep: bizStep?.attribute?.fieldProperties?.values?.valueOptions?.[0],
        }),
        ...(disposition?.attribute?.fieldProperties?.values?.valueOptions?.[0] && {
          disposition: disposition?.attribute?.fieldProperties?.values?.valueOptions?.[0],
        }),
        certificationList,
        customProperties,
      });

      /* update default values depending on location */

      if (location) {
        onChangeLocation(form, location, item, eventType);
      }
    },
    [setTemplate, onChangeLocation],
  );

  const onCreateShipEvent = useCallback(
    async (reqBody: MultiShipRequest) => {
      try {
        const result = await createShipEvent.mutateAsync(reqBody);
        return result;
      } catch (error) {
        throw error;
      }
    },
    [createShipEvent],
  );
  const onCreateBatchShipEvent = useCallback(
    async (reqBody: any, formData: BatchShipDataItem) => {
      try {
        const result = await createMultiShipEvent.mutateAsync(
          reqBody?.ticketItems?.map((ticket: any) => ({
            ticketId: reqBody?.ticketId,
            purchaseOrder: ticket?.purchaseOrder || '',
            bizStep: formData.bizStep,
            disposition: formData.disposition,
            eventTime: ticket?.eventTime,
            eventTimeZone: formData.timeZone,
            originLocationId: formData?.shipFromAddress || '',
            destinationLocationId: formData?.shipToAddress || '',
            products: [
              {
                instanceId: ticket.instanceId,
                quantity: Number(ticket?.quantity || 0),
              },
            ],
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            // @ts-ignore deprecated
            customProperties: formData?.template?.templateFields?.map((field: any, indx: any) => ({
              name: field?.propertyName || camelize(field?.name || ''),
              value: formData.customProperties?.[indx],
            })),
          })) || [],
        );
        return result;
      } catch (error) {
        throw error;
      }
    },
    [createMultiShipEvent],
  );

  const onDownloadCSVInventoryMultiple = useCallback(
    async (rows?: GTableDateType[], fileName?: string) => {
      if (rows?.length) {
        const csvData =
          rows?.map((item) => ({
            ...item,
            address: item?.address?.line2 || '',
            containerItems: item?.containerItems?.length
              ? JSON.stringify(
                  item?.containerItems?.map((record: any) => ({
                    primaryId: !record?.isContainer
                      ? `${getProductsT(record.productIdentifierType)}: ${record?.lotSerial || ''}`
                      : `${getProductsT('sscc')}: ${record?.containerIdentifier || ''}`,
                    quantity: `${record?.quantity || ''} ${defaultUom}`,
                  })),
                )
              : '',
          })) || [];
        downloadJsonToCSV(csvData, fileName);
      } else {
        const instancesAPICall = await getProductInstances(productId || defaultProductId || '', {
          pageNumber: 1,
          pageSize: instances?.totalItems || 20,
          sortBy: '-loggedDate',
          isHistory: false,
        });
        const inventory = instancesAPICall?.results?.map(mapInvItem) || [];
        const csvData =
          inventory?.map((item) => ({
            ...item,
            address: item?.address?.line2 || '',
            containerItems: item?.containerItems?.length
              ? JSON.stringify(
                  item?.containerItems?.map((record: any) => ({
                    primaryId: !record?.isContainer
                      ? `${getProductsT(record.productIdentifierType)}: ${record?.lotSerial || ''}`
                      : `${getProductsT('sscc')}: ${record?.containerIdentifier || ''}`,
                    quantity: `${record?.quantity || ''} ${defaultUom}`,
                  })),
                )
              : '',
          })) || [];
        downloadJsonToCSV(csvData, fileName);
      }
    },
    [defaultProductId, defaultUom, instances?.totalItems, productId],
  );

  const onDownloadCSVMultiple = useCallback(
    async (rows?: GTableDateType[]) => {
      if (rows?.length) {
        downloadJsonToCSV(rows);
      } else {
        const instancesAPICall = await getProductInstances(productId || defaultProductId || '', {
          pageNumber: 1,
          pageSize: instances?.totalItems || 20,
          sortBy: '-loggedDate',
          isHistory: false,
        });
        downloadJsonToCSV(instancesAPICall?.results || []);
      }
    },
    [defaultProductId, instances?.totalItems, productId],
  );
  const onDownloadCSV = useCallback((actionPayload?: ProductInventoryItem) => {
    if (actionPayload) {
      downloadJsonToCSV([actionPayload]);
    }
  }, []);

  const onReportEventError = useCallback(() => {
    eventErrorModal.show();
  }, [eventErrorModal]);

  const onErrorRemoveEvent = useCallback<OnErrorRemoveEvent>(
    ({ identifier: identifieR, lotSerial, event }) => {
      const t = (key: string, param?: object) =>
        getTParam(`products.current_inventory.${key}`, param);
      Modal.warning({
        title: t?.('subsequent_event_title'),
        content: t?.('subsequent_event_desc', {
          event,
          identifier: identifieR,
          lotSerial,
        }),
        okText: t?.('subsequent_event_confirm'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
        zIndex: 9999,
      });
    },
    [],
  );
  const onErrorShipEvent = useCallback<OnErrorShipEvent>(() => {
    const t = (key: string, param?: object) => getTParam(`products.${key}`, param);
    Modal.error({
      title: t('ship_event_error_title'),
      content: (
        <div>
          {t('ship_event_error_desc')}
          <a href={`mailto:${supportEmailLink}`}>{` ${t('ship_event_contact_us')} `}</a>.
        </div>
      ),
      okText: t?.('ship_event_error_confirm'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
      zIndex: 9999,
    });
  }, []);

  const onRemoveEvent = useCallback(
    (actionPayload?: ProductInventoryItem) => {
      const t = (key: string, param?: object) =>
        getTParam(`products.current_inventory.${key}`, param);
      const isContainer = actionPayload?.isContainer || false;
      const primaryIdentifier = isContainer
        ? getProductsT('sscc')
        : getProductsT(actionPayload?.productIdentifierType || '');
      const lotSerial = isContainer
        ? actionPayload?.containerIdentifier
        : actionPayload?.lotSerial || '';
      Modal.warning({
        title: t('remove_event_title', {
          event: getEventsT(actionPayload?.eventName || ''),
          identifier: primaryIdentifier,
          lotSerial,
        }),
        content: t('remove_event_desc', {
          event: getEventsT(actionPayload?.eventName || '')?.toLowerCase(),
          identifier: primaryIdentifier,
          lotSerial,
        }),
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        okText: t('remove_event_confirm'),
        cancelText: t('remove_event_cancel'),
        okCancel: true,
        onOk: async () => {
          try {
            await removeEvent.mutateAsync(actionPayload?.eventId || '');
            message.success(
              t('remove_event_success_msg', {
                event: getEventsT(actionPayload?.eventName || ''),
                identifier: primaryIdentifier,
                lotSerial: actionPayload?.lotSerial || '',
              }),
            );
          } catch (error) {
            onErrorRemoveEvent({
              event: getEventsT(actionPayload?.eventName || '')?.toLowerCase(),
              identifier: primaryIdentifier,
              lotSerial: actionPayload?.lotSerial || '',
              message: errorHandler(error),
            });
          }
        },
        centered: true,
      });
    },
    [removeEvent, onErrorRemoveEvent],
  );

  /* toggle show trace modal  */
  const toggleTraceModal = useCallback(
    async (partnerId: string, showTraceInfoModal: boolean) => {
      try {
        const result = await updateTradePartner.mutateAsync({
          id: partnerId,
          options: {
            // @ts-ignore missing field
            showTraceInfoModal,
          },
        });
        return result;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.info(error);
        return undefined;
      }
    },
    [updateTradePartner],
  );

  return {
    eventErrorModal,
    productId,
    locationId,
    tradePartnerId,
    locationName,
    product,
    containerIdArray,
    productIdArray,
    lotId,
    lastEventDate,
    defaultUom,
    defaultTimezone,
    sameDayMessage,
    defaultAttribute,
    isSerial,
    identifier,
    timeValidation,
    setLastEventDate,
    clearSameDayMessage,
    disabledDate,
    disabledTime,
    onChangeDate,
    onChangeTemplate,
    onCreateShipEvent,
    onCreateBatchShipEvent,
    onChangeLocation,
    onDownloadCSVMultiple,
    onDownloadCSV,
    onReportEventError,
    onDownloadCSVInventoryMultiple,
    onRemoveEvent,
    toggleTraceModal,
    onErrorShipEvent,
  };
};

export default useEventActions;
