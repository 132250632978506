import { ProColumns } from '@ant-design/pro-table';
import { FormInstance, Modal, message } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs from 'dayjs';
import { queryClient } from 'index';
import moment, { Moment } from 'moment';
import { DecommissionProductItem, ShipProductItem } from 'pages/Events';
import { AggregateProductItem } from 'pages/Events/components/Forms/AggregationForm.fields';
import { RowConfig } from 'pages/Events/components/Forms/hooks/typings';
import { ProductInventoryItem } from 'pages/Products';
import { BatchShipProductItem } from 'pages/Shipments/typings';
import pluralize from 'pluralize';
import {
  EventCertificationContract,
  EventDetailResponse,
  EventNodeStub,
  EventResponse,
  EventTemplatesResponse,
  InventoryResponse,
  ListBizStepResponse,
  ListDispositionResponse,
  MapDiagramEdge,
  MapModel,
  MapNode,
  ProductResponse,
  TemplateCertificationResponse,
} from 'services/api/client/src';
import { v4 as uuidv4 } from 'uuid';
import { colProps6Cols } from './columns';
import { dateLocaleFormat, fieldTypes, isSameKey } from './helpers';
import styles from './index.module.less';
import { getProductsT, getT, getTParam } from './language';
import { isObject } from './locations';
import {
  CSVTemplate,
  ContainerProductInstanceQuantity,
  EventDataItem,
  GetCSVCustomPropertiesFn,
  GetCertPropertiesFn,
  GetCustomPropertiesFn,
  GetLocationByIdFn,
  GetLocationNameByIdFn,
  GetTradePartnerIdByIdFn,
  GetTradePartnerOByIdFn,
  GetUomEventProps,
  GetUomProps,
  LotItem,
  MapCertPropertiesFn,
  MapEventNodes,
  MapEventNodesFn,
  ProField,
  RecordType,
} from './typings';

/**
 * Retrieves the unit of measure (UOM) string for a given product.
 * @param props An object containing the product information.
 * @returns The unit of measure string based on the product details.
 */
export const getUom = ({ product }: GetUomProps) => {
  // Alias for the translation function.
  const t = getProductsT;

  // Check if unit quantity exists and is greater than 0.
  const unitQuantityExist = product?.unitQuantity && Number(product?.unitQuantity) > 0;
  // Construct the unit quantity text if it exists.
  const unitQuantityText = unitQuantityExist ? `x ${product.unitQuantity} ` : '';

  // Get the unit of measure text based on the product's unitOfMeasure property.
  const uom =
    (product as ProductResponse)?.simpleUnitOfMeasurement ||
    (product as EventDetailResponse)?.unitOfMeasure ||
    '';
  const unitOfMeasureText = uom
    ? t?.(String(uom).toLowerCase()) || (product as ProductResponse)?.simpleUnitOfMeasurement
    : '';

  // Get the unit descriptor text based on the product's unitDescriptor property.
  const unitDescriptorText = product?.unitDescriptor
    ? t?.(`descriptor_${String(product.unitDescriptor).toLowerCase()}`) || product?.unitDescriptor
    : '';

  // Cast the product to Product type to access additional properties.
  const serialProduct = product as ProductResponse;
  const sProduct = product as EventDetailResponse;
  // Check if the product is serial.
  const isSerial =
    serialProduct?.productIdentifierType === 'Serial' || sProduct?.identifierType === 'Serial';
  // Construct the final UOM string based on whether the product is serial or not.
  const uomString = !isSerial
    ? `${unitQuantityText}${unitOfMeasureText}${
        unitDescriptorText ? ` ${t?.('per')} ` : ''
      }${pluralize.singular(unitDescriptorText)}`
    : t('item');

  return uomString;
};

/**
 * Retrieves the unit of measure (UOM) string for an event.
 * @param props An object containing the event information.
 * @returns The unit of measure string based on the event details.
 */
export const getUomEvent = ({ event }: GetUomEventProps) => {
  // Alias for the translation function.
  const t = getProductsT;

  // Construct the unit quantity text if it exists.
  const unitQuantityText = event?.unitQuantity ? `x ${event.unitQuantity} ` : '';

  // Get the unit of measure text based on the event's uom property.
  const unitOfMeasureText = event?.simpleUnitOfMeasurement
    ? t?.(String(event.simpleUnitOfMeasurement).toLowerCase()) || event?.simpleUnitOfMeasurement
    : '';

  // Get the unit descriptor text based on the event's unitDescriptor property.
  const unitDescriptorText = event?.unitDescriptor
    ? t?.(`descriptor_${String(event.unitDescriptor).toLowerCase()}`) || event?.unitDescriptor
    : '';

  // Check if the event's product is serial.
  const isSerial = event?.productIdentifierType === 'Serial';

  // Construct the final UOM string based on whether the product is serial or not.
  const uomString = !isSerial
    ? `${unitQuantityText}${unitOfMeasureText}${
        unitDescriptorText ? ` ${t?.('per')} ` : ''
      }${pluralize.singular(unitDescriptorText)}`
    : t('item');

  return uomString;
};

/**
 * Retrieves the unit of measure (UOM) string for a container product instance quantity.
 * @param product The container product instance quantity.
 * @returns The unit of measure string based on the product details.
 */
export const getContainerUom = (product: ContainerProductInstanceQuantity) => {
  // Alias for the translation function.
  const t = getProductsT;

  // Construct the unit quantity text if it exists.
  const unitQuantityText = product?.unitQuantity ? `x ${product.unitQuantity} ` : '';

  // Get the unit of measure text based on the product's unitOfMeasure property.
  const unitOfMeasureText = product?.unitOfMeasure
    ? t?.(String(product.unitOfMeasure).toLowerCase()) || product?.unitOfMeasure
    : '';

  // Get the unit descriptor text based on the product's unitDescriptor property.
  const unitDescriptorText = product?.unitDescriptor
    ? t?.(`descriptor_${String(product.unitDescriptor).toLowerCase()}`) || product?.unitDescriptor
    : '';

  // Cast the product to Product type to access additional properties.
  const serialProduct = product as ProductResponse;

  // Check if the product is serial.
  const isSerial = serialProduct?.productIdentifierType === 'Serial';

  // Construct the final UOM string based on whether the product is serial or not.
  const uomString = !isSerial
    ? `${unitQuantityText}${unitOfMeasureText} ${unitDescriptorText}`
    : t('item');

  return uomString;
};

// allow same day but disable past dates
/**
 * Generates a function to disable dates before the specified event date.
 * @param eventDate The event date.
 * @returns A function to disable dates before the event date.
 */
export const getDisabledDate =
  (eventDate?: string): RangePickerProps['disabledDate'] =>
  (current) =>
    current && current < moment(eventDate).startOf('day');

/**
 * Generates a function to disable dates before today or after the specified expiry date.
 * @param expiryDate The expiry date.
 * @returns A function to disable dates before today or after the expiry date.
 */
export const getDisabledAlertDate =
  (expiryDate?: string): RangePickerProps['disabledDate'] =>
  (current) =>
    current < dayjs().subtract(1, 'days') ||
    current > dayjs(expiryDate).startOf('day').add(1, 'days');

/**
 * Checks if the given expiry date is in the past.
 * @param expiryDate The expiry date.
 * @returns True if the expiry date is in the past, false otherwise.
 */
export const isPastExpiryDate = (expiryDate?: string) =>
  moment(expiryDate).startOf('day') < moment().subtract(1, 'days');

/**
 * Generates a range of numbers between start and end (exclusive).
 * @param start The starting number of the range.
 * @param end The ending number of the range.
 * @returns An array containing numbers from start to end (exclusive).
 */
const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
};

/**
 * Generates a function to disable times before the specified event time.
 * @param eventDate The event date.
 * @returns A function to disable times before the event time.
 */
export const getDisabledTime =
  (eventDate?: string): RangePickerProps['disabledTime'] =>
  () => {
    const disabledHoursRange = range(0, 24).filter(
      (hour) => hour < moment(new Date(eventDate || new Date())).hours(),
    );
    const disabledMinutesRange = range(0, 59).filter(
      (min) => min < moment(new Date(eventDate || new Date())).minutes(),
    );
    const disabledSecondsRange = range(0, 59).filter(
      (secs) => secs < moment(new Date(eventDate || new Date())).seconds() + 1,
    );

    return {
      disabledHours: () => disabledHoursRange,
      disabledMinutes: (hour) =>
        hour === moment(new Date(eventDate || new Date())).hour() ? disabledMinutesRange : [],
      disabledSeconds: (hour, min) =>
        hour === moment(new Date(eventDate || new Date())).hour() &&
        min === moment(new Date(eventDate || new Date())).minute()
          ? disabledSecondsRange
          : [],
    };
  };

/**
 * Generates a function to disable times before the specified time.
 * @param date The time.
 * @returns A function to disable times before the specified time.
 */
export const getDisabledTimeDependency =
  (date?: string): RangePickerProps['disabledTime'] =>
  () => {
    const time = !moment(date)?.isValid() ? date : moment(date).format('HH:mm:ss');

    const [h, m, s] = time?.split(':') || [];
    const disabledHoursRange = range(0, 24).filter((hour) => hour < Number(h));
    const disabledMinutesRange = range(0, 59).filter((min) => min < Number(m));
    const disabledSecondsRange = range(0, 59).filter((secs) => secs < Number(s) + 1);

    return {
      disabledHours: () => disabledHoursRange,
      disabledMinutes: (hour) =>
        hour === moment(new Date(date || new Date())).hour() ? disabledMinutesRange : [],
      disabledSeconds: (hour, min) =>
        hour === moment(new Date(date || new Date())).hour() &&
        min === moment(new Date(date || new Date())).minute()
          ? disabledSecondsRange
          : [],
    };
  };

/**
 * Finds the maximum event date from an array of items.
 * @param arr The array of items.
 * @returns The maximum event date in 'YYYY-MM-DD HH:mm:ss' format.
 */
export const getMaxEventDate = <T = any>(arr?: Array<T>) => {
  const values =
    arr?.map((item) => moment(dateLocaleFormat(item?.['eventDate' as keyof T] as Date))) || [];
  return values.length ? moment.max(values).format('YYYY-MM-DD HH:mm:ss') : '';
};

/**
 * Finds the maximum local event date from an array of items.
 * @param arr The array of items.
 * @returns The maximum local event date in 'YYYY-MM-DD HH:mm:ss' format.
 */
export const getLocalMaxEventDate = <T = any>(arr?: Array<T>) => {
  const values = arr?.map((item) => moment(item?.['eventDate' as keyof T] || '')) || [];
  return values.length ? moment.max(values).format('YYYY-MM-DD HH:mm:ss') : '';
};

/**
 * Finds the maximum event time from an array of items.
 * @param arr The array of items.
 * @returns The maximum event time in 'HH:mm:ss' format.
 */
export const getMaxEventTime = <T = any>(arr?: Array<T>) => {
  const values = arr?.map((item) => moment(item?.['eventDate' as keyof T] || '')) || [];
  return values.length ? moment.max(values).format('HH:mm:ss') : '';
};

/**
 * Finds the item with the maximum event time from an array of event nodes.
 * @param arr The array of event nodes.
 * @returns The event node with the maximum event time.
 */
export const getNodeMaxEventTime = <T = EventNodeStub>(arr?: Array<T>) => {
  const values = arr?.map((item) => moment(item?.['eventTime' as keyof T] || '')) || [];
  const maxEventTime = values.length ? moment.max(values).format('YYYY-MM-DD HH:mm:ss') : '';
  return arr?.find(
    (item) =>
      moment(item?.['eventTime' as keyof T] || '').format('YYYY-MM-DD HH:mm:ss') === maxEventTime,
  );
};
/**
 * Calculates the life cycle time of a node based on its events.
 * @param arr The array of map nodes.
 * @returns The life cycle time of the node.
 */
export const getNodeLifeCycleTime = <T = MapNode>(arr?: Array<MapNode>) => {
  /* return item with max event time */
  // combine events in single array
  // @ts-ignore
  const events = arr?.reduce((acc, curr) => [...acc, ...(curr?.events || [])], []);

  const values = // @ts-ignore
    events?.map((item) => moment(dateLocaleFormat(item?.['eventTime' as keyof T] as Date))) || [];
  const maxEventTime = values.length ? moment.max(values).format('YYYY-MM-DD HH:mm:ss') : '';
  const minEventTime = values.length ? moment.min(values).format('YYYY-MM-DD HH:mm:ss') : '';

  // const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const lifeCycleTime = moment(maxEventTime).diff(moment(minEventTime), 'seconds');

  if (lifeCycleTime > 31556926) {
    return `${Math.floor(lifeCycleTime / 31556926)} ${getProductsT(
      pluralize('year', Math.floor(lifeCycleTime / 31556926)),
    )}`;
  }
  if (lifeCycleTime > 2629743) {
    return `${Math.floor(lifeCycleTime / 2629743)} ${getProductsT(
      pluralize('month', Math.floor(lifeCycleTime / 2629743)),
    )}`;
  }
  if (lifeCycleTime > 604800) {
    return `${Math.floor(lifeCycleTime / 604800)} ${getProductsT(
      pluralize('week', Math.floor(lifeCycleTime / 604800)),
    )}`;
  }
  if (lifeCycleTime > 86400) {
    return `${Math.floor(lifeCycleTime / 86400)} ${getProductsT(
      pluralize('day', Math.floor(lifeCycleTime / 86400)),
    )}`;
  }
  if (lifeCycleTime > 3600) {
    return `${Math.floor(lifeCycleTime / 3600)} ${getProductsT(
      pluralize('hour', Math.floor(lifeCycleTime / 3600)),
    )}`;
  }
  if (lifeCycleTime > 60) {
    return `${Math.floor(lifeCycleTime / 60)} ${getProductsT(
      pluralize('minute', Math.floor(lifeCycleTime / 60)),
    )}`;
  }

  return lifeCycleTime
    ? `${lifeCycleTime} ${getProductsT(pluralize('second', lifeCycleTime))}`
    : '-';
};
/**
 * Checks if at least one product with a lot exists in the provided array.
 * Throws an error if no products exist or if any product does not have a lot.
 * @param products The array of batch ship product items.
 */
export const checkLotExists = (products?: Array<BatchShipProductItem>) => {
  if (!products?.length) {
    throw new Error('Please add at least one product');
  }
  const lotNotExists = products?.filter((item) => !item?.primaryId);
  if (lotNotExists?.length) {
    throw new Error('Please select an item with your product/s in the table below');
  }
};
/**
 * Checks if the parameters have changed.
 * @param prevParams The previous parameters.
 * @param currentParams The current parameters.
 * @returns True if the parameters have changed, false otherwise.
 */
export const hasParamsChanged = (prevParams: any, currentParams: any) => {
  const prevParamsKeys = Object.keys(prevParams);
  const currentParamsKeys = Object.keys(currentParams);
  if (prevParamsKeys.length !== currentParamsKeys.length) {
    return true;
  }
  return prevParamsKeys.some((key) => prevParams[key] !== currentParams[key]);
};
/**
 * Updates form fields based on instance properties.
 * @param form The form instance.
 * @param rowConfig The row configuration.
 * @param option The option data.
 * @param recordProps Additional record properties.
 */
export const onChangeFieldsPropInstance = (
  form: FormInstance<any>,
  rowConfig: RowConfig,
  option: any,
  recordProps?: any,
) => {
  const { recordKey } = rowConfig;
  const item: InventoryResponse = option?.itemProps;
  const instance = item?.productInstance;
  const event = item?.event;
  /* on change reset quantity field to the intance inventory */

  form?.setFieldsValue({
    [String(recordKey)]: {
      lotSerial: instance?.lotSerial || '',
      eventDate: event?.eventDate ? dateLocaleFormat(event?.eventDate) : '',
      addOn: `/ ${instance?.quantity || 0}`,
      instanceInventory: instance?.quantity || 0,
      quantity: instance?.quantity || 0,
      ...recordProps,
    },
  });
};
/**
 * Updates form fields based on product properties.
 * @param form The form instance.
 * @param rowConfig The row configuration.
 * @param option The option data.
 * @param recordProps Additional record properties.
 */
export const onChangeFieldsPropProduct = (
  form: FormInstance<any>,
  rowConfig: RowConfig,
  option: any,
  recordProps?: any,
  totalInventoryAtSameLocation?: Number,
) => {
  const { recordKey } = rowConfig;
  const item: ProductResponse = option?.itemProps;
  // const isSerial = item?.productIdentifierType === 'Serial';
  form?.setFieldsValue({
    [String(recordKey)]: {
      primaryId: undefined,
      currentInventory: totalInventoryAtSameLocation || item?.currentInventoryTotal || 0,
      unitOfMeasure: getUom({ product: item }),
      productIdentifierType: item?.productIdentifierType,
      addOn: `/ ${0}`,
      ...recordProps,
    },
  });
};
/**
 * Generates a string representing the product's current inventory and unit of measure.
 * @param product The product data.
 * @returns A string representing the product's current inventory and unit of measure.
 */
export const getProductUomAddon = (product?: ProductResponse) =>
  `/ ${product?.currentInventoryTotal || 0} ${getUom({ product })}`;
/**
 * Generates a string representing the quantity addon.
 * @param record The record data.
 * @param defaultUom The default unit of measure.
 * @returns A string representing the quantity addon.
 */
export const getQuantityAddon = (record: any, defaultUom?: string) => {
  const instanceInventory = record?.instanceInventory || 0;
  const unitOfMeasure = record?.unitOfMeasure || defaultUom || 'LBS';
  const defaultAddon = `/ ${instanceInventory}`;
  const addOn = record?.addOn || defaultAddon;
  return `${addOn} ${unitOfMeasure}`;
};

export const getShipmentQuantityAddon = (record: any, defaultUom?: string) => {
  const unitOfMeasure =
    getUom({
      product: record,
    }) ||
    record?.unitOfMeasure ||
    defaultUom ||
    'LBS';
  return `${unitOfMeasure?.toUpperCase()}`;
};
/**
 * Generates a string representing the quantity addon.
 * @param record The record data.
 * @param defaultUom The default unit of measure.
 * @returns A string representing the quantity addon.
 */
export const getQuantitySum = (items?: Array<ContainerProductInstanceQuantity>) => {
  const sum = items?.reduce((acc, item) => acc + (item?.quantity || 0), 0);
  return sum;
};

/**
 * checks if uom is same for all items
 * @param items  The array of container product instance quantities.
 * @returns True if the unit of measure is the same for all items, false otherwise.
 */
export const isSameUom = (items?: Array<ContainerProductInstanceQuantity>) => {
  const isUomSame = items?.every(
    (item) => getUom({ product: item }) === getUom({ product: items?.[0] }),
  );

  return isUomSame;
};

export const isSameProducts = (items?: Array<ContainerProductInstanceQuantity>) => {
  const isMultiProd = !!items?.find((item) => item?.productId !== items?.[0]?.productId, []);

  return !isMultiProd;
};

/**
 * Calculates the sum of quantities for container items.
 * @param record The ship product item record.
 * @returns The sum of quantities with unit of measure.
 */
export const getContainerSum = (record?: ShipProductItem | DecommissionProductItem) => {
  const items = record?.containerItems || record?.children || [];
  const isUomSame = isSameUom(items);
  const uom = isUomSame ? getUom({ product: items[0] }) : '';
  const sum = getQuantitySum(items);
  if (isUomSame) {
    return `${sum} ${uom}`;
  }
  return '-';
};

/**
 * Retrieves the current inventory with appropriate unit of measure.
 * @param record The ship product item record.
 * @returns The current inventory with unit of measure.
 */
export const getCurrentInventory = (record?: ShipProductItem) => {
  const currentInventory = record?.currentInventory || 0;
  const productIdentifierType = record?.productIdentifierType || 'Lot';
  const uom = record?.unitOfMeasure || 'LBS';
  const isSerial = String(uom)?.toLowerCase() === 'item' || productIdentifierType === 'Serial';

  if (record?.isContainer) {
    const items = record?.containerItems || record?.children || [];
    const isSameProduct = items?.every((item) => item?.productName === items[0]?.productName);
    if (isSameProduct) {
      return `${currentInventory} ${uom}`;
    }
    return '-';
  }

  if (isSerial) {
    const greaterThanOne = currentInventory > 1;
    const itemStr = greaterThanOne ? 'items' : 'item';
    return `${currentInventory} ${getProductsT(itemStr)}`;
  }

  // same applies for lot and serial
  return `${currentInventory} ${uom}`;
};
/**
 * Retrieves the value of a specified key from the record.
 * @param key The key to retrieve the value for.
 * @param records The array of records.
 * @param rowKey The key of the row.
 * @returns The value corresponding to the key in the record.
 */
export const getRecordValue = (
  key: string,
  records?: Array<RecordType>,
  rowKey?: string | string[],
) => {
  const recordId = Array.isArray(rowKey) ? rowKey?.[0] : rowKey;

  const record = records?.find((item) => item?.id === recordId);
  return record?.[key];
};
/**
 * Retrieves the value of a specified key from the record or form instance.
 * @param key The key to retrieve the value for.
 * @param records The array of records.
 * @param rowKey The key of the row.
 * @param form The form instance.
 * @returns The value corresponding to the key in the record or form instance.
 */
export const getRecordValuev2 = (
  key: string,
  records?: Array<RecordType>,
  rowKey?: string | string[],
  form?: FormInstance<any>,
) => {
  const formRecordValue = form?.getFieldValue?.(String(rowKey))?.[key];

  const recordId = Array.isArray(rowKey) ? rowKey?.[0] : rowKey;
  const record = records?.find((item) => item?.id === recordId);
  return formRecordValue || record?.[key];
};
/**
 * Checks if the product is serial based on the unit of measure and product identifier type.
 * @param records The array of records.
 * @param rowKey The key of the row.
 * @param form The form instance.
 * @returns True if the product is serial, false otherwise.
 */
export const getIsSerial = (
  records?: Array<RecordType>,
  rowKey?: string | string[],
  form?: FormInstance<any>,
) => {
  const unitOfMeasure = getRecordValuev2('unitOfMeasure', records, rowKey, form);
  const productIdentifierType = getRecordValuev2('productIdentifierType', records, rowKey, form);
  const isSerial =
    String(unitOfMeasure)?.toLowerCase() === 'item' || productIdentifierType === 'Serial';
  return isSerial;
};
/**
 * Generates field properties for lot unit of measure addon.
 * @param form The form instance.
 * @param rowConfig The row configuration.
 * @param defaultUom The default unit of measure.
 * @param fieldProps Additional field properties.
 * @param records The array of records.
 * @param isSerial Flag indicating if the product is serial.
 * @returns The field properties.
 */
export const getLotUomAddon = (
  form: FormInstance<any>,
  rowConfig: RowConfig,
  defaultUom?: string,
  fieldProps?: ProColumns['fieldProps'],
  records?: Array<RecordType>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSerial?: boolean,
) => {
  const { rowKey } = rowConfig;
  const record = form?.getFieldValue?.(String(rowKey));
  const instanceInventory =
    form?.getFieldValue?.(String(rowKey))?.instanceInventory ||
    getRecordValue('instanceInventory', records, String(rowKey)) ||
    0;
  /* note :  unitOfMeasure translation is not required since record?.unitOfMeasure should be already translated */
  const unitOfMeasure = record?.unitOfMeasure || defaultUom || 'LBS';
  const isProductSerial =
    String(unitOfMeasure)?.toLowerCase() === 'item' || record?.productIdentifierType === 'Serial';
  const defaultAddon = `/ ${instanceInventory}`;
  const addOn = record?.addOn || defaultAddon;
  return {
    addonAfter: !isProductSerial ? `${addOn} ${unitOfMeasure}` : getProductsT('item'),
    stringMode: true,
    size: 'small',
    placeholder: 'Quantity',
    disabled: isProductSerial,
    className: isProductSerial ? 'full-width' : undefined,
    ...fieldProps,
  } as ProColumns['fieldProps'];
};
/**
 * Generates field properties for fields addon.
 * @param form The form instance.
 * @param rowConfig The row configuration.
 * @param defaultUom The default unit of measure.
 * @param fieldProps Additional field properties.
 * @param isAddonRequired Flag indicating if the addon is required.
 * @param isSerial Flag indicating if the product is serial.
 * @returns The field properties.
 */
export const getFieldsPropsAddon = (
  form: FormInstance<any>,
  rowConfig: RowConfig,
  defaultUom?: string,
  fieldProps?: ProColumns['fieldProps'],
  isAddonRequired = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSerial = false,
) => {
  const { rowKey } = rowConfig;
  const unitOfMeasure = form?.getFieldValue?.(String(rowKey))?.unitOfMeasure || defaultUom || 'LBS';
  const addOn = form?.getFieldValue?.(String(rowKey))?.addOn || '';
  const isProductSerial = unitOfMeasure === 'item';
  // eslint-disable-next-line no-nested-ternary
  const addonAfter = !isProductSerial
    ? isAddonRequired
      ? `${addOn} ${unitOfMeasure}`
      : unitOfMeasure
    : 'item';

  return {
    addonAfter,
    stringMode: true,
    size: 'small',
    placeholder: 'Quantity',
    disabled: isProductSerial,
    ...fieldProps,
  } as ProColumns['fieldProps'];
};
/**
 * Generates field properties for inventory addon.
 * @param form The form instance.
 * @param rowConfig The row configuration.
 * @param defaultUom The default unit of measure.
 * @param fieldProps Additional field properties.
 * @param isSerial Flag indicating if the product is serial.
 * @returns The field properties.
 */
export const getInventoryPropsAddon = (
  form: FormInstance<any>,
  rowConfig: RowConfig,
  defaultUom?: string,
  fieldProps?: ProColumns['fieldProps'],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSerial?: boolean,
) => {
  const { rowKey } = rowConfig;
  const unitOfMeasure = form?.getFieldValue?.(String(rowKey))?.unitOfMeasure || defaultUom || 'LBS';
  const isProductSerial = unitOfMeasure === 'item';

  return {
    addonAfter: !isProductSerial ? unitOfMeasure : 'items',
    stringMode: true,
    size: 'small',
    placeholder: 'Quantity',
    ...fieldProps,
  } as ProColumns['fieldProps'];
};

/**
 * Disables selection of existing lots based on instanceId.
 * @param item The product instance quantity.
 * @param products The array of batch ship product items.
 * @returns A boolean indicating whether the lot exists.
 */
export const disableExistingLots = (
  item?: InventoryResponse,
  products?: Array<BatchShipProductItem>,
) => {
  const instance = item?.productInstance;
  const lotExists = products?.find((product) => product?.primaryId === instance?.id);
  return !!lotExists;
};

/**
 * Disables selection of existing lot locations based on instanceId, locationId, and eventTime.
 * @param item The product instance quantity.
 * @param products The array of lot items.
 * @param locationId The location ID.
 * @param eventTime The event time.
 * @returns A boolean indicating whether the lot location should be disabled.
 */
export const disableExistingLotLocations = (
  item?: InventoryResponse,
  products?: Array<LotItem>,
  locationId?: string,
  eventTime?: Moment,
) => {
  const instance = item?.productInstance;
  const event = item?.event;
  const lotExists = products?.find((product) => product?.primaryId === instance?.id);
  const isSameLocation = event?.locationId === locationId;
  // if eventTime is defined, then disable selecting lot if the eventTime is greater than the lot's eventTime
  const isEventTimeBefore = eventTime ? eventTime.isBefore(moment(event?.eventDate), 's') : false;

  return !!lotExists || !isSameLocation || isEventTimeBefore;
};

/**
 * Disables selection of existing lots based on remaining quantity.
 * @param item The product instance quantity.
 * @param products The array of batch ship product items.
 * @returns A boolean indicating whether the remaining quantity is greater than or equal to the lot quantity.
 */
export const disableExistingLotsByQuantity = (
  item?: InventoryResponse,
  products?: Array<BatchShipProductItem>,
) => {
  const instance = item?.productInstance;
  const existingLots = products?.filter((product) => product?.primaryId === instance?.id);
  const remainingQuantity =
    existingLots?.reduce((acc, curr) => acc + (curr?.quantity || 0), 0) || 0;
  return remainingQuantity >= (instance?.quantity || 0);
};

/**
 * Gets the instance ID based on lot serial or container ID.
 * @param primaryId The primary ID (lot serial).
 * @param products The array of product instance quantities.
 * @returns The instance ID.
 */
export const getInstanceIdByLot = (primaryId?: string, products?: Array<InventoryResponse>) => {
  const instance = products?.find((p) => p?.productInstance?.lotSerial === primaryId);
  return instance?.productInstance?.id || instance?.container?.containerId;
};

export const getInventoryInstanceIdByLot = (
  primaryId?: string,
  products?: Array<InventoryResponse>,
) => {
  const instance = products?.find((p) => p?.productInstance?.lotSerial === primaryId);
  return instance;
};

/**
 * Gets the product instance based on lot serial.
 * @param primaryId The primary ID (lot serial).
 * @param products The array of product instance quantities.
 * @returns The product instance.
 */
export const getInstanceByLot = (primaryId?: string, products?: Array<InventoryResponse>) => {
  const instance = products?.find((p) => p?.productInstance?.lotSerial === primaryId);
  return instance;
};

/**
 * Gets the product ID based on product name.
 * @param productName The product name.
 * @param products The array of products.
 * @param defaultProductId The default product ID.
 * @returns The product ID.
 */
export const getProductIdByName = (
  productName?: string,
  products?: Array<ProductResponse>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultProductId?: string,
) => {
  const product = products?.find((p) => p?.name?.trim() === productName?.trim());
  return product?.id;
};

/**
 * Gets the product based on product name.
 * @param productName The product name.
 * @param products The array of products.
 * @param defaultProductId The default product ID.
 * @returns The product.
 */
export const getProductByName = (
  productName?: string,
  products?: Array<ProductResponse>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultProductId?: string,
) => {
  const product = products?.find((p) => p?.name === productName);
  return product;
};
/**
 * Gets the product name based on product ID.
 * @param productId The product ID.
 * @param products The array of products.
 * @returns The product name.
 */
export const getProductNameById = (productId?: string, products?: Array<ProductResponse>) => {
  const product = products?.find((p) => p?.id === productId);
  return product?.name;
};

/**
 * Gets the product based on product ID.
 * @param productId The product ID.
 * @param products The array of products.
 * @returns The product.
 */
export const getProductById = (productId?: string, products?: Array<ProductResponse>) => {
  const product = products?.find((p) => p?.id === productId);
  return product;
};

/**
 * Gets the lot name based on instance ID.
 * @param primaryId The instance ID.
 * @param lots The array of product instance quantities.
 * @returns The lot name.
 */
export const getLotNameById = (primaryId?: string, lots?: Array<InventoryResponse>) => {
  const lot = lots?.find((p) => p?.productInstance?.id === primaryId);
  return lot?.productInstance?.lotSerial;
};

/**
 * Gets the location ID based on location name.
 * @param name The location name.
 * @param locations The array of locations.
 * @returns The location ID.
 */
export const getLocationIdByName: GetLocationNameByIdFn = (name, locations) => {
  const location = locations?.find((loc) => loc?.name === name);
  return location?.id || undefined;
};

/**
 * Gets the company ID based on company name.
 * @param name The company name.
 * @param partners The array of trade partners.
 * @returns The company ID.
 */
export const getCompanyIdByName: GetTradePartnerIdByIdFn = (name, partners) => {
  const partner = partners?.find((p) => p?.name === name);
  return partner?.id || undefined;
};

/**
 * Gets the trade partner ID based on trade partner name.
 * @param name The trade partner name.
 * @param tradepartners The array of trade partners.
 * @returns The trade partner ID.
 */
export const getTradePartnerByName: GetTradePartnerIdByIdFn = (name, tradepartners) => {
  const location = tradepartners?.find((loc) => loc?.name === name);
  return location?.id;
};

/**
 * Gets the trade partner based on trade partner name.
 * @param name The trade partner name.
 * @param tradepartners The array of trade partners.
 * @returns The trade partner.
 */
export const getTradePartnerOByName: GetTradePartnerOByIdFn = (name, tradepartners) => {
  const partner = tradepartners?.find((loc) => loc?.name === name);
  return partner;
};

/**
 * Gets the trade partner based on trade partner name.
 * @param name The trade partner name.
 * @param tradepartners The array of trade partners.
 * @returns The trade partner.
 */
export const getTradePartnerOById: GetTradePartnerOByIdFn = (id, tradepartners) => {
  const partner = tradepartners?.find((t) => t?.id === id);
  return partner;
};

/**
 * Gets the location name based on location ID.
 * @param locationId The location ID.
 * @param locations The array of locations.
 * @returns The location name.
 */
export const getLocationNameById: GetLocationNameByIdFn = (locationId, locations) => {
  const location = locations?.find((loc) => loc?.id === locationId);
  return location?.name;
};

/**
 * Gets the location based on location ID.
 * @param locationId The location ID.
 * @param locations The array of locations.
 * @returns The location.
 */
export const getLocationById: GetLocationByIdFn = (locationId, locations) => {
  const location = locations?.find((loc) => loc?.id === locationId);
  return location;
};

/**
 * Triggers an information message for same-day events.
 * @param isMultipleDates Indicates whether multiple dates are involved.
 */
const triggerSameDayMessage = (isMultipleDates?: boolean) => {
  message.destroy();
  const key = uuidv4();
  message.info(
    {
      content: !isMultipleDates
        ? getProductsT('same_day_tooltip')
        : getProductsT('same_day_multiple_tooltip'),
      key,
      duration: 0,
      className: styles.antmessage,
    },
    0,
  );
};

/**
 * Clears the same-day message.
 */
const clearSameDayMessage = () => {
  message.destroy();
};

/**
 * Object mapping certification keys to their corresponding translated keys.
 */
export const certificationMappings: {
  [key: string]: string;
} = {
  type: 'cert_type',
  standard: 'cert_standard',
  agency: 'cert_agency',
  value: 'cert_value',
  identification: 'cert_id',
  location: 'cert_location',
  eventTemplateId: 'cert_eventtemplateid',
  id: 'cert_certificateid',
};

/**
 * Object mapping certification keys to their corresponding translated keys.
 */
export const vesselCatchMappings: {
  [key: string]: string;
} = {
  vesselName: 'vessel_name',
  vesselFlagState: 'vessel_flag_state',
  imoNumber: 'imo_number',
  vesselPublicRegistry: 'vessel_public_registry',
  gpsAvailability: 'gps_availability',
  satelliteTrackingAuthority: 'satellite_tracking_authority',
  economicZone: 'economic_zone',
  fisheryImprovementProject: 'fishery_improvement_project',
  rfmoArea: 'rfmo_area',
  subnationalPermitArea: 'subnational_permit_area',
  catchArea: 'catch_area',
  fishingGearTypeCode: 'fishing_gear_type_code',
  vesselTripDate: 'vessel_trip_date',
  vesselTripDateStart: 'vessel_trip_date_start',
  vesselTripDateEnd: 'vessel_trip_date_end',
};

/**
 * Object defining CSV template fields for vessel catch data.
 */
export const vesselCatchFields: CSVTemplate = {
  economicZone: { dataIndex: 'economicZone', valueType: 'text' },
  fisheryImprovementProject: { dataIndex: 'fisheryImprovementProject', valueType: 'text' },
  rfmoArea: { dataIndex: 'rfmoArea', valueType: 'text' },
  subnationalPermitArea: { dataIndex: 'subnationalPermitArea', valueType: 'text' },
  catchArea: { dataIndex: 'catchArea', valueType: 'text' },
  fishingGearTypeCode: { dataIndex: 'fishingGearTypeCode', valueType: 'text' },
  vesselTripDateStart: { dataIndex: 'vesselTripDateStart', valueType: 'date' },
  vesselTripDateEnd: { dataIndex: 'vesselTripDateEnd', valueType: 'date' },
};

/**
 * Object defining CSV template fields for certification data.
 */
export const certificationFields: CSVTemplate = {
  certificationType: {
    dataIndex: 'certificationType',
    required: true,
    valueType: 'text',
  },
  certificationStandard: {
    dataIndex: 'certificationStandard',
    required: true,
    valueType: 'text',
  },
  certificationAgency: {
    dataIndex: 'certificationAgency',
    required: true,
    valueType: 'text',
  },
  certificationValue: {
    dataIndex: 'certificationValue',
    required: true,
    valueType: 'text',
  },
  certificationIdentification: { dataIndex: 'certificationIdentification', valueType: 'text' },
};

/**
 * Displays a modal popup for future date notification.
 */
const showFutureDayPopup = () => {
  const t = getProductsT;
  Modal.info({
    title: t?.('future_date_title'),
    content: t?.('future_date_message'),
    okText: t?.('done'),
    cancelButtonProps: {
      type: 'primary',
      shape: 'round',
      ghost: true,
      hidden: true,
    },
    okButtonProps: {
      type: 'primary',
      shape: 'round',
    },
    centered: true,
    okCancel: true,
  });
};

/**
 * Handles the change event for a date field in a form.
 * Resets time fields and triggers notifications based on the date value.
 *
 * @param {FormInstance<any>} form - The form instance.
 * @param {string} value - The new value of the date field.
 * @param {string | undefined} date - The current date value from the form (optional).
 */
export const onChangeFieldDate = (form: FormInstance<any>, value: string, date?: string) => {
  if (value) {
    const isSameDay = moment(value).isSame(moment(new Date(String(date))), 'day');
    const isFutureDay = moment(new Date()).isBefore(moment(value), 'day');

    if (isSameDay) {
      form.setFieldsValue({
        receiveTime: undefined,
        time: undefined,
      });
      triggerSameDayMessage();
    } else {
      form.setFieldsValue({
        receiveTime: '12:00:00',
        time: '12:00:00',
      });
      clearSameDayMessage();
    }
    if (isFutureDay) {
      showFutureDayPopup();
    }
  }
};

/**
 * Handles the change event for a ship field date in a form.
 * Resets time fields and triggers notifications based on the date value.
 *
 * @param {FormInstance<any>} form - The form instance.
 * @param {string} value - The new value of the date field.
 * @param {string | undefined} date - The current date value from the form (optional).
 */
export const onChangeShipFieldDate = (form: FormInstance<any>, value: string, date?: string) => {
  if (value) {
    const isSameDay = moment(value).isSame(moment(new Date(String(date))), 'day');
    const isFutureDay = moment(new Date()).isBefore(moment(value), 'day');

    if (isSameDay) {
      form.setFieldsValue({
        receiveTime: undefined,
        aggregateTime: undefined,
      });
      triggerSameDayMessage();
    } else {
      form.setFieldsValue({
        receiveTime: '12:00:00',
        aggregateTime: '12:00:00',
      });
      clearSameDayMessage();
    }
    if (isFutureDay) {
      showFutureDayPopup();
    }
  }
};

/**
 * Handles the change event for a batch ship field date in a form.
 * Resets time fields and triggers notifications based on the date value.
 *
 * @param {FormInstance<any>} form - The form instance.
 * @param {string | undefined} rowKey - The key of the row in the form (optional).
 * @param {string | undefined} value - The new value of the date field (optional).
 */
export const onChangeBatchShipFieldDate = (
  form: FormInstance<any>,
  rowKey?: string,
  value?: string,
) => {
  const date = form?.getFieldValue?.(String(rowKey))?.eventDate;
  if (value) {
    const isSameDay = moment(value).isSame(moment(new Date(String(date))), 'day');
    if (isSameDay) {
      form.setFieldsValue({
        [String(rowKey)]: {
          time: undefined,
        },
      });
      triggerSameDayMessage(true);
    } else {
      form.setFieldsValue({
        [String(rowKey)]: {
          time: '12:00:00',
        },
      });
      clearSameDayMessage();
    }
  }
};

/**
 * Returns the product name associated with a container, or "Multiple Products" if the container contains multiple products.
 * @param record Optional parameter representing the EventNode record.
 * @returns A string representing the product name or "Multiple Products".
 */
export const getContainerProductName = (record?: EventResponse) => {
  if (!record?.containers?.[0]?.containerIdentifier) {
    return record?.productInstances?.[0].productName || '-';
  }
  if (isSameKey('productName', record?.containers?.[0].productInstances)) {
    return record?.containers?.[0]?.productInstances?.[0]?.productName || '-';
  }
  // container of multiple products
  return 'Multiple Products';
};

/**
 * Formats a time string into "HH:mm:ss" format using moment.js.
 * @param time Optional parameter representing the time string to format.
 * @returns A string representing the formatted time or the original time string if it's invalid.
 */
export const getTimeFormat = (time?: string) =>
  !moment(time)?.isValid() ? time : moment(time).format('HH:mm:ss');

/**
 * Orders an array of MapNodes based on their event times, with placeholders (if any) at the beginning.
 * @param nodes Optional parameter representing an array of MapNode records.
 * @returns An array of MapNodes sorted by event time.
 */
export const orderMapNodes = (nodes?: Array<MapNode>) => {
  if (!nodes) return [];
  const receivePlaceHolder = nodes.find((node) => node?.isReceivePlaceholder);
  const sortedNodes = nodes
    .filter((node) => !node?.isReceivePlaceholder)
    .sort((a, b) =>
      moment.max(a?.events?.map((event) => moment(event?.eventTime || new Date())) || []) >
      moment.max(b?.events?.map((event) => moment(event?.eventTime || new Date())) || [])
        ? -1
        : 1,
    );
  return receivePlaceHolder ? [receivePlaceHolder, ...sortedNodes] : sortedNodes;
};

/**
 * Orders nodes based on their connections defined by edges in a graph structure.
 * @param data Optional parameter representing the GetEventHistoryMapResponse data.
 * @returns An array of MapNodes ordered by their relationships defined in the edges.
 */
export const orderNodesByEdges = (data?: MapModel) => {
  const edges = data?.edges || [];
  const nodes = data?.nodes || [];
  // return ordered nodes based on edges array containing source and target
  const orderedNodes =
    edges?.length > 0
      ? edges.reduce((acc: Array<MapNode>, edge: MapDiagramEdge) => {
          const sourceNode = nodes.find((node) => node?.id === edge?.source);
          const targetNode = nodes.find((node) => node?.id === edge?.target);
          if (sourceNode) {
            acc.push(sourceNode);
          }
          if (targetNode) {
            acc.push(targetNode);
          }
          return acc;
        }, [])
      : nodes;
  return orderedNodes;
};

/**
 * Generates template fields for a given event template.
 * @param template Optional parameter representing the EventTemplate.
 * @returns An array of ProFormColumnsType representing the template fields.
 */
export const getTemplateFields = (
  template?: EventTemplatesResponse,
): ProField[] | ((values: any) => ProField[]) | undefined =>
  template?.templateAttributes?.map((f, idx) => {
    const attribute = f?.attribute;
    const fieldProperties = f?.attribute?.fieldProperties;
    const field = fieldProperties?.fields;
    const values = fieldProperties?.values;
    const initialValue = f?.defaultValue || undefined;
    const title = attribute?.name;

    const defaultProField: ProField = {
      title,
      dataIndex: ['customProperties', field?.propertyName || ''],
      valueType: fieldTypes[field?.fieldType || 'Text'].valueType,
      initialValue,
      fieldProps: {
        ...((fieldTypes?.[field?.fieldType || 'Text']?.fieldProps || {}) as ProField),
        size: 'middle',
        placeholder: title,
        options: values?.valueOptions,
      },
      formItemProps: (form: FormInstance<EventDataItem>) => ({
        rules: [
          {
            required: field?.required || false,
            message: getTParam('products.field_required', {
              name: title,
            }),
            validator: () => {
              const customProperties = form?.getFieldValue('customProperties');
              const value = isObject(customProperties)
                ? customProperties?.[field?.propertyName || '']
                : customProperties?.[idx];
              if (!field?.required) {
                return Promise.resolve();
              }
              if (value?.trim() && field?.required) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error(
                  getTParam('products.field_required', {
                    name: title,
                  }),
                ),
              );
            },
          },
        ],
      }),
      colProps: colProps6Cols,
    };
    switch (field?.propertyName) {
      case 'vesselTripDateStart':
        return {
          ...defaultProField,
          // @ts-ignore
          fieldProps: (form) => ({
            ...(defaultProField?.fieldProps || {}),
            onSelect: (value: string) => {
              const customProperties = form?.getFieldValue('customProperties') || {};
              form?.setFieldsValue({
                customProperties: {
                  ...customProperties,
                  vesselTripDateStart: value,
                  vesselTripDateEnd: undefined,
                },
              });
            },
          }),
        };
      case 'vesselTripDateEnd':
        return {
          valueType: 'dependency',
          fieldProps: {
            name: ['customProperties', 'vesselTripDateStart'],
          },
          columns: ({ customProperties }) => [
            {
              ...defaultProField,
              // @ts-ignore
              fieldProps: {
                ...(defaultProField?.fieldProps || {}),
                disabledDate: getDisabledDate(customProperties?.vesselTripDateStart),
              },
            },
          ],
        };

      default:
        return defaultProField;
    }
  }) || [];

/**
 * Represents a list of business steps along with their URNs.
 */
export const rawBizStep = [
  {
    name: 'Accepting (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:accepting',
  },
  {
    name: 'Arriving (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:arriving',
  },
  {
    name: 'Assembling (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:assembling',
  },
  {
    name: 'Collecting (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:collecting',
  },
  {
    name: 'Commissioning (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:commissioning',
  },
  {
    name: 'Consigning (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:consigning',
  },
  {
    name: 'Creating Class Instance (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:creating_class_instance',
  },
  {
    name: 'Cycle Counting (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:cycle_counting',
  },
  {
    name: 'Decomissioning (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:decomissioning',
  },
  {
    name: 'Departing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:departing',
  },
  {
    name: 'Destroying (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:destroying',
  },
  {
    name: 'Disassembling (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:disassembling',
  },
  {
    name: 'Dispensing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:dispensing',
  },
  {
    name: 'Encoding (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:encoding',
  },
  {
    name: 'Entering/Exiting (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:entering/exiting',
  },
  {
    name: 'Holding (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:holding',
  },
  {
    name: 'Inspecting (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:inspecting',
  },
  {
    name: 'Installing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:installing',
  },
  {
    name: 'Killing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:killing',
  },
  {
    name: 'Loading (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:loading',
  },
  {
    name: 'Other (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:other',
  },
  {
    name: 'Packing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:packing',
  },
  {
    name: 'Picking (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:picking',
  },
  {
    name: 'Receiving (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:receiving',
  },
  {
    name: 'Removing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:removing',
  },
  {
    name: 'Repackaging (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:repackaging',
  },
  {
    name: 'Repairing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:repairing',
  },
  {
    name: 'Replacing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:replacing',
  },
  {
    name: 'Reserving (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:reserving',
  },
  {
    name: 'Retail Selling (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:retail_selling',
  },
  {
    name: 'Shipping (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:shipping',
  },
  {
    name: 'Staging Outbound (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:staging_outbound',
  },
  {
    name: 'Stock Taking (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:stock_taking',
  },
  {
    name: 'Storing (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:storing',
  },
  {
    name: 'Transporting (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:transporting',
  },
  {
    name: 'Unloading (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:unloading',
  },
  {
    name: 'Unpacking (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:unpacking',
  },
  {
    name: 'Void Shipping (CBV)',
    urn: 'urn:epcglobal:cbv:bizstep:void_shipping',
  },
  {
    name: 'Fishing Event (GDST)',
    urn: 'urn:gdst:bizstep:fishingevent',
  },
  {
    name: 'Farm Harvest (GDST)',
    urn: 'urn:gdst:bizstep:farmharvest',
  },
  {
    name: 'Transshipment (GDST)',
    urn: 'urn:gdst:bizstep:transshipment',
  },
  {
    name: 'Landing (GDST)',
    urn: 'urn:gdst:bizstep:landing',
  },
  {
    name: 'Feeding (GDST)',
    urn: 'urn:gdst:bizstep:feeding',
  },
  {
    name: 'Hatching (GDST)',
    urn: 'urn:gdst:bizstep:hatching',
  },
  {
    name: 'Temperature (GDST)',
    urn: 'urn:gdst:bizstep:temperature',
  },
  {
    name: 'Packaging (GDST)',
    urn: 'urn:gdst:bizstep:packaging',
  },
  {
    name: 'Commingling (GDST)',
    urn: 'urn:gdst:bizstep:commingling',
  },
  {
    name: 'Sampling (GDST)',
    urn: 'urn:gdst:bizstep:sampling',
  },
  {
    name: `Freezing (GDST)`,
    urn: 'urn:gdst:bizstep:freezing',
  },
];

/**
 * Generates core business steps based on the raw business step data.
 * @returns An array of objects representing core business steps.
 */
export const coreBizStep = () =>
  rawBizStep.map((el) => {
    const urns = el.urn.split(':');
    const step = urns[urns.length - 1];
    const isCBV = urns?.[2] === 'cbv';
    const type = isCBV ? 'cbv' : 'gdst';
    const name = `${getProductsT?.(step) || ''} (${getProductsT?.(type) || ''})`;
    return {
      name,
      urn: el.urn,
    };
  });

/**
 * Represents a list of Dispositions along with their URNs.
 */
export const rawDispositions = [
  {
    name: 'Active (CBV)',
    urn: 'urn:epcglobal:cbv:disp:active',
  },
  {
    name: 'Container Closed (CBV)',
    urn: 'urn:epcglobal:cbv:disp:container_closed',
  },
  {
    name: 'Damaged (CBV)',
    urn: 'urn:epcglobal:cbv:disp:damaged',
  },
  {
    name: 'Destroyed (CBV)',
    urn: 'urn:epcglobal:cbv:disp:destroyed',
  },
  {
    name: 'Dispensed (CBV)',
    urn: 'urn:epcglobal:cbv:disp:dispensed',
  },
  {
    name: 'Disposed (CBV)',
    urn: 'urn:epcglobal:cbv:disp:disposed',
  },
  {
    name: 'Encoded (CBV)',
    urn: 'urn:epcglobal:cbv:disp:encoded',
  },
  {
    name: 'Expired (CBV)',
    urn: 'urn:epcglobal:cbv:disp:expired',
  },
  {
    name: 'In Progress (CBV)',
    urn: 'urn:epcglobal:cbv:disp:in_progress',
  },
  {
    name: 'In Transit (CBV)',
    urn: 'urn:epcglobal:cbv:disp:in_transit',
  },
  {
    name: 'Inactive (CBV)',
    urn: 'urn:epcglobal:cbv:disp:inactive',
  },
  {
    name: 'No Pedigree Match (CBV)',
    urn: 'urn:epcglobal:cbv:disp:no_pedigree_match',
  },
  {
    name: 'Non Sellable Other (CBV)',
    urn: 'urn:epcglobal:cbv:disp:non_sellable_other',
  },
  {
    name: 'Partially Dispensed (CBV)',
    urn: 'urn:epcglobal:cbv:disp:partially_dispensed',
  },
  {
    name: 'Recalled (CBV)',
    urn: 'urn:epcglobal:cbv:disp:recalled',
  },
  {
    name: 'Reserved (CBV)',
    urn: 'urn:epcglobal:cbv:disp:reserved',
  },
  {
    name: 'Retail Sold (CBV)',
    urn: 'urn:epcglobal:cbv:disp:retail_sold',
  },
  {
    name: 'Returned (CBV)',
    urn: 'urn:epcglobal:cbv:disp:returned',
  },
  {
    name: 'Sellable Accessible (CBV)',
    urn: 'urn:epcglobal:cbv:disp:sellable_accessible',
  },
  {
    name: 'Sellable Not Accessible (CBV)',
    urn: 'urn:epcglobal:cbv:disp:sellable_not_accessible',
  },
  {
    name: 'Stolen (CBV)',
    urn: 'urn:epcglobal:cbv:disp:stolen',
  },
  {
    name: 'Unknown (CBV)',
    urn: 'urn:epcglobal:cbv:disp:unknown',
  },
];

/**
 * Generates core Dispositions based on the raw Dispositions step data.
 * @returns An array of objects representing core Dispositions.
 */
export const coreDispositions = () =>
  rawDispositions.map((el) => {
    const urns = el.urn.split(':');
    const step = urns[urns.length - 1];
    const isCBV = urns?.[2] === 'cbv';
    const type = isCBV ? 'cbv' : 'gdst';
    const name = `${getProductsT?.(step)} (${getProductsT?.(type)})`;
    return {
      name,
      urn: el.urn,
    };
  });

/**
 * Retrieves the translated name of a business step based on its text representation.
 * @param text The text representation of the business step.
 * @returns The translated name of the business step.
 */
export const getBizStepT = (text?: string) => {
  const urn = rawBizStep.find((b) => b.name === text)?.urn || '';
  const urns = urn.split(':');
  const step = urns[urns.length - 1];
  const isCBV = urns?.[2] === 'cbv';
  const type = isCBV ? 'cbv' : 'gdst';
  const name = `${getProductsT?.(step) || ''} (${getProductsT?.(type) || ''})`;

  return name;
};

/**
 * Retrieves the translated name of a disposition based on its text representation.
 * @param text The text representation of the disposition.
 * @returns The translated name of the disposition.
 */
export const getDispositionT = (text?: string) => {
  const urn = rawDispositions.find((b) => b.name === text)?.urn || '';
  const urns = urn.split(':');
  const step = urns[urns.length - 1];
  const isCBV = urns?.[2] === 'cbv';
  const type = isCBV ? 'cbv' : 'gdst';
  const name = `${getProductsT?.(step) || ''} (${getProductsT?.(type) || ''})`;

  return name;
};

/**
 * Handle exceptions in text translations.
 * @param text The text to be translated.
 * @returns The translated text with exceptions handled.
 */
export const getTExpections = (text: string) => {
  switch (text) {
    case 'OriginaÃ§Ã£o (CBV)':
      return 'Originação (CBV)';
    case 'NÃºmero do lote':
      return 'Número do lote';
    case 'NÃºmero de sÃ©rie':
      return 'Número de série';
    case 'ID LogÃ­stico/SSCC':
      return 'ID Logístico/SSCC';
    case 'Fuso horÃ¡rio':
      return 'Fuso horário';
    case 'LocalizaÃ§Ã£o':
      return 'Localização';
    case 'Enviar para o endereÃ§o':
      return 'Enviar para o endereço';
    case 'Em trÃ¢nsito (CBV)':
      return 'Em trânsito (CBV)';

    default:
      return text;
  }
};

/**
 * Generates an identifier string based on the given record.
 * @param record The record for which to generate the identifier.
 * @param defaultIdentifier The default identifier to use if no specific one is found.
 * @returns The generated identifier string.
 */
export const getIdentifier = (record: AggregateProductItem, defaultIdentifier?: string) => {
  const identifier = record?.isContainer
    ? getProductsT('SSCC')
    : getProductsT(record?.productIdentifierType || defaultIdentifier);

  return `${identifier}: ${record?.lotSerial || ''}`;
};

/**
 * Retrieves the business step URN based on an identifier.
 * @param identifier The identifier string.
 * @returns The URN of the business step.
 */
export const getBizStepByIdentifier = (rawIdentifier: string) => {
  const identifier = rawIdentifier?.trim();
  /* exceptions */
  const identifierT = getTExpections(identifier);
  // all text are in same language and unicode
  const bizStep = coreBizStep()?.find((b) => b.name === identifierT || b.urn === identifierT);

  return bizStep?.urn;
};
/**
 * Retrieves the business step URN based on an identifier.
 * @param urn The urn string.
 * @returns The id of the business step.
 */
export const getBizStepIdByURN = (urn?: string) => {
  const cachedData = queryClient.getQueryData<ListBizStepResponse>(['bizSteps', undefined]);
  const record = cachedData?.results?.find((b) => b.urn === urn);
  return record?.id || '';
};
/**
 * Retrieves the disposition step URN based on an identifier.
 * @param urn The urn string.
 * @returns The id of the disposition.
 */
export const getDispositionIdByURN = (urn?: string) => {
  const cachedData = queryClient.getQueryData<ListDispositionResponse>(['dispositions', undefined]);
  const record = cachedData?.results?.find((b) => b.urn === urn);
  return record?.id || '';
};

/**
 * Retrieves the business step name based on an identifier.
 * @param identifier The identifier string.
 * @returns The name of the business step.
 */
export const getBizStepNameByIdentifier = (identifier: string) => {
  /* exceptions */
  const identifierT = getTExpections(identifier);
  // all text are in same language and unicode
  const bizStep = coreBizStep()?.find((b) => b.name === identifierT || b.urn === identifierT);

  return bizStep?.name;
};

/**
 * Retrieves the disposition URN based on an identifier.
 * @param identifier The identifier string.
 * @returns The URN of the disposition.
 */
export const getDispositionByIdentifier = (rawIdentifier: string) => {
  const identifier = rawIdentifier?.trim();
  const identifierT = getTExpections(identifier);
  const d = coreDispositions()?.find((b) => b.name === identifierT || b.urn === identifierT);
  return d?.urn;
};

/**
 * Retrieves the disposition name based on an identifier.
 * @param identifier The identifier string.
 * @returns The name of the disposition.
 */
export const getDispositionNameByIdentifier = (identifier: string) => {
  const identifierT = getTExpections(identifier);
  const d = coreDispositions()?.find((b) => b.name === identifierT || b.urn === identifierT);
  return d?.name;
};

/**
 * Retrieves custom properties based on a template and custom property values.
 * @param customProperties The custom property values.
 * @param template The event template.
 * @returns An array of event custom properties.
 */
export const getCustomProperties: GetCustomPropertiesFn = (customProperties, template) =>
  template?.templateAttributes?.map((f, idx) => {
    const attribute = f?.attribute;
    const fieldProperties = f?.attribute?.fieldProperties;
    const field = fieldProperties?.fields;

    const value = isObject(customProperties)
      ? customProperties?.[field?.propertyName || '']
      : customProperties?.[idx];

    return {
      name: attribute?.name || field?.propertyName,
      value: value ? String(value) : '',
      namespace: fieldProperties?.namespace?.name || 'wc',
      propertyLocation: fieldProperties?.location,
    };
  }) || [];

/**
 * Retrieves custom properties for CSV export based on form data and a template.
 * @param formData The form data.
 * @param template The event template.
 * @returns An array of CSV custom properties.
 */
export const getCSVCustomProperties: GetCSVCustomPropertiesFn = (formData, template) =>
  template?.templateAttributes?.map((f) => {
    const attribute = f?.attribute;
    const fieldProperties = f?.attribute?.fieldProperties;
    const field = fieldProperties?.fields;
    const value = formData?.[String(field?.propertyName || attribute?.name || '')] || '';

    return {
      name: attribute?.name || field?.propertyName,
      value: value ? String(value) : '',
      namespace: fieldProperties?.namespace?.name || 'wc',
      propertyLocation: fieldProperties?.location,
    };
  }) || [];

/**
 *
 * @param certProperties  The certification properties.
 * @returns  An array of certification information objects.
 */
export const mapCertProperties: MapCertPropertiesFn = (certProperties) => {
  const certifications = certProperties.map<EventCertificationContract>((cert) => ({
    type: cert.certificationType,
    standard: cert.certificationStandard,
    agency: cert.certificationAgency,
    value: cert.certificationValue,
    identification: cert.certificationIdentification,
  }));

  return certifications;
};
/**
 * Retrieves certification properties based on form data and a template.
 * @param formData The form data.
 * @param template The event template.
 * @returns An array of certification information objects.
 */
export const getCertProperties: GetCertPropertiesFn = (formData, template) => {
  const certProperties =
    template?.templateCertifications?.reduce((acc, item, idx) => {
      const certObj = Object.keys(item)
        ?.filter((key) => key !== 'id' && key !== 'eventTemplateId', [])
        ?.reduce((init, key) => {
          const value = formData?.[`${key} ${idx + 1}`] || '-';
          return {
            ...init,
            [key]: value,
          };
        }, {} as TemplateCertificationResponse);
      acc.push(certObj);
      return acc;
    }, [] as Array<TemplateCertificationResponse>) || [];
  /* map CertificationInfo to EventCertificationContract */
  // const certifications = mapCertProperties(certProperties);
  return certProperties;
};

/*   event details section */
/**
 * Maps event nodes to their corresponding attributes for input products.
 * @param el The event node.
 * @param idx The index of the event node.
 * @returns An object containing mapped attributes for input products.
 */
export const mapIProducts: MapEventNodes = (el, idx) => {
  const idxString = `(${idx + 1})`;
  const attributeProduct = `${getT('products.event_details.input_product_title')} ${idxString}`;
  const attributeLot = `${getT('products.event_details.input_title')} ${getProductsT(
    el?.productIdentifierType || 'Lot',
  )} ${idxString}`;
  const attributeQuantity = `${getT('products.event_details.input_quantity_title')} ${idxString}`;
  const productName = el?.productName || '';
  const lotSerial = el?.lotSerial || '';
  const quantity = `${el?.quantity || 0} ${getUomEvent({
    event: el,
  })}`;
  return {
    attributeProduct,
    attributeLot,
    attributeQuantity,
    productName,
    lotSerial,
    quantity,
  };
};

/**
 * Maps event nodes to their corresponding attributes for output products.
 * @param el The event node.
 * @param idx The index of the event node.
 * @returns An object containing mapped attributes for output products.
 */
export const mapOProducts: MapEventNodes = (el, idx) => {
  const idxString = `(${idx + 1})`;
  const attributeProduct = `${getT('products.event_details.output_product_title')} ${idxString}`;
  const attributeLot = `${getT('products.event_details.output_title')} ${getProductsT(
    el?.productIdentifierType || 'Lot',
  )} ${idxString}`;
  const attributeQuantity = `${getT('products.event_details.output_quantity_title')} ${idxString}`;
  const productName = el?.productName || '';
  const lotSerial = el?.lotSerial || '';
  const quantity = `${el?.quantity || 0} ${getUomEvent({
    event: el,
  })}`;
  return {
    attributeProduct,
    attributeLot,
    attributeQuantity,
    productName,
    lotSerial,
    quantity,
  };
};

/**
 * Maps event nodes to their corresponding attributes for products.
 * @param isContainer Indicates whether the product is a container.
 * @returns An object containing mapped attributes for products.
 */
export const mapProducts: MapEventNodesFn = (isContainer) => (el, idx, arr) => {
  const arrIdxString = arr?.length > 1 ? `(${idx + 1})` : '';
  const idxString = isContainer ? `(${idx + 1})` : arrIdxString;
  const attributeProduct = `${getT('products.event_details.product_title')} ${idxString}`;
  const attributeLot = `${getProductsT(el?.productIdentifierType || 'Lot')} ${idxString}`;
  const attributeQuantity = `${getT('products.event_details.quantity_title')} ${idxString}`;
  const productName = el?.productName || '';
  const lotSerial = el?.lotSerial || '';
  const quantity = `${el?.quantity || 0} ${getUomEvent({
    event: el,
  })}`;

  return {
    attributeProduct,
    attributeLot,
    attributeQuantity,
    productName,
    lotSerial,
    quantity,
  };
};

/**
 * Generates properties for quantities checkbox based on a product inventory item.
 * @param record The product inventory item.
 * @returns An object containing properties for quantities checkbox.
 */
export const quantitiesCheckbox = (record: ProductInventoryItem) => ({
  disabled: !record?.id,
});

/**
 * Generates a function to check same date.
 * @param currentDate The time.
 * @param selectedDate The time.
 * @returns A function to check same date.
 */
export const checkSameDate = (currentDate?: string, selectedDate?: string) =>
  moment(currentDate).isValid() &&
  moment(selectedDate).isValid() &&
  moment(new Date(String(selectedDate))).isSame(currentDate, 'day');
