import { BetaSchemaForm } from '@ant-design/pro-form';
import { Form, message, Modal } from 'antd';
import { useDrawerVisibility } from 'hooks';
import { useShipmentStore } from 'pages/Shipments';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { CreateProductRequest } from 'services/api/client/src';
import { useCreateProduct } from 'services/api/useProducts';
import { errorHandler, getEventsT, hasUnsavedChanges } from 'utils';
import { AddProductColumns, DataItem } from './Columns';
/**
 * Component for adding a new product.
 */
const AddProductForm = () => {
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const { t } = useTranslation('pages', { keyPrefix: 'products.settings' });
  const queryClient = useQueryClient();
  const createProduct = useCreateProduct(queryClient);
  const [form] = Form.useForm<DataItem>();

  const { products } = useShipmentStore();
  const [queryParams] = useSearchParams();

  const isReceive = queryParams?.get('from') === 'receive';
  const productName = queryParams?.get('productName') || '';
  const senderName = queryParams?.get('senderName') || '';

  const onFinish = async (formData: DataItem) => {
    try {
      hasUnsavedChanges();

      const isSerial = formData?.traceabilityType === 'Serial';
      const definedUnits = !isSerial ? formData?.definedUnits : false;
      let unitDescriptor: string | undefined;
      let unitQuantity: number | undefined;
      let uomValue: string | undefined;

      if (definedUnits) {
        if (!formData.uomQuantity) {
          throw new Error(t('uom_req'));
        }
        uomValue = formData.uomQuantity;
        unitDescriptor = formData.unitDescriptor;
        unitQuantity = Number(formData?.unitQuantity || 0);
      } else {
        uomValue = formData.unitOfMeasure;
        unitDescriptor = undefined;
        unitQuantity = undefined;
      }

      const reqData: CreateProductRequest = {
        name: formData.name,
        productIdentifierType: formData.traceabilityType,
        sharingPolicy: formData.dataSharingPolicy,
        gtin: formData?.gtin?.length === 12 ? `00${formData?.gtin || ''}` : formData?.gtin || '',
        plu: formData.plu,
        externalIdentifier: formData.itemid,
        simpleUnitOfMeasurement: uomValue,
        unitDescriptor,
        unitQuantity,
        productMasterData: formData.customAttributes
          ? formData.customAttributes.map((el) => ({
              elementId: String(el.elementId),
              name: el.attribute,
              value: el.value,
              namespace: el?.namespace || '',
            }))
          : undefined,
      };
      await createProduct.mutateAsync(reqData);
      setTimeout(() => {
        message.success(
          t('update_success', {
            name: formData.name,
          }),
        );
      }, 1000);
      closeDrawer();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const onClose = () => {
    const hasFilled = form.isFieldsTouched();

    if (hasFilled) {
      Modal.warning({
        title: t?.('unsaved_changes_title'),
        content: t?.('unsaved_changes_desc'),
        okText: t?.('unsaved_changes_submit'),
        cancelText: t?.('unsaved_changes_cancel'),
        onOk: closeDrawer,
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      });
    } else {
      closeDrawer();
    }
  };
  const onSubmit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (error) {
      message.error(getEventsT('events_field_req'));
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      form={form}
      layoutType="DrawerForm"
      columns={AddProductColumns({
        isProductSettingsDrawer: false,
        products,
        isReceive,
        productName,
        senderName,
      })}
      grid
      title={t('new_product')}
      visible={visible}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        ...restDrawerVisibility,
        onClose,
      }}
      submitter={{
        searchConfig: {
          submitText: t('add'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: (_, defaultDoms) => defaultDoms[1],
        onSubmit,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
      initialValues={{
        traceabilityType: 'Lot',
        identifierType: 'My Own GTIN',
        blockchain: 'Algorand',
        dataSharingPolicy: 'Open',
      }}
    />
  );
};

export default React.memo(AddProductForm);
