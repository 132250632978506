/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { Checkbox, message, Modal, Space, Typography } from 'antd';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getEventResponse, useTradePartners } from 'services/api';
import {
  EventResponse,
  ExistingContainerProductInstanceContract,
  ShipRequest,
} from 'services/api/client/src';
import { useCreateShipEvent, useMultiShipEvent } from 'services/api/events';
import {
  apiDateFormat,
  checkDateValidations,
  checkRequestedDocuments,
  errorHandler,
  getBizStepIdByURN,
  getCustomProperties,
  getDispositionIdByURN,
  getErrorCode,
  getProductsT,
  getTimezoneOffset,
  getTradePartnerOById,
  hasUnsavedChanges,
  mapCertProperties,
} from 'utils';
import { useEventsStore, useTitle } from '../hooks';
import { ShipDataItem as DataItem, useEventActions } from './Forms';
import ShipFormFields from './Forms/ShipForm.fields';
import { EventPageProps } from './typings';

const Ship: FC<EventPageProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.ship' });
  useTitle('ship');
  const queryClient = useQueryClient();
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();

  const {
    productId,
    locationId,
    containerIdArray,
    lastEventDate,
    product: currProduct,
    defaultTimezone,
    disabledDate,
    disabledTime,
    onChangeDate,
    clearSameDayMessage,
    onChangeTemplate,
    toggleTraceModal,
    isSerial,
    identifier,
    onErrorShipEvent,
    timeValidation,
  } = useEventActions();
  const { data: dtradePartners } = useTradePartners();
  const tradePartners = dtradePartners?.data || [];

  const createShipEvent = useCreateShipEvent(queryClient);
  const createShipEvents = useMultiShipEvent(queryClient);

  const {
    hasAdvancedSettings,
    dataEntryMethod,
    setSubmitting,
    template,
    setTemplate,
    // showNewTraceModal,
  } = useEventsStore();

  const navigate = useNavigate();
  const onViewEvent = useCallback(
    (formData: DataItem, res: EventResponse) => {
      const eventId = res?.id || '';
      const instanceId = formData?.eventProducts?.[0]?.id || '';
      const containerstr = formData?.eventProducts?.[0]?.isContainer ? '&isContainer=true' : '';
      navigate({
        pathname: `/products/${productId}/${instanceId}`,
        search: `?eventId=${eventId}${containerstr}`,
      });
    },
    [navigate, productId],
  );

  const generateSuccessMessage = useCallback(
    (formData: DataItem, res?: EventResponse) => {
      const msg =
        (formData?.eventProducts?.length || 0) > 1
          ? t('submit_success_many', { count: formData?.eventProducts?.length })
          : t('submit_success', {
              type: formData?.eventProducts?.[0]?.isContainer
                ? getProductsT('sscc')
                : getProductsT(identifier),
              id: formData?.eventProducts?.[0]?.lotSerial || '',
            });
      const receiveMsg =
        (formData?.eventProducts?.length || 0) > 1
          ? t('submit_receive_success_many', { count: formData?.eventProducts?.length })
          : t('submit_receive_success', {
              type: formData?.eventProducts?.[0]?.isContainer
                ? getProductsT('sscc')
                : getProductsT(identifier),
              id: formData?.eventProducts?.[0]?.lotSerial || '',
            });
      setTimeout(() => {
        message.success(
          <Space>
            {msg}
            {formData?.eventProducts?.length === 1 ? (
              <Typography.Link
                onClick={(e) => {
                  e.preventDefault();
                  if (res) onViewEvent(formData, res);
                }}
              >
                {t('view_details')}
              </Typography.Link>
            ) : null}
          </Space>,
        );
        if (formData?.autologReceiveEvent) {
          message.success(
            <Space>
              {receiveMsg}
              {formData?.eventProducts?.length === 1 ? (
                <Typography.Link
                  onClick={(e) => {
                    e.preventDefault();
                    if (res) onViewEvent(formData, res);
                  }}
                >
                  {t('view_details')}
                </Typography.Link>
              ) : null}
            </Space>,
          );
        }
      }, 800);
    },
    [t, onViewEvent, identifier],
  );

  const onCSVSubmit = async (formData: DataItem) => {
    try {
      const values = formData?.csvData;
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();
        const requests: Array<ShipRequest> = [];
        values?.forEach((formValue) => {
          const isAutoReceive =
            formData?.autologReceiveEvent && !!formValue?.receiveDate && !!formValue?.receiveTime;
          const reqData: ShipRequest = {
            purchaseOrder: formValue.poNumber,

            bizStepId: getBizStepIdByURN(formValue.bizStep),
            dispositionId: getDispositionIdByURN(formValue.disposition),
            eventTime: apiDateFormat(
              formValue?.date || formData?.eventProducts?.[0]?.date,
              formValue?.time || formData?.eventProducts?.[0]?.time,
            ),
            eventTimeZone: getTimezoneOffset(formValue.timeZone),
            originLocationId: formData.location,
            destinationLocationId: formValue.shippingAddress,
            productInstances: [
              {
                id: formValue.lotID,
                quantity: !isSerial ? Number(formValue?.quantity || 0) : 1,
                tlcSource: formValue?.tlcSourceDetails,
              },
            ],
            containerIds: containerIdArray,
            documentsIds: formValue?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            /* auto receive */
            automaticReceive: isAutoReceive,
            receiveEventTime: isAutoReceive
              ? apiDateFormat(formValue.receiveDate, formValue.receiveTime)
              : undefined,
            masterData: getCustomProperties(formData?.customProperties, template),
            certifications: mapCertProperties(formData?.certificationList || []),
          };
          requests.push(reqData);
        });
        await createShipEvents.mutateAsync(requests);
        const msg = t('submit_success_many', { count: requests?.length });

        setTimeout(() => {
          message.success(
            <Space>
              {msg}
              {formData?.eventProducts?.length === 1 ? (
                <Typography.Link
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {t('view_details')}
                </Typography.Link>
              ) : null}
            </Space>,
          );
        }, 800);
      } else {
        throw new Error(t('no_data'));
      }
    } catch (error) {
      throw error;
    }
  };

  const getContainerProductInstances = (formData: DataItem) => {
    let instances: ExistingContainerProductInstanceContract[] = [];
    formData?.eventProducts?.forEach((parentProduct) => {
      const childProducts: any[] =
        parentProduct?.containerItems?.map(
          (product) => ({
            productInstanceId: product?.instanceId || '',
            containerId: parentProduct?.id || '',
            tlcSource: product?.tlcSourceDetails || undefined,
          }),
          [],
        ) || [];
      if (childProducts && childProducts.length) {
        instances = [...instances, ...childProducts];
      }
    }, []);
    return instances?.filter((instance) => instance?.tlcSource);
  };

  const onFormSubmit = async (formData: DataItem) => {
    try {
      if (!formData.isMultipleDates) {
        const reqData: ShipRequest = {
          purchaseOrder: formData.poNumber,
          bizStepId: getBizStepIdByURN(formData.bizStep),
          dispositionId: getDispositionIdByURN(formData.disposition),
          eventTime: apiDateFormat(
            formData?.date || formData?.eventProducts?.[0]?.date,
            formData?.time || formData?.eventProducts?.[0]?.time,
          ),
          eventTimeZone: getTimezoneOffset(formData.timeZone),
          originLocationId: formData.location,
          destinationLocationId: formData.shippingAddress,
          productInstances: formData?.eventProducts
            ?.filter((product) => !product?.isContainer || false)
            ?.map((product) => ({
              id: product.primaryId,
              quantity: isSerial ? 1 : Number(product.quantity || 0),
              tlcSource: product?.tlcSourceDetails,
            })),
          containerProductInstances: getContainerProductInstances(formData) || [],
          containerIds: containerIdArray,
          documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
          templateId: template?.id,
          masterData: getCustomProperties(formData?.customProperties, template),
          certifications: mapCertProperties(formData?.certificationList || []),
          /* auto receive */
          automaticReceive: formData?.autologReceiveEvent || false,
          receiveEventTime: formData?.autologReceiveEvent
            ? apiDateFormat(formData.receiveDate, formData.receiveTime)
            : undefined,
        };
        if (!reqData?.containerProductInstances?.length) {
          delete reqData.containerProductInstances;
        }
        const res = await createShipEvent.mutateAsync({
          ships: [reqData],
        });
        navigate(-1);

        const event = await getEventResponse(res);
        generateSuccessMessage(formData, event);
      } else {
        /* check is date is undefined */
        checkDateValidations(formData?.eventProducts);
        const requests: Array<ShipRequest> = [];
        formData?.eventProducts?.forEach((formValue) => {
          const isContainer = formValue?.isContainer || false;
          const containerProductInstances: any[] = isContainer
            ? formValue?.containerItems
                ?.map(
                  (product) => ({
                    productInstanceId: product?.productId || '',
                    containerId: product?.instanceId || '',
                    tlcSource: product?.tlcSourceDetails || undefined,
                  }),
                  [],
                )
                ?.filter((item) => item?.tlcSource) || []
            : [];
          const reqData: ShipRequest = {
            purchaseOrder: formData.poNumber,
            bizStepId: getBizStepIdByURN(formData.bizStep),
            dispositionId: getDispositionIdByURN(formData.disposition),
            eventTime: apiDateFormat(formValue?.date, formValue?.time),
            eventTimeZone: getTimezoneOffset(formData.timeZone),
            originLocationId: formData.location,
            destinationLocationId: formData.shippingAddress,
            productInstances: !isContainer
              ? [
                  {
                    id: formValue.primaryId,
                    quantity:
                      formValue.unitOfMeasure !== 'item' ? Number(formValue?.quantity || 0) : 1,
                    tlcSource: formValue?.tlcSourceDetails,
                  },
                ]
              : [],
            containerProductInstances,
            containerIds: isContainer ? [formValue?.primaryId || ''] : [],
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            masterData: getCustomProperties(formData?.customProperties, template),
            certifications: mapCertProperties(formData?.certificationList || []),
            /* auto receive */
            automaticReceive: formData?.autologReceiveEvent || false,
            receiveEventTime: formData?.autologReceiveEvent
              ? apiDateFormat(formValue.receiveDate, formValue.receiveTime)
              : undefined,
          };
          if (!reqData?.containerProductInstances?.length) {
            delete reqData.containerProductInstances;
          }
          requests.push(reqData);
        });
        await createShipEvents.mutateAsync(requests);
        navigate(-1);
        generateSuccessMessage(formData);
      }
    } catch (error) {
      throw error;
    }
  };

  const onLogEvent = async (formData: DataItem) => {
    try {
      const isWholechain = !(formData?.isOwnerAccount || !formData?.hasWholechainAccount);
      const tradePartner = getTradePartnerOById(formData?.shippingCompany, tradePartners);
      const showTraceInfoModal =
        // @ts-ignore tbd backend
        tradePartner?.showTraceInfoModal === undefined || tradePartner?.showTraceInfoModal === null
          ? true
          : // @ts-ignore tbd backend
            tradePartner?.showTraceInfoModal;
      /* TLCS Required */
      /* checkTlcsContainerItemsValidations(formData);
      checkTlcsValidations(formData); */
      if ((formData.anotherTracebility || isWholechain) && showTraceInfoModal) {
        const count = formData?.eventProducts?.length || 0;
        const isContainer = formData?.eventProducts?.[0]?.isContainer || false;
        const isGreaterOne = !isContainer ? count > 1 : false;
        const primaryIdentifier = isContainer ? getProductsT('sscc') : getProductsT(identifier);
        await new Promise((resolve, reject) => {
          const modalContent = formData.anotherTracebility
            ? {
                title: t?.('new_tracebility_modal_title'),
                desc: !isGreaterOne
                  ? t?.('new_tracebility_modal_desc', {
                      identifier: primaryIdentifier,
                      lotSerial: formData?.eventProducts?.[0]?.lotSerial || '',
                      tradepartnerName: tradePartner?.name || '',
                    })
                  : t?.('new_tracebility_modal_desc2', {
                      identifier: getProductsT(`${identifier?.toLowerCase()}s`),
                      count,
                      tradepartnerName: tradePartner?.name || '',
                    }),
              }
            : {
                title: t?.('wc_tracebility_modal_title'),
                desc: !isGreaterOne
                  ? t?.('wc_tracebility_modal_desc', {
                      identifier: primaryIdentifier,
                      lotSerial: formData?.eventProducts?.[0]?.lotSerial || '',
                      tradepartnerName: tradePartner?.name || '',
                    })
                  : t?.('wc_tracebility_modal_desc2', {
                      identifier: getProductsT(`${identifier?.toLowerCase()}s`),
                      count,
                      tradepartnerName: tradePartner?.name || '',
                    }),
              };
          Modal.info({
            title: modalContent.title,
            content: (
              <>
                <Typography.Paragraph>{modalContent.desc}</Typography.Paragraph>
                <Checkbox
                  onChange={(e) =>
                    toggleTraceModal(formData?.shippingCompany || '', !e.target.checked)
                  }
                >
                  <Typography.Text>{t?.('donot_show_again_title')}</Typography.Text>
                </Checkbox>
              </>
            ),
            cancelText: t('new_tracebility_modal_cancel'),
            okText: t('new_tracebility_modal_ok'),
            cancelButtonProps: {
              type: 'primary',
              shape: 'round',
              ghost: true,
            },
            okButtonProps: {
              type: 'primary',
              shape: 'round',
            },
            centered: true,
            okCancel: true,
            onCancel: () => {
              reject(new Error('eventCancelled'));
            },
            onOk: () => {
              try {
                Modal.destroyAll();
                resolve(onFormSubmit(formData));
              } catch (error) {
                reject(error);
              }
            },
          });
        });
      } else {
        await onFormSubmit(formData);
      }
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      hasUnsavedChanges();
      checkRequestedDocuments({ template, documents: formData?.documents });

      setSubmitting(true);
      if (dataEntryMethod === 'manual') {
        await onLogEvent(formData);
      } else {
        await onCSVSubmit(formData);
        navigate(-1);
      }

      setSubmitting(false);
      clearSameDayMessage(true);
    } catch (error) {
      setSubmitting(false);
      const msg = errorHandler(error);
      if (msg !== 'eventCancelled') {
        const errorCode = getErrorCode(error);
        switch (errorCode) {
          case 'error.events.shipment.sending_error':
            onErrorShipEvent({});
            break;

          default:
            message.error(msg);
            break;
        }
      }
    }
  };
  return (
    <BetaSchemaForm<DataItem>
      columns={ShipFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        defaultTimezone,
        lastEventDate,
        template,
        locationId,
        product: currProduct,
        isSerial,
        identifier,
        editableTableFormRef,
        disabledTime,
        onChangeDate,
        setTemplate,
        timeValidation,
        disabledDate,
        onChangeTemplate,
        t,
        containerIdArray,
      })}
      form={form}
      grid
      rowProps={{ gutter: 32 }}
      layoutType="Form"
      onFinish={onFinish}
      // we don't need a submitter here since we are submitting from the drawer footer
      submitter={{ render: () => null }}
    />
  );
};

export default React.memo(Ship);
