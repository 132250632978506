import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, EditableFormInstance } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Form, FormInstance, message, Typography } from 'antd';
import { ActionButtons, GTable, ProductLotSelect } from 'components';
import TraceabilityLotCodeSourceSelect from 'components/GSelect/TraceabilityLotCodeSourceSelect';
import moment from 'moment';
import { ContainerTable } from 'pages/Products';
import useProductStore from 'pages/Products/useProductStore';
import { DefaultOptionType } from 'rc-select/lib/Select';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMultipleProductInstances } from 'services/api/useProducts';
import {
  dateLocalFormat,
  disableExistingLotLocations,
  getContainerSum,
  getCurrentInventory,
  getDisabledDate,
  getDisabledTime,
  getInventoryPropsAddon,
  getIsSerial,
  getLotUomAddon,
  getProductsT,
  getQuantityAddon,
  getRecordValue,
  getRecordValuev2,
  getUom,
  hiddencol,
  mapInvDataItem,
  onChangeFieldsPropInstance,
} from 'utils';
import { useEventActions } from '../hooks';
import styles from '../index.module.less';
import { ShipProductItem } from '../typings';
import { ColumnsFn, ShipProductsProps } from './typings';

const columns: ColumnsFn = ({
  onEditRow,
  onDelRow,
  onChangeDate,
  t,
  multipleDates,
  productId,
  defaultUom,
  products,
  product,
  locationId,
  editableFormRef,
  identifier,
  autologReceiveEvent,
  newCompanyLocationList,
  setNewCompanyLocationList,
}) => [
  {
    title: t?.('tbl_col_primary_id_title'),
    dataIndex: 'primaryId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_primary_id_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, rowConfig) =>
      !rowConfig?.record?.isContainer ? (
        <ProductLotSelect
          productId={productId}
          size="small"
          disabledItem={(item) => disableExistingLotLocations(item, products, locationId)}
          onChange={(value, option) =>
            onChangeFieldsPropInstance(editableFormRef?.current, rowConfig, option, {
              unitOfMeasure: getUom({ product }) || defaultUom,
            })
          }
        />
      ) : (
        `${getProductsT('SSCC')}: ${rowConfig?.record?.lotSerial || ''}`
      ),
    render: (_, record) => (
      <span>
        {record?.isContainer
          ? `${getProductsT('SSCC')}:`
          : `${getProductsT(record?.productIdentifierType || identifier)}:`}{' '}
        {record?.lotSerial || ''}
      </span>
    ),
    width: !multipleDates ? '20vw' : undefined,
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form: FormInstance<ShipProductItem>, config) =>
      getLotUomAddon(
        editableFormRef?.current,
        config,
        defaultUom,
        {
          placeholder: t?.('tbl_col_quantity_placeholder'),
          disabled:
            getIsSerial(products, config.rowKey, editableFormRef?.current) ||
            getRecordValuev2('isContainer', products, config.rowKey, editableFormRef?.current) ||
            false,
          className: getRecordValuev2(
            'isContainer',
            products,
            config.rowKey,
            editableFormRef?.current,
          )
            ? 'no-display'
            : 'full-width',
        },
        products,
        getIsSerial(products, config.rowKey, editableFormRef?.current),
      ),
    formItemProps: (form, { rowKey }) => ({
      rules: [
        {
          required: !getIsSerial(products, rowKey, editableFormRef?.current),
          message: t?.('tbl_col_quantity_req'),
        },
      ],
      hasFeedback: false,
    }),

    render: (text, record) => {
      const quantityUom = !record?.isContainer
        ? `${record?.quantity || 0} ${getQuantityAddon(record, defaultUom)}`
        : getContainerSum(record);

      return record?.unitOfMeasure !== 'item' ? quantityUom : `1 ${getProductsT('item')}`;
    },
    width: !multipleDates ? '20vw' : undefined,
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    tooltip: {
      title: t?.('tbl_col_current_inventory_tooltip'),
      icon: <InfoCircleOutlined />,
    },
    fieldProps: (form, config) =>
      getInventoryPropsAddon(
        editableFormRef?.current,
        config,
        defaultUom,
        {
          disabled: true,
        },
        getRecordValuev2('unitOfMeasure', products, config.rowKey, editableFormRef?.current) ===
          'item',
      ),
    render: (text, record) => getCurrentInventory(record),
    width: !multipleDates ? '20vw' : undefined,
    ellipsis: true,
  },
  {
    title: t?.('traceability_lotcode'),
    dataIndex: 'tlcSource',
    tooltip: {
      title: (
        <span className={styles.tooltiplink}>
          {t?.('traceability_info_tooltip')}{' '}
          <Typography.Link
            href="https://www.fda.gov/food/food-safety-modernization-act-fsma/traceability-lot-code"
            target="_blank"
          >
            {t?.('traceability_info_tooltip_link')}
          </Typography.Link>
          .
        </span>
      ),
      icon: <InfoCircleOutlined />,
    },
    valueType: 'select',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('tlcs_required'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('tlcs_placeholder'),
    },
    renderFormItem: (_, { recordKey, record }, form) =>
      !record?.isContainer ? (
        <TraceabilityLotCodeSourceSelect
          size="small"
          isAddProduct
          allowLotSerial
          onChange={(value, option: any) => {
            const { setFieldsValue } = form;
            if (option && option?.itemProps) {
              const item: any = option?.itemProps;
              setFieldsValue({
                [String(recordKey)]: {
                  tlcSourceDetails: { ...item?.address, name: item?.name },
                  tlcSource: item?.name,
                },
              });
            }
          }}
          onClear={() => {
            const { setFieldsValue } = form;
            setFieldsValue({
              [String(recordKey)]: { tlcSource: undefined, tlcSourceDetails: undefined },
            });
          }}
          allowClear
          eventId={record?.eventId || ''}
          updateListHander={(updatedList: DefaultOptionType[]) => {
            setNewCompanyLocationList(updatedList);
          }}
          newCompanyLocationList={newCompanyLocationList}
        />
      ) : null,
    render: (text, record: ShipProductItem) => record?.tlcSource,
    width: '20vw',
  },
  {
    dataIndex: 'tlcSourceDetails',
    ...hiddencol,
  },
  {
    dataIndex: 'instanceInventory',
    ...hiddencol,
  },
  {
    title: t?.('tbl_col_date_title'),
    dataIndex: 'date',
    valueType: 'date',
    hideInTable: !multipleDates,
    fieldProps: (form, { rowKey }) => ({
      size: 'small',
      placeholder: t?.('tbl_col_date_title'),
      onChange: (value: string) =>
        onChangeDate?.(
          editableFormRef?.current,
          value,
          multipleDates,
          rowKey,
          editableFormRef?.current?.getFieldValue?.(String(rowKey))?.eventDate ||
            getRecordValue('eventDate', products, rowKey),
        ),
      disabledDate: getDisabledDate(
        editableFormRef?.current?.getFieldValue?.(String(rowKey))?.eventDate ||
          getRecordValue('eventDate', products, rowKey),
      ),
      className: 'full-width',
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_date_req'),
        },
      ],
      hasFeedback: false,
    },
  },
  {
    dataIndex: 'isContainer',
    ...hiddencol,
  },
  {
    title: t?.('tbl_col_time_title'),
    dataIndex: 'time',
    valueType: 'time',
    hideInTable: !multipleDates,
    fieldProps: (form, { rowKey }) => ({
      size: 'small',
      placeholder: t?.('tbl_col_time_title'),
      disabledTime: getDisabledTime(
        editableFormRef?.current?.getFieldValue?.(String(rowKey))?.eventDate ||
          getRecordValue('eventDate', products, rowKey),
      ),
      className: 'full-width',
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_date_req'),
        },
      ],
      hasFeedback: false,
    },
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    title: t?.('tbl_col_receive_date_title'),
    dataIndex: 'receiveDate',
    valueType: 'date',
    hideInTable: !autologReceiveEvent || !multipleDates,
    fieldProps: (form, { rowKey }) => ({
      size: 'small',
      placeholder: t?.('tbl_col_receive_date_title'),
      onChange: (value: string) =>
        onChangeDate?.(
          editableFormRef?.current,
          value,
          multipleDates,
          rowKey,
          editableFormRef?.current?.getFieldValue?.(String(rowKey))?.date ||
            getRecordValue('date', products, rowKey),
          'receiveTime',
        ),
      disabledDate: getDisabledDate(
        editableFormRef?.current?.getFieldValue?.(String(rowKey))?.date ||
          getRecordValue('date', products, rowKey),
      ),
      className: 'full-width',
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_receive_date_req'),
        },
      ],
      hasFeedback: false,
    },
  },
  {
    title: t?.('tbl_col_receive_time_title'),
    dataIndex: 'receiveTime',
    valueType: 'time',
    hideInTable: !autologReceiveEvent || !multipleDates,
    fieldProps: (form, { rowKey }) => {
      const date =
        editableFormRef?.current?.getFieldValue?.(String(rowKey))?.date ||
        getRecordValue('date', products, rowKey);
      const time =
        editableFormRef?.current?.getFieldValue?.(String(rowKey))?.time ||
        getRecordValue('time', products, rowKey);
      const isDateSelected = !!date;
      const isTimeSelected = !!time;
      // date can be str or moment so we need to convert it to date YYYY-MM-DD
      const shipDate = !(typeof date === 'string' || date instanceof String)
        ? moment(date).format('YYYY-MM-DD')
        : date;
      const shipTime = !(typeof time === 'string' || time instanceof String)
        ? moment(time).format('HH:mm:ss')
        : time;
      const shipDateTime = new Date(`${shipDate} ${shipTime}`);

      const shipDateTimeStr = dateLocalFormat(shipDateTime);

      return {
        size: 'small',
        placeholder: t?.('tbl_col_receive_time_title'),
        disabledTime:
          isDateSelected && isTimeSelected ? getDisabledTime(shipDateTimeStr) : undefined,
        className: 'full-width',
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_date_req'),
        },
      ],
      hasFeedback: false,
    },
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: (_text, record) => (
      <ActionButtons
        record={record}
        onEdit={onEditRow}
        onDelete={onDelRow}
        editButtonProps={{ hidden: record?.isContainer && !multipleDates }}
      />
    ),
  },
];
const ShipProducts: FC<ShipProductsProps> = ({
  form,
  multipleDates,
  isSerial,
  identifier,
  selectedInstances,
  product,
  locationId: elocationId,
  autologReceiveEvent,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.ship.form_fields' });
  const [fetched, setFetched] = useState<boolean>(false);
  const { setFieldsValue } = form;
  const [newCompanyLocationList, setNewCompanyLocationList] = useState<DefaultOptionType[]>([]);
  const {
    locationId,
    productId,
    productIdArray,
    containerIdArray,
    defaultUom: fallbackUom,
    product: dataProduct,
    onChangeDate,
  } = useEventActions({
    locationId: elocationId,
  });
  const currProduct = useMemo(() => dataProduct || product, [product, dataProduct]);
  const defaultUom = useMemo(
    () => getUom({ product: currProduct }) || fallbackUom,
    [currProduct, fallbackUom],
  );
  const sactionRef = useRef<ActionType>();

  const products: Array<ShipProductItem> = Form?.useWatch('eventProducts', form) || [];
  const editableFormRef = useRef<EditableFormInstance<any>>();
  const { paramsStore } = useProductStore();
  const {
    data: dataProducts = [],
    isLoading,
    isSuccess,
  } = useMultipleProductInstances(productIdArray, containerIdArray, paramsStore);

  const setProducts = useCallback(
    (value: Array<ShipProductItem>) => {
      setFieldsValue({
        eventProducts: value,
      });
    },
    [setFieldsValue],
  );

  useEffect(() => {
    if (isSuccess && !fetched) {
      const modifiedProducts = dataProducts?.map(mapInvDataItem) || [];
      setProducts(modifiedProducts);
      setFetched(true);
    }
    if ((selectedInstances?.length || 0) > 0) {
      setProducts(
        selectedInstances?.map((p) => ({
          id: p?.id,
          name: p?.name,
          productIdentifierType: p?.productIdentifierType || '',
          parentProductId: p?.productId || '',
          primaryId: p?.instanceId || p?.containerId || '',
          lotSerial: p?.lotSerial || p?.containerIdentifier || '',
          quantity: p?.quantity || 0,
          unitOfMeasure: p.unitOfMeasure || defaultUom,
          isContainer: p?.isContainer || false,
          containerItems: p?.containerItems || [],
          currentInventory: p?.currentInventory || 0,
          instanceInventory: p?.quantity || 0,
          eventDate: p?.eventDate ? dateLocalFormat(p?.eventDate) : undefined,
        })) || [],
      );
    }
  }, [dataProducts, isSuccess, fetched, currProduct, setProducts, selectedInstances, defaultUom]);

  const onEditRow = (actionPayload?: ShipProductItem) => {
    sactionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onDelRow = async (actionPayload?: ShipProductItem) => {
    if (actionPayload && products.length > 1) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    } else {
      message.error(getProductsT('delete_last_product'));
    }
  };
  const onSaveProduct = async (rowKey: RecordKey, data: ShipProductItem) => {
    const isSer = getIsSerial(products, String(rowKey), editableFormRef?.current);
    sactionRef.current?.cancelEditable(data?.id || 0);

    const existingProduct = products.find((p) => p.id === data?.id);
    if (existingProduct) {
      existingProduct.name = data?.name || '';
      existingProduct.quantity = isSer ? 1 : data?.quantity;
      existingProduct.instanceInventory = data?.instanceInventory;
      existingProduct.currentInventory = Number(data?.currentInventory) || 0;
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.lotSerial = data?.lotSerial || '';
      existingProduct.date = data?.date;
      existingProduct.time = data?.time;
      existingProduct.receiveDate = data?.receiveDate;
      existingProduct.receiveTime = data?.receiveTime;
      existingProduct.unitOfMeasure = data?.unitOfMeasure;
      existingProduct.eventDate = data?.eventDate;
      existingProduct.tlcSource = data?.tlcSource;
      existingProduct.tlcSourceDetails = data?.tlcSourceDetails;
      // update product
      setProducts(products.map((p) => (p.id === existingProduct.id ? existingProduct : p)));
    } else {
      const productData = {
        id: data?.id || '0',
        name: data?.name || '',
        lotSerial: data?.lotSerial || '',
        primaryId: data?.primaryId || '0',
        currentInventory: Number(data?.currentInventory) || 0,
        quantity: isSer ? 1 : Number(data?.quantity || 0),
        instanceInventory: Number(data?.instanceInventory || 0),
        date: data?.date,
        time: data?.time,
        receiveDate: data?.receiveDate,
        receiveTime: data?.receiveTime,
        unitOfMeasure: data?.unitOfMeasure,
        eventDate: data?.eventDate,
        tlcSource: data?.tlcSource,
        tlcSourceDetails: data?.tlcSourceDetails,
      };
      // add product
      setProducts([productData, ...products]);
    }
  };
  const expandedRowRender = (rowData: ShipProductItem) => {
    const { containerItems, id } = rowData;
    return (
      <ContainerTable
        products={containerItems}
        parentProducts={products}
        parentRowId={id}
        parentForm={form}
        showHeader={false}
        className={styles.shipcontainer}
        identifier={identifier}
        columnProps={{
          lotSerial: {
            width: '23%',
            fieldProps: {
              size: 'small',
              disabled: true,
            },
          },
          quantity: {
            width: '23%',
            fieldProps: {
              size: 'small',
              disabled: true,
            },
          },
          currentInventory: {
            width: '23%',
            hideInTable: false,
            fieldProps: {
              size: 'small',
              disabled: true,
            },
          },
          lastEvent: {
            hideInTable: true,
          },
          locationName: {
            hideInTable: true,
          },
          tlcSource: {
            hideInTable: false,
            width: '23%',
            fieldProps: {
              size: 'small',
              disabled: true,
            },
          },
          actions: {
            // width: 200,
            hideInTable: false,
          },
        }}
        setNewCompanyLocationList={(updatedList: DefaultOptionType[]) => {
          setNewCompanyLocationList(updatedList);
        }}
        newCompanyLocationList={newCompanyLocationList}
        t={t}
      />
    );
  };

  const rowExpandable = useCallback(
    (rowData: ShipProductItem) => rowData?.isContainer || false,
    [],
  );
  return (
    <GTable<ShipProductItem>
      key="sactionRef"
      actionRef={sactionRef}
      editableFormRef={editableFormRef}
      columns={columns({
        t,
        onEditRow,
        onDelRow,
        onChangeDate,
        multipleDates,
        productId: product?.id || productId,
        locationId,
        defaultUom,
        products,
        product: currProduct,
        isSerial,
        identifier,
        autologReceiveEvent,
        // @ts-ignore
        editableFormRef,
        newCompanyLocationList,
        setNewCompanyLocationList,
      })}
      editable={{
        onSave: (rowKey, data) => onSaveProduct(rowKey, data),
        onCancel: async (_rowKey, data) => {
          sactionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        sactionRef.current?.addEditRecord?.(
          {
            id: Date.now().toString(),
            currentInventory: products?.[0]?.currentInventory || 0,
          },
          {
            position: 'top',
          },
        );
      }}
      loading={isLoading}
      value={products}
      enableRecordCreator
      expandable={{ expandedRowRender, rowExpandable, columnWidth: 30 }}
      scroll={{ x: 900, y: '50vh' }}
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
      }}
    />
  );
};
export default React.memo(ShipProducts);
