import { BetaSchemaForm } from '@ant-design/pro-form';
import { message, Space, Typography } from 'antd';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getEventResponse } from 'services/api';
import { AggregateRequest, EventResponse } from 'services/api/client/src';
import { useCreateAggregateEvent } from 'services/api/events';
import {
  apiDateFormat,
  errorHandler,
  getBizStepIdByURN,
  getCustomProperties,
  getDispositionIdByURN,
  getProductsT,
  getTimezoneOffset,
  hasUnsavedChanges,
  mapCertProperties,
} from 'utils';
import { useEventsStore, useTitle } from '../hooks';
import { useEventActions } from './Forms';
import AggregationFormFields, { DataItem } from './Forms/AggregationForm.fields';
import { EventPageProps } from './typings';

const Aggregation: FC<EventPageProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.aggregate' });
  useTitle('aggregation');
  const {
    disabledDate,
    productId,
    disabledTime,
    lastEventDate,
    clearSameDayMessage,
    onChangeDate,
    defaultTimezone,
    isSerial,
    identifier,
    timeValidation,
    onChangeTemplate,
  } = useEventActions();

  const { hasAdvancedSettings, dataEntryMethod, setSubmitting, template, setTemplate } =
    useEventsStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const createAggregateEvent = useCreateAggregateEvent(queryClient);

  const onViewEvent = useCallback(
    (formData: DataItem, res: EventResponse | undefined) => {
      const containerId = res?.containers?.[0]?.id || '';
      const eventId = res?.id || '';
      navigate({
        pathname: `/products/${productId}/${containerId}`,
        search: `?eventId=${eventId}&isContainer=true`,
      });
    },
    [navigate, productId],
  );
  const onGenerateMsg = useCallback(
    (formData: DataItem, res: EventResponse | undefined) => {
      const msg =
        (formData?.eventProducts?.length || 0) > 1
          ? t('submit_success_many', { count: formData?.eventProducts?.length })
          : t('submit_success', {
              type: getProductsT(identifier),
              id: formData?.eventProducts?.[0]?.lotSerial || '',
            });
      setTimeout(() => {
        message.success(
          <Space>
            {msg}
            <Typography.Link
              onClick={(e) => {
                e.preventDefault();
                onViewEvent(formData, res);
              }}
            >
              {t('view_details')}
            </Typography.Link>
          </Space>,
        );
      }, 900);
    },
    [onViewEvent, t, identifier],
  );

  const getIdentifierFields = (formData: DataItem) => {
    if (formData?.sscc && formData?.logisticid) {
      return {
        identifierType: 'SSCC',
        containerIdentifier: formData.sscc,
        masterData: [
          {
            name: 'LogisticsId',
            namespace: '',
            value: formData?.logisticid,
          },
        ],
      };
    }
    if (formData?.sscc) {
      return {
        identifierType: 'SSCC',
        containerIdentifier: formData.sscc,
      };
    }
    if (formData?.logisticid) {
      return {
        identifierType: 'LogisticId',
        containerIdentifier: formData?.logisticid,
      };
    }
    return {};
  };

  const onFinish = async (formData: DataItem) => {
    try {
      hasUnsavedChanges();
      const identifierFields = getIdentifierFields(formData);
      if (!identifierFields?.identifierType) {
        message.error(t?.('identifier_warning'));
        return;
      }

      const reqData: AggregateRequest = {
        purchaseOrder: formData.poNumber,
        identifierType: identifierFields?.identifierType,
        containerIdentifier: identifierFields?.containerIdentifier,
        bizStepId: getBizStepIdByURN(formData.bizStep),
        dispositionId: getDispositionIdByURN(formData.disposition),
        eventTime: apiDateFormat(formData.date, formData.time),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        productInstances: formData.eventProducts.map((product) => ({
          id: product.primaryId,
          quantity: Number(product.quantity || 0),
        })),
        masterData: [
          ...getCustomProperties(formData?.customProperties, template),
          ...(identifierFields?.masterData || []),
        ],
        certifications: mapCertProperties(formData?.certificationList || []),
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
      };
      setSubmitting(true);
      const res = await createAggregateEvent.mutateAsync(reqData);
      setSubmitting(false);
      navigate(-1);
      clearSameDayMessage();

      /* get event response */
      const event = await getEventResponse(res);
      onGenerateMsg(formData, event);
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
      setSubmitting(false);
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      columns={AggregationFormFields({
        disabledDate,
        disabledTime,
        onChangeDate,
        timeValidation,
        hasAdvancedSettings,
        dataEntryMethod,
        lastEventDate,
        defaultTimezone,
        isSerial,
        identifier,
        template,
        setTemplate,
        onChangeTemplate,
      })}
      form={form}
      grid
      rowProps={{ gutter: 32 }}
      layoutType="Form"
      onFinish={onFinish}
      submitter={{ render: () => null }}
    />
  );
};

export default React.memo(Aggregation);
