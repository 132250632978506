import { ActionType } from '@ant-design/pro-table';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions } from 'components';
import GTable from 'components/GTable';
import { useModalVisibility } from 'hooks';
import { useEventActions } from 'pages/Events/components';
import { useEventsStore } from 'pages/Events/hooks';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, Key, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';

import { useEventMapByIdEvent } from 'services/api';
import {
  dateLocalFormat,
  dateLocaleFormat,
  downloadJSONToCSVLotDetail,
  getEventsT,
  getProductsT,
  getShipmentItemFromProductInventory,
  getUom,
  isSameUom,
  quantitiesCheckbox,
} from 'utils';
import ContainerModal from './ContainerModal';
import {
  CurrentInventoryProps,
  InvColumnFunction,
  InvColumnType,
  ProductInventoryItem,
} from './typings';
import useProductInstanceFilters from './useProductInstanceFilters';
import useProductStore from './useProductStore';

type ActionItemsProps = {
  t: TFunction<'pages', 'products.current_inventory'>;
  selectedRowKeys: Array<Key>;
  selectedRows?: Array<ProductInventoryItem>;
  record?: ProductInventoryItem;
};

/**
 * Generates an array of action items based on the provided translation function and record data.
 * @param t The translation function.
 * @param record The record data.
 * @returns An array of action items.
 */
const productActionItems = ({ t, record }: ActionItemsProps): Array<ItemType> => {
  const isSscc = record?.isContainer || false;
  const ssccItem: ItemType = {
    key: 'view_sscc',
    label: t('actions.view_sscc'),
  };
  return [
    {
      key: 'view_event',
      label: t('actions.view_event'),
    },
    ...(isSscc ? [ssccItem] : []),
    {
      key: 'events',
      type: 'group',
      label: t('actions.events'),
      children: [
        {
          key: 'aggregate',
          label: t('actions.aggregate'),
          disabled: record?.isContainer || record?.eventName === 'Ship' || false,
        },
        {
          key: 'disaggregation',
          label: t('actions.disaggregate'),
          disabled: !(record?.isContainer && record?.eventName !== 'Ship') || false,
        },
        {
          key: 'transform',
          label: t('actions.transform'),
          disabled: record?.isContainer || record?.eventName === 'Ship' || false,
        },
        {
          key: 'decommission',
          label: t('actions.decommission'),
          disabled: record?.eventName === 'Ship' || false,
        },
        {
          key: 'ship',
          label: t('actions.ship'),
          disabled: record?.eventName === 'Ship' || false,
        },
        // {
        //   key: 'receive',
        //   label: t('actions.receive'),
        //   disabled: !(record?.eventName === 'Ship'),
        // },
        {
          key: 'observe',
          label: t('actions.observe'),
          disabled: record?.eventName === 'Ship',
        },
      ],
    },
    {
      key: 'manage',
      label: t('actions.manage'),
      type: 'group',
      children: [
        {
          key: 'remove_event',
          label: t('actions.remove_event'),
        },
        {
          key: 'download_xls',
          label: t('actions.download_csv_item'),
        },
      ],
    },
  ];
};

/**
 * Generates an array of columns for the inventory table based on the provided parameters.
 * @param t The translation function.
 * @param onRowActionClick Function to handle row action clicks.
 * @param setInventoryItem Function to set the inventory item.
 * @param primaryIdSearchfilter Search filter for the primary ID column.
 * @param poSearchfilter Search filter for the purchase order column.
 * @param selectedRowKeys Selected row keys.
 * @param defaultUom Default unit of measure.
 * @param isSerial Indicates whether the inventory item is serial.
 * @param coreLocations Array of core locations for filtering.
 * @param coreEventTypes Array of core event types for filtering.
 * @returns An array of columns for the inventory table.
 */
const columns: InvColumnFunction = ({
  t,
  onRowActionClick,
  setInventoryItem,
  primaryIdSearchfilter,
  poSearchfilter,
  selectedRowKeys,
  defaultUom,
  isSerial,
  coreLocations,
  coreEventTypes,
}) => [
  {
    title: t('tbl_col_primary_id'),
    dataIndex: 'primaryId',
    fixed: 'left',
    hideInSetting: true,
    render: (text, record) => {
      const name = !record?.isContainer
        ? `${getProductsT(record.productIdentifierType)}: ${record?.lotSerial || ''}`
        : `${getProductsT('sscc')}: ${record?.containerIdentifier || ''}`;
      return record?.id ? (
        <Link
          className="text-link"
          to={{
            pathname: String(record?.id || record?.containerId || ''),
            search: `?eventId=${record?.eventId || ''}${
              record?.isContainer ? '&isContainer=true' : ''
            }`,
          }}
          onClick={() => {
            setInventoryItem(record);
          }}
        >
          {name}
        </Link>
      ) : (
        name
      );
    },
    ellipsis: true,
    width: 200,
    ...primaryIdSearchfilter,
  },
  {
    title: t('tbl_col_quantity'),
    dataIndex: 'quantity',
    width: 180,
    ellipsis: true,
    hideInTable: isSerial || false,
    render: (text, record: any) => {
      const isMultipleProducts = !!record?.containerItems?.find(
        (item: any) => item?.product?.id !== record?.containerItems?.[0]?.product?.id,
        [],
      );
      const isSameUOM = isSameUom(record?.containerItems?.map((item: any) => item.product));
      let returnValue = '-';
      if (isMultipleProducts && !isSameUOM) {
        returnValue = '-';
      } else {
        returnValue = `${record?.quantity || ''} ${
          getUom({
            product: record,
          }) || defaultUom
        }`;
      }
      return returnValue;
    },
  },
  {
    title: t('tbl_col_event_name'),
    dataIndex: 'eventName',
    width: 150,
    ellipsis: true,
    filters: coreEventTypes,
    render: (text, record) => `${getEventsT(record?.eventName) || ''}`,
  },
  {
    title: t('tbl_col_location'),
    dataIndex: 'locationAddress',
    width: '30vw',
    filters: coreLocations,
    filterSearch: true,
    ellipsis: true,
  },
  {
    title: t('tbl_col_purchase_order'),
    dataIndex: 'purchaseOrder',
    ellipsis: true,
    width: 150,
    ...poSearchfilter,
  },
  {
    title: t('tbl_col_event_date'),
    dataIndex: 'eventDate',
    sorter: true,
    ellipsis: true,
    width: 150,
    render: (text, record) => `${record?.eventDate ? dateLocaleFormat(record?.eventDate) : ''}`,
  },
  {
    title: t('tbl_col_logged_date'),
    dataIndex: 'loggedDate',
    sorter: true,
    defaultSortOrder: 'descend',
    ellipsis: true,
    width: 150,
    render: (text, record) => `${record?.loggedDate ? dateLocalFormat(record?.loggedDate) : ''}`,
  },
  {
    title: t('tbl_col_blockchain'),
    dataIndex: 'blockchain',
    ellipsis: true,
    width: 120,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 120,
    hideInSetting: true,
    fixed: 'right',
    render: (text, record) =>
      record?.id ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          items={productActionItems({ selectedRowKeys, t, record })}
          onClick={onRowActionClick}
          actionPayload={record}
        />
      ) : undefined,
  },
];
const CurrentInventory: FC<CurrentInventoryProps> = ({ selectedRowKeys, onRowSelectionChange }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('pages', { keyPrefix: 'products.current_inventory' });

  // const { commissionCSVContextHolder } = useMessageContext();
  const { setTemplate, setDataEntryMethod, setAdvancedSettings, setMultipleDates } =
    useEventsStore();
  const setInstance = useProductStore((state) => state.setProduct);
  const { setProducts: setShipmentProduct, setInventoryItem } = useShipmentStore();

  const containerModal = useModalVisibility();
  const actionRef = useRef<ActionType>();
  const { productId, product, defaultUom, identifier, isSerial, onRemoveEvent } = useEventActions();
  const {
    params,
    totalItems,
    isLoading,
    records,
    coreLocations,
    coreEventTypes,
    onTableChange,
    refetch,
    filters: [primaryIdSearchfilter, poSearchfilter],
  } = useProductInstanceFilters(productId, {
    sortBy: '-loggedDate',
    isHistory: false,
  });

  const queryClient = useQueryClient();
  const getEventMapById = useEventMapByIdEvent(queryClient);
  const onDownloadCSV = async (actionPayload?: ProductInventoryItem) => {
    const instances = await getEventMapById.mutateAsync({
      eventId: actionPayload?.eventId,
      options: {
        instanceId: actionPayload?.instanceId || undefined,
        ...(actionPayload?.isContainer
          ? {
              containerId: actionPayload?.containerId,
              instanceId:
                actionPayload?.containerItems?.[0]?.instanceId ||
                actionPayload?.instanceId ||
                undefined,
            }
          : {}),
        showAllProducts: true,
      },
    });
    if (instances && actionPayload) {
      downloadJSONToCSVLotDetail(actionPayload, instances);
    }
  };
  const onRowActionClick: InvColumnType['onRowActionClick'] = (actionItemKey, actionPayload) => {
    const recordId = actionPayload?.id || actionPayload?.containerId || '';
    const productsSearchParams = createSearchParams({
      products: String(recordId),
      locationId: actionPayload?.destinationId || actionPayload?.locationId || '',
      shippedFrom: actionPayload?.tradePartnerName || '',
      eventDate: actionPayload?.eventDate ? dateLocaleFormat(actionPayload?.eventDate) : '',
      ...(actionPayload?.isContainer && { containers: actionPayload?.containerId || '' }),
    });

    switch (actionItemKey) {
      case 'decommission':
        navigate({ pathname: '../events/decommission', search: `?${productsSearchParams}` });
        break;
      case 'aggregate':
        navigate({ pathname: `../events/aggregation`, search: `?${productsSearchParams}` });
        break;
      case 'disaggregation':
        navigate({ pathname: `../events/disaggregation`, search: `?${productsSearchParams}` });
        break;
      case 'observe':
        navigate({ pathname: `../events/observe`, search: `?${productsSearchParams}` });
        break;
      case 'ship':
        navigate({
          pathname: `../events/ship/${recordId}`,
          search: `?${productsSearchParams}&eventId=${actionPayload?.eventId || ''}`,
        });
        break;
      case 'receive':
        navigate({
          pathname: '../events/receive',
          search: actionPayload ? `?${productsSearchParams}` : undefined,
        });
        setShipmentProduct?.([
          getShipmentItemFromProductInventory(actionPayload, product, {
            shipmentType: 'Internal',
            status: 'Pending',
          }),
        ]);

        break;
      case 'transform':
        navigate({
          pathname: `../events/transform/${recordId}`,
          search: `?${productsSearchParams}`,
        });
        break;
      case 'view_settings':
        navigate({ pathname: `../settings` });
        break;
      case 'view_event':
        navigate({
          pathname: String(recordId || '1'),
          search: `?eventId=${actionPayload?.eventId || '1'}${
            actionPayload?.isContainer ? '&isContainer=true' : ''
          }`,
        });
        break;
      case 'view_sscc':
        setInstance(actionPayload);
        containerModal.show();
        break;
      case 'download_xls':
        onDownloadCSV(actionPayload);
        break;
      case 'remove_event':
        onRemoveEvent(actionPayload);
        break;

      default:
        break;
    }
    setInventoryItem(actionPayload as ProductInventoryItem);
    setTemplate(undefined);
    setDataEntryMethod('manual');
    setMultipleDates(false);
    if (actionItemKey === 'observe') {
      setAdvancedSettings(true);
    } else {
      setAdvancedSettings(false);
    }
    onRowSelectionChange?.([], []);
  };

  return (
    <>
      <ContainerModal modal={containerModal} />
      <GTable<ProductInventoryItem>
        className="gtbl-abs-toolbar"
        actionRef={actionRef}
        columns={columns({
          t,
          onRowActionClick,
          setInventoryItem,
          primaryIdSearchfilter,
          selectedRowKeys,
          defaultUom,
          identifier,
          isSerial,
          coreLocations,
          coreEventTypes,
          poSearchfilter,
        })}
        columnsState={{
          defaultValue: {
            purchaseOrder: {
              show: true,
            },
            eventDate: {
              show: false,
            },
            loggedDate: {
              show: false,
            },
            blockchain: {
              show: false,
            },
          },
          persistenceKey: 'current-inventory-columns',
        }}
        loading={isLoading}
        enableRowSelection
        value={records}
        recordCreatorProps={false}
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '500', '1000'],
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: onRowSelectionChange,
          columnWidth: 30,
          preserveSelectedRowKeys: true,
          getCheckboxProps: quantitiesCheckbox,
        }}
        scroll={{ y: '75vh', x: 1200 }}
        onTableChange={onTableChange}
        options={{
          reload: () => refetch(),
        }}
      />
    </>
  );
};
export default React.memo(CurrentInventory);
