/* eslint-disable no-useless-catch */
import { FormInstance, Modal, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { MessageType } from 'antd/lib/message';
import { useModalVisibility } from 'hooks';
import moment from 'moment';
import { MultiReceiveProductItem } from 'pages/Events/components/Forms/AllReceiveForm.fields';
import { DataItem } from 'pages/Events/components/Forms/CommissionForm.fields';
import { useEventsStore } from 'pages/Events/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAccount, useAttributes } from 'services/api';
import { EventTemplatesResponse } from 'services/api/client/src';
import {
  filterTCertAttribute,
  getDisabledDate,
  getDisabledTime,
  getProductsT,
  getTParam,
  mapCertDocuments,
  supportEmailLink,
} from 'utils';
import { isObject } from 'utils/locations';
import { v4 as uuidv4 } from 'uuid';
import styles from '../index.module.less';
import { OnErrorShipEvent, TimeValidationFn, useEventActionsProps } from './typings';

const useEventActions = (defaultProps?: useEventActionsProps) => {
  const eventErrorModal = useModalVisibility();
  const { data: attributes } = useAttributes();
  const { data: account } = useAccount();
  const [queryParams] = useSearchParams();
  const { productId } = useParams();
  const [sameDayMessage, setSameDayMessage] = useState<MessageType>();
  const [msgKey, setMsgKey] = useState<string>();
  const { template, setTemplate, dataEntryMethod } = useEventsStore();

  const defaultContainerIdArray = useMemo(
    () => defaultProps?.containerIdArray || [],
    [defaultProps],
  );
  const defaultProductIdArray = useMemo(() => defaultProps?.productIdArray || [], [defaultProps]);
  const defaultEventDate = useMemo(() => defaultProps?.eventDate, [defaultProps]);
  const defaultLocationId = useMemo(() => defaultProps?.locationId, [defaultProps]);
  const defaultLocationName = useMemo(() => defaultProps?.locationName || '', [defaultProps]);
  const defaultTradePartnerId = useMemo(() => defaultProps?.tradePartnerId || '', [defaultProps]);

  const containerIdArray = defaultContainerIdArray || queryParams?.get('containers')?.split(',');
  const productIdArray = defaultProductIdArray || queryParams?.get('products')?.split(',');
  const eventDate = defaultEventDate || queryParams?.get('eventDate') || '';
  const locationId = defaultLocationId || queryParams?.get('locationId');
  const tradePartnerId = defaultTradePartnerId || queryParams?.get('tradePartnerId');
  const locationName = defaultLocationName || queryParams?.get('locationName');

  const lotId = productIdArray?.[0] || '';

  const [lastEventDate, setLastEventDate] = useState<string>(eventDate);

  useEffect(() => {
    if (eventDate) {
      setLastEventDate(eventDate);
    }
  }, [eventDate]);

  const disabledDate = useMemo(() => getDisabledDate(lastEventDate), [lastEventDate]);
  const disabledTime = useMemo(() => getDisabledTime(lastEventDate), [lastEventDate]);
  const timeValidation = useCallback<TimeValidationFn>(
    (date, prevEventDate) => {
      const rules: Array<Rule> = [
        {
          /* value should be greater than lastEventDate time */
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            }

            const prevEventDateStr = prevEventDate || lastEventDate;
            const lastEventDateM = moment(new Date(prevEventDateStr));
            const eventTime = !(typeof value === 'string' || value instanceof String)
              ? moment(value, 'HH:mm:ss')
              : String(value);

            const eDate = !(typeof date === 'string' || date instanceof String)
              ? moment(date).format('YYYY-MM-DD')
              : String(date);
            const eventDateTime = moment(
              `${moment(new Date(eDate)).format('YYYY-MM-DD')} ${
                !(typeof value === 'string' || value instanceof String)
                  ? moment(eventTime).format('HH:mm:ss')
                  : eventTime
              }`,
              'YYYY-MM-DD HH:mm:ss',
            );

            const lastEventTime = !(typeof prevEventDateStr === 'string')
              ? moment(new Date(prevEventDateStr), 'HH:mm:ss')
              : prevEventDateStr;

            switch (true) {
              case moment(eDate).isSame(lastEventDateM, 'day'): {
                const isSameofBeforeTime = moment(eventDateTime).isSameOrBefore(
                  lastEventTime,
                  'second',
                );

                if (isSameofBeforeTime) {
                  return Promise.reject(
                    new Error(getTParam('error.events.eventtime_greater_last_event')),
                  );
                }
                break;
              }

              default:
                break;
            }
            return Promise.resolve();
          },
        },
      ];
      return rules;
    },
    [lastEventDate],
  );

  const defaultTimezone = useMemo(
    () => account?.timezone || '(UTC-05:00) Eastern Time (US & Canada)',
    [account],
  );
  const defaultAttribute = useMemo(
    () => ({
      id: template?.id || '',
      name: 'Custom Data Template',
      value: template?.templateName || 'None',
    }),
    [template],
  );

  const triggerSameDayMessage = useCallback(
    (isMultipleDates?: boolean) => {
      message.destroy();
      const key = uuidv4();
      const messageFn = message.info(
        {
          content: !isMultipleDates
            ? getProductsT('same_day_tooltip')
            : getProductsT('same_day_multiple_tooltip'),
          key,
          duration: 0,
          className: styles.antmessage,
        },
        0,
      );
      setSameDayMessage(messageFn);
      setMsgKey(key);
    },
    [setMsgKey, setSameDayMessage],
  );
  const clearSameDayMessage = useCallback(
    (destroyAll?: boolean) => {
      message.destroy(!destroyAll ? msgKey : undefined);
      if (sameDayMessage) {
        sameDayMessage();
        setSameDayMessage(undefined);
      }
    },
    [sameDayMessage, msgKey],
  );
  /* note: eventTime is optional and in Locale time ( with browsers timezone offset) */
  const showFutureDayPopup = useCallback(() => {
    const t = getProductsT;
    Modal.info({
      title: t?.('future_date_title'),
      content: t?.('future_date_message'),
      okText: t?.('done'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);
  const onChangeDate = useCallback(
    (
      form: FormInstance<any>,
      value: string,
      isMultipleDates?: boolean,
      rowKey?: string,
      eventTime?: string,
      timeKey = 'time',
    ) => {
      switch (true) {
        case !!value: {
          const edate = eventTime || lastEventDate;
          const isSameDay = moment(value).isSame(moment(new Date(String(edate))), 'day');

          if (isSameDay) {
            const clearValue =
              isMultipleDates && rowKey
                ? { [String(rowKey)]: { [timeKey]: undefined } }
                : { time: undefined };
            form?.setFieldsValue(clearValue);
            triggerSameDayMessage(isMultipleDates);
          } else {
            const setValue =
              isMultipleDates && rowKey
                ? { [String(rowKey)]: { [timeKey]: '12:00:00' } }
                : { time: '12:00:00' };
            form.setFieldsValue(setValue);
            clearSameDayMessage();
          }

          if (moment(new Date()).isBefore(moment(value), 'day')) {
            showFutureDayPopup();
          }
          break;
        }

        default:
          clearSameDayMessage();
          break;
      }
    },
    [triggerSameDayMessage, clearSameDayMessage, showFutureDayPopup, lastEventDate],
  );

  const onChangeLocation = useCallback(
    (
      form: FormInstance<any>,
      location?: string,
      isMultiLoc?: boolean,
      optionTemplate?: EventTemplatesResponse,
    ) => {
      switch (true) {
        case attributes &&
          (optionTemplate || template) &&
          (attributes?.results?.length || 0) > 0 &&
          ((optionTemplate || template)?.templateAttributes?.length || 0) > 0:
          (optionTemplate || template)?.templateAttributes?.forEach((field, fieldIdx) => {
            const fieldProperties = attributes?.results?.map((el) => ({
              id: el?.id,
              ...el?.fieldProperties,
            }));
            const propertyName = field?.attribute?.fieldProperties?.fields?.propertyName;
            const dValue = field?.defaultValue;
            const attributeField = fieldProperties?.find(
              (attr) => attr?.id === field?.attribute?.id,
            );

            if (attributeField) {
              const defaultValues = attributeField?.values?.defaultValues;
              const templateDefaultValue = dValue;
              const customProperties = form?.getFieldValue('customProperties') || {};
              const existingCsvData = form?.getFieldValue('csvData') || [];
              const newCustomProperties = isObject(customProperties)
                ? { ...customProperties }
                : [...customProperties];

              const defaultValue = defaultValues?.find((el) => el?.locationId === location);
              const valueToSet = templateDefaultValue || defaultValue?.value || undefined;

              if (valueToSet !== undefined) {
                if (isObject(customProperties)) {
                  newCustomProperties[propertyName || ''] = valueToSet;
                } else {
                  newCustomProperties[fieldIdx] = valueToSet;
                }

                if (isMultiLoc) {
                  const multiReceiveObjects: MultiReceiveProductItem[] =
                    form?.getFieldValue('multiReceiveObjects') || [];
                  form?.setFieldsValue({
                    multiReceiveObjects: multiReceiveObjects?.map(
                      (i) => ({
                        ...i,
                        customProperties: newCustomProperties,
                      }),
                      [],
                    ),
                  });
                }

                if (dataEntryMethod === 'manual') {
                  form?.setFieldsValue({ customProperties: newCustomProperties });
                } else {
                  const updatedCsvData = existingCsvData?.map((el: DataItem) => ({
                    ...el,
                    [propertyName || '']: valueToSet,
                  }));
                  form?.setFieldsValue({ csvData: updatedCsvData });
                }
              } else {
                if (isObject(customProperties)) {
                  newCustomProperties[propertyName || ''] = undefined;
                } else {
                  newCustomProperties[fieldIdx] = undefined;
                }
                if (isMultiLoc) {
                  const multiReceiveObjects: MultiReceiveProductItem[] =
                    form?.getFieldValue('multiReceiveObjects') || [];
                  form?.setFieldsValue({
                    multiReceiveObjects: multiReceiveObjects?.map(
                      (i) => ({
                        ...i,
                        customProperties: newCustomProperties,
                      }),
                      [],
                    ),
                  });
                }

                if (dataEntryMethod === 'manual') {
                  form?.setFieldsValue({ customProperties: newCustomProperties });
                } else {
                  const updatedCsvData = existingCsvData?.map((el: DataItem) => ({
                    ...el,
                    [propertyName || '']: undefined,
                  }));
                  form?.setFieldsValue({ csvData: updatedCsvData });
                }
              }
            }
          });
          break;
        default:
          // Handle other cases here
          break;
      }
    },
    [attributes, template, dataEntryMethod],
  );

  const onChangeTemplate = useCallback(
    (
      form: FormInstance<any>,
      option: any,
      value: string,
      location?: string,
      isMultiReceive?: boolean,
    ) => {
      const item: EventTemplatesResponse = option?.itemProps;
      const tFields = item?.templateAttributes || [];
      const bizStep = tFields?.find(
        (field) => field?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep',
      );
      const disposition = tFields?.find(
        (field) => field?.attribute?.fieldProperties?.fields?.propertyName === 'disposition',
      );
      const templateAttributes = tFields?.filter(filterTCertAttribute);
      const templateItem: EventTemplatesResponse = {
        ...item,
        templateAttributes,
      };

      setTemplate(templateItem);

      /* update default values for certification fields */
      const certdefaultValuesExist = (item?.templateCertifications?.length || 0) > 0;
      /* map default value of each property name to respective fields in certificationList */
      const certificationList =
        certdefaultValuesExist && item?.allowCertificationDocuments
          ? item?.templateCertifications?.map(mapCertDocuments)
          : [];

      /* update default values for vessel catch fields and other template fields */
      const customProperties = templateAttributes?.reduce((acc, curr) => {
        acc[(curr?.attribute?.fieldProperties?.fields?.propertyName || '') as keyof DataItem] =
          curr?.defaultValue || undefined;
        return acc;
      }, {} as DataItem);

      if (isMultiReceive) {
        const multiReceiveObjects: MultiReceiveProductItem[] =
          form?.getFieldValue('multiReceiveObjects') || [];
        form?.setFieldsValue({
          multiReceiveObjects: multiReceiveObjects?.map(
            (i) => ({
              ...i,
              customData: value,
              ...(bizStep?.attribute?.fieldProperties?.values?.valueOptions?.[0] && {
                bizStep: bizStep?.attribute?.fieldProperties?.values?.valueOptions?.[0],
              }),
              ...(disposition?.attribute?.fieldProperties?.values?.valueOptions?.[0] && {
                disposition: disposition?.attribute?.fieldProperties?.values?.valueOptions?.[0],
              }),
              certificationList,
              customProperties,
            }),
            [],
          ),
        });
      } else {
        form?.setFieldsValue({
          customData: value,
          ...(bizStep?.attribute?.fieldProperties?.values?.valueOptions?.[0] && {
            bizStep: bizStep?.attribute?.fieldProperties?.values?.valueOptions?.[0],
          }),
          ...(disposition?.attribute?.fieldProperties?.values?.valueOptions?.[0] && {
            disposition: disposition?.attribute?.fieldProperties?.values?.valueOptions?.[0],
          }),
          certificationList,
          customProperties,
        });
      }

      /* update default values depending on location */

      if (location) {
        onChangeLocation(form, location, isMultiReceive, item);
      }
    },
    [setTemplate, onChangeLocation],
  );

  const onReportEventError = useCallback(() => {
    eventErrorModal.show();
  }, [eventErrorModal]);

  const onErrorShipEvent = useCallback<OnErrorShipEvent>(() => {
    const t = (key: string, param?: object) => getTParam(`products.${key}`, param);
    Modal.error({
      title: t('ship_event_error_title'),
      content: (
        <div>
          {t('ship_event_error_desc')}
          <a href={`mailto:${supportEmailLink}`}>{` ${t('ship_event_contact_us')} `}</a>.
        </div>
      ),
      okText: t?.('ship_event_error_confirm'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
      zIndex: 9999,
    });
  }, []);

  return {
    eventErrorModal,
    productId,
    locationId,
    tradePartnerId,
    locationName,
    containerIdArray,
    productIdArray,
    lotId,
    lastEventDate,
    defaultTimezone,
    sameDayMessage,
    defaultAttribute,
    timeValidation,
    setLastEventDate,
    clearSameDayMessage,
    disabledDate,
    disabledTime,
    onChangeDate,
    onChangeTemplate,
    onChangeLocation,
    onReportEventError,
    onErrorShipEvent,
  };
};

export default useEventActions;
